import { Box, Paper, Typography } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import AOS from "aos";
import { useEffect, useState } from "react";

import fetchNews from "../../api/NewsAPI";
import { axiosClient } from "../../api/axios.js";
import Database from "../../assets/icon/database.svg";
import rsf from "../../assets/img/Fundings/rsf.png";
import skoltech from "../../assets/img/Fundings/skoltech.png";
import {
  Banner,
  DBStatistics,
  Details,
  Footer,
  Header,
  NewsWidget,
} from "../../components/index.js";

import "aos/dist/aos.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./style.css";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
}));
const URL_INFO = process.env.REACT_APP_URL_INFO;

const get_info = async function () {
  const response = await axiosClient.get(URL_INFO);
  return response;
};

export default function Home() {
  const [results, setResults] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [newsLength, setNewsLength] = useState(0);

  useEffect(() => {
    const getNews = async () => {
      const fetchedNews = await fetchNews();
      setNewsLength(fetchedNews.length);
    };

    getNews();
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    get_info().then((r) => {
      setResults(r.data.result);
      setLoaded(true);
    });
  }, []);

  return (
    <>
      <main>
        <Box>
          <Header />
          <Banner />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            margin: { xs: "12vw 7vw 1vw 7vw", md: "8vw 7vw 1vw 7vw" },
            backgroundColor: "white",
            padding: "5vw",
            boxShadow: "0px 0px 6px 0px grey",
          }}
        >
          <Box>
            <Box
              component="img"
              sx={{
                display: "block",
                width: "60px",
                margin: "0 auto",
              }}
              alt="Search"
              src={Database}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center !important",
              }}
            >
              <Typography
                sx={{
                  margin: "40px auto !important",
                  justifyContent: "center !important",
                  marginBottom: "20px",
                  fontSize: { xs: "20px", md: "36px" },
                  textAlign: "center",
                  color: "#262B40",
                  px: 5,
                }}
              >
                Database of Ab Initio Crystal Structures (DAICS) with{" "}
                <span
                  style={{
                    fontSize: { xs: "20px !important", md: "36px" },
                    color: "#262B40",
                    fontWeight: "400",
                  }}
                >
                  {loaded ? (
                    <DBStatistics
                      reff={"counter"}
                      number={results["Crystal Structures"]}
                      style={{
                        color: "#inherit",
                      }}
                    />
                  ) : null}
                </span>{" "}
                entries for unary and binary system.
              </Typography>
            </Box>
          </Box>
          {loaded && <Details items={results} />}
        </Box>
        {newsLength > 0 && (
          <Box className="box2">
            {" "}
            <Typography
              sx={{
                fontSize: "2rem",
              }}
            >
              News:
              <NewsWidget />
            </Typography>
          </Box>
        )}
        <Box className="box2" sx={{ marginBottom: "50px !important" }}>
          <Typography
            sx={{
              fontSize: "2rem",
            }}
          >
            Fundings:
          </Typography>
          <Box
            data-aos="fade-up"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: { xs: "center", md: "space-around" },
              width: "100%",
              pt: 4,
            }}
          >
            <Box
              component="img"
              data-aos="zoom-in-down"
              sx={{
                display: "block",
                pageBreakInside: "avoid",
                margin: "0 auto",
                verticalAlign: "middle",
                height: "auto",
                maxWidth: { xs: "40vw", md: "17vw" },
                border: "0.01px solid",
                borderRadius: "20px !important",
                boxShadow: "0px 0px 3px 0px grey",
              }}
              alt="logo"
              src={skoltech}
            />
            <Box
              component="img"
              data-aos="zoom-in-down"
              sx={{
                display: "block",
                mt: { xs: 4, md: 0 },
                margin: "0 auto",
                maxWidth: { xs: "40vw", md: "17vw" },
                Width: "100%",
                height: "auto",
                border: "0.01px solid",
                borderRadius: "20px !important",
                boxShadow: "0px 0px 3px 0px grey",
              }}
              alt="logo"
              src={rsf}
            />
          </Box>
        </Box>
      </main>
      <Footer />
    </>
  );
}
