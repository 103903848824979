import HelpIcon from "@mui/icons-material/Help";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import React, { useState } from "react";
import periodicTable from "../../assets/icon/PeriodicTable.png";
import HeatmapChart from "../../components/Charts/HeatmapChart.js";
import Element from "../../components/Element/Element";
import MTable from "../../components/Home/table/Table";
import PettiforDes from "../../components/Pettifor/PettiforDes.js";
import Loading from "../../components/UI/Loading";
import { Footer, Header } from "../../components/index.js";
import { elements } from "../../data/_data";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import FiltersLink from "../HomePage/FiltersLink.js";
import { decryptData } from "../Results/utils";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: 12,
  },
}));

const orderOEltData = [
  {
    value: "atomic_number",
    name: "Atomic number ",
    helpText: "Order elements based on Atomic number",
  },
  {
    value: "periodic_number",
    name: "Periodic number ",
    helpText: "Order elements based on Periodic number",
  },
  {
    value: "mendeleev_number",
    name: "Mendeleev number ",
    helpText: "Order elements based on Pettifor's Mendeleev number",
  },
  {
    value: "modified_mendeleev_number",
    name: "Modified Mendeleev number ",
    helpText: "Order elements based on modified Mendeleev number ",
  },
  {
    value: "universal_sequence_of_elements",
    name: "Universal sequence of elements ",
    helpText: "Order elements based on universal sequence of elements (USE)",
  },
];

const materealPropertyData = [
  { value: "hardness", name: "Hardness " },
  { value: "magnetization", name: "Magnetization " },
  { value: "enthalpy_of_formation", name: "Enthalpy of formation" },
  { value: "atomization_energy", name: "Atomization energy" },
  { value: "density", name: "Density" },
  { value: "ml_bulk_modulus", name: "ML Bulk Modulus" },
  { value: "ml_shear_modulus", name: "ML Shear Modulus" },
  { value: "ml_young_modulus", name: "ML Young Modulus" },
  { value: "ml_pugh_modulus", name: "ML Pugh Modulus" },
  { value: "ml_possions_ratio", name: "ML Possions ratio" },
  { value: "ml_vickers_hardness", name: "ML Vickers hardness" },
  { value: "ml_fracture_toughness", name: "ML Fracture toughness" },
];

function Pettifor() {
  const [orderOElt, setOrderOElt] = useState("");
  const [materealProperty, setMaterealProperty] = useState("");
  const [minmax, setMinMax] = useState("");
  const [EL, setEL] = React.useState([0, 1.5]);
  const [ELR, setELR] = React.useState(false);
  const [structure, setStructure] = useState("");
  const [selectedElements, setSelectedElements] = useState("");
  const [acitveElements, setActiveElement] = useState({});
  const [elementNumbers, setElementNumbers] = useState(new Set());
  const [expanded, setExpanded] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isError, setError] = useState(false);
  const [errMsg, setErrorMsg] = useState("");
  const [message, setMessage] = useState("");
  const [ParetoData, setParetoData] = useState(false);
  const [results, setResults] = useState([]);
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [loadedResults, setLoadedResults] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [dataCount, setDataCount] = useState(0);
  const axiosPrivate = useAxiosPrivate();

  const URL_ATOMS = process.env.REACT_APP_URL_ATOMS;
  const URL_PETTIFOR_DATA = process.env.REACT_APP_URL_PETTIFOR_DATA;

  async function getPettifor(params) {
    try {
      const response = await axiosPrivate.get(URL_PETTIFOR_DATA, {
        params: params,
      });
      return response;
    } catch (error) {
      setError(true);
      setErrorMsg(error.response.data.detail);
    }
  }

  const searchByElement = async (request, d) => {
    const cleanedRequest = request.replace(/\s/g, "");
    const filterLink = Object.keys(d).length !== 0 ? `&${FiltersLink(d)}` : "";
    const orderingLink = orderBy
      ? order === "asc"
        ? `&ordering=${orderBy}`
        : `&ordering=-${orderBy}`
      : "";
    const requestLink = `${URL_ATOMS}${cleanedRequest}&page=${
      page + 1
    }&page_size=${rowsPerPage}${orderingLink}${filterLink}`;

    const response = await axiosPrivate.get(requestLink);
    return response;
  };

  const handelOrderOElt = (event) => {
    setOrderOElt(event.target.value);
  };
  const handleMaterealProperty = (event) => {
    setMaterealProperty(event.target.value);
  };
  const handleMinMax = (event) => {
    setMinMax(event.target.value);
  };
  const handleStructure = (event) => {
    setStructure(event.target.value);
  };
  const executeScroll = () => {
    var element = document.getElementById("foooter");
    element.scrollIntoView({ behavior: "smooth", block: "end" });
  };
  const sendReq = (event) => {
    event.preventDefault();
    executeScroll();
    setResults([]);
    setError(false);
    const params = {
      order_of_elements: orderOElt,
      materials_property: materealProperty,
      min_max: minmax,
      which_material_energy: "all",
      e_hull_limit: EL[1],
      excluded_elements: selectedElements,
    };
    setIsLoading(true);
    executeScroll();
    setExpanded([]);
    getPettifor(params)
      .then((response) => {
        if (response.status === 200) {
          setParetoData(response.data.result);
          setIsLoading(false);
          setLoaded(true);
          executeScroll();
        }
      })
      .catch((e) => {
        setMessage(e.response);
        setError(true);
      });
  };
  const clear = (e) => {
    setSelectedElements("");
    setElementNumbers(new Set());
  };

  const handleSearch = (e) => {
    setSelectedElements(e.target.value);
  };

  const send = async (systemQuery) => {
    setIsLoadingResults(true);
    setLoadedResults(false);
    try {
      const response = await searchByElement(systemQuery, {});
      setDataCount(response.data.count);
      const responseData = decryptData(response.data.results);
      setResults(responseData);
      setIsLoadingResults(false);
      setLoadedResults(true);
      executeScroll();
    } catch (error) {
      console.error("An error occurred while processing the request:", error);
      setResults([]);
      setIsLoadingResults(false);
      setLoadedResults(true);
    }
  };

  const re = /[^!:^()]*\w\s/g;
  const addElement = (num) => {
    if (122 > Number(num) && Number(num) > 97) {
    } else {
      if (selectedElements) {
        let s = [...selectedElements.matchAll(re)];
        if (s.length > 0) {
          if (
            String([...selectedElements.matchAll(re)].slice(-1)).includes(
              elements[num].symbol + " "
            )
          ) {
            s = String(s[0]);
            s = s.replace(", " + elements[num].symbol + " ", "");
            s = s.replace(" " + elements[num].symbol + " ,", "");
            s = s.replace(elements[num].symbol + " ", "");
            if (s.startsWith(",")) {
              s = s.slice(2);
            }
            setSelectedElements(s);
          } else {
            let s = [...selectedElements.matchAll(re)];
            s = s + ", " + elements[num].symbol + " ";
            setSelectedElements(s);
          }
        } else {
          let s = [...selectedElements.matchAll(re)];
          s = s + ", " + elements[num].symbol + " ";
          setSelectedElements(s);
        }
      } else {
        setSelectedElements(elements[num].symbol + " ");
      }
    }
  };

  const populateElements = (start, end) => {
    let items = [];
    for (let i = start; i <= end; i++) {
      items.push(
        <Element
          addElement={addElement}
          num={i}
          category={acitveElements}
          selectedElements={selectedElements}
          elementNumbers={elementNumbers}
        />
      );
    }
    return items;
  };
  const handleToggle = (event) => {
    expanded.length === 0 ? setExpanded(["1"]) : setExpanded([]);
  };
  const handleEL = (event, newEL) => {
    setEL([0, newEL[1]]);
    if (ELR < 1.5) {
      setELR(true);
    } else {
      setELR(false);
    }
  };

  const ActionButtonsStack = () => (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        display: "flex",
        alignItems: "center",
        mt: "10px !important",
      }}
    >
      <IconButton
        color="primary"
        aria-label="periodicTable"
        component="label"
        variant="contained"
        onClick={(e) => handleToggle(e)}
        sx={{
          mt: "8px",
        }}
      >
        <img
          src={periodicTable}
          width={"auto"}
          height={"50"}
          alt="periodicTable"
        />
      </IconButton>
      <Button
        color="success"
        variant="contained"
        size="small"
        onClick={(e) => sendReq(e)}
        sc={{
          mt: "13px !important",
        }}
      >
        generate
      </Button>
    </Stack>
  );

  return (
    <Grid
      container
      sx={{
        minHeight: "100%",
      }}
    >
      <Header />

      <Grid xs={12} lg={12}>
        <PettiforDes />
        <Card
          sx={{
            m: { xs: 1, md: 3 },
            mt: 5,
          }}
          variant="outlined"
        >
          <Box
            sx={{
              display: "contents",
            }}
          >
            <FormControl sx={{ m: 3, minWidth: 120 }}>
              <Typography variant="h6" component="h6">
                Order Of elements
              </Typography>
              <Select
                value={orderOElt}
                onChange={handelOrderOElt}
                displayEmpty
                inputProps={{ "aria-label": "Order Of elements" }}
              >
                {Object.entries(orderOEltData).map(([index, item]) => (
                  <MenuItem key={index} value={item.value}>
                    {item.name}
                    <BootstrapTooltip
                      title={item.helpText}
                      arrow
                      placement="right"
                    >
                      <HelpIcon />
                    </BootstrapTooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 3, minWidth: 120 }}>
              <Typography variant="h6" component="h6">
                Material's property
              </Typography>
              <Select
                value={materealProperty}
                onChange={handleMaterealProperty}
                displayEmpty
                inputProps={{ "aria-label": "Order Of elements" }}
              >
                {Object.entries(materealPropertyData).map(([index, item]) => (
                  <MenuItem key={index} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 3, minWidth: 120 }}>
              <Typography variant="h6" component="h6">
                Optimization Type
              </Typography>
              <Select
                value={minmax}
                onChange={handleMinMax}
                displayEmpty
                inputProps={{ "aria-label": "Order Of elements" }}
              >
                <MenuItem value={"MIN"}>Min </MenuItem>
                <MenuItem value={"MAX"}>Max </MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 3, minWidth: 120 }}>
              <Typography variant="h6" component="h6">
                Energy Limit (eV/atom)
              </Typography>
              <Typography>
                {" "}
                {EL[0]} to {EL[1]}
              </Typography>
              <Slider
                sx={{
                  mt: 1,
                  width: "85%",
                }}
                size="small"
                getAriaLabel={() => "Minimum distance"}
                value={EL}
                onChange={handleEL}
                valueLabelDisplay="auto"
                step={0.1}
                color="secondary"
                min={0}
                max={1.5}
              />
            </FormControl>
          </Box>
          {/* --------------EXCLUDED ELEMENTS------------------------- */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "95%",
              mx: 1,
              justifyContent: "space-around",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "70%", md: "75%" },
                mx: 1,
              }}
            >
              <Typography variant="h6" component="h6">
                Excluded Elements:
              </Typography>
              <InputBase
                variant="outlined"
                sx={{
                  backgroundColor: "#ffffff",
                  border: "2px solid #808080",
                  borderRadius: "15px",
                  height: "2em",
                  px: 1,
                  mb: 3,
                }}
                value={selectedElements}
                onChange={handleSearch}
              />
            </Box>
            <ActionButtonsStack />
          </Box>
          {/* --------------PERIODIC TABLE BOX------------------------- */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TreeView
              sx={{
                marginLeft: "-17px !important",
              }}
              expanded={expanded}
            >
              <TreeItem className="results-tree--item" nodeId="1" label="">
                <Card
                  sx={{
                    // m: 3,
                    p: 3,
                  }}
                  variant="outlined"
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xs: "repeat(17,4.1vw) 1fr",
                        md: "repeat(17, 4.1vw) 1fr",
                      },
                      gridColumnGap: { xs: "1px", md: "2px" },
                      gridRowGap: { xs: "1px", md: "2px" },
                      height: "auto",
                      margin: "auto 0",
                      mx: { xs: "1vw", md: "1vw" },
                      mt: { xs: "2vw", md: "0" },
                      width: "95%",
                    }}
                  >
                    {populateElements(1, 4)}
                    {populateElements(5, 56)}
                    {populateElements(120, 120)}
                    {populateElements(72, 88)}
                    {populateElements(121, 121)}
                    {populateElements(104, 118)}
                    {populateElements(57, 71)}
                    {populateElements(89, 103)}
                  </Box>
                </Card>
              </TreeItem>
            </TreeView>
          </Box>
          {/* --------------END PERIODIC TABLE BOX------------------------- */}
        </Card>
      </Grid>

      {/* --------------HEATMAP CARD------------------------- */}
      <>
        {isError ? (
          <Typography variant="body1" color="error">
            {errMsg}
          </Typography>
        ) : isLoading ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-around",
              m: { xs: 1, md: 3 },
            }}
          >
            <Loading type={"spinningBubbles"} color="#4A5073" />
          </Box>
        ) : loaded ? (
          <Card
            variant="outlined"
            sx={{
              display: "flex",
              width: "100%",
              py: 4,
              my: 1,
              justifyContent: "space-around",
              mx: { xs: 1, md: 3 },
            }}
          >
            <HeatmapChart
              data={ParetoData}
              label={orderOElt}
              colorbar_label={materealProperty}
              send={send}
            />
          </Card>
        ) : null}
      </>
      {/* --------------END HEATMAP CARD------------------------- */}

      {/* -------------- RESULTS TABLE BOX------------------------- */}
      <>
        {isLoadingResults ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-around",
              m: { xs: 1, md: 3 },
            }}
          >
            <Loading type={"spinningBubbles"} color="#4A5073" />
          </Box>
        ) : loadedResults ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
              py: 4,
              my: 1,
              width: "100%",
            }}
          >
            <MTable
              data={results}
              count={dataCount}
              order={order}
              setOrder={setOrder}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          </Box>
        ) : null}
      </>

      {/* --------------END RESULTS TABLE BOX------------------------- */}
      <Footer />
      <div id="foooter"></div>
    </Grid>
  );
}

export default Pettifor;
