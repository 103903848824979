import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import GoogleLogo from "../../assets/icon/G.svg";
import backgroundImage from "../../assets/img/grapheneWallpaper.jpg";
import { getItemWithExpiration } from "../../components/Cookies/CookiesConsent";
import LightFooter from "../../components/Footer/LightFooter";
import useAuth from "../../hooks/useAuth";
import { decryptData } from "../../pages/Results/utils";

const URL_LOGIN = process.env.REACT_APP_URL_LOGIN;
const GOOGLE_REDIRECT = process.env.REACT_APP_GOOGLE_REDIRECT;
const URL_SERVER = process.env.REACT_APP_URL_SERVER;

const theme = createTheme();

const customStyle = {
  Button: {
    backgroundColor: "#f8f8f8",
    color: "black",
    border: "1px solid #adacb5",
    padding: "9px 20px",
    borderRadius: "5px",
    fontSize: "18px",
    fontWeight: "500",
    cursor: "pointer",
    transition: "background-color 0.3s, box-shadow 0.3s",
    boxShadow: "none",
    outline: "none",
    width: "100%",
    marginTop: "1px",
    marginBottom: "-15px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    textTransform: "none",

    "&:hover": {
      color: "#fff",
      backgroundColor: "#b80000",
      boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.5)",
    },
  },
};

const Login = () => {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const userRef = useRef();
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isError, setError] = useState(false);
  const isAcceptedCookies = getItemWithExpiration("consent");

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  useEffect(() => {
    localStorage.setItem("daicsUser", user);
  }, [user]);

  const handleGoogleLogin = () => {
    if (!isAcceptedCookies) {
      setErrMsg("Please accept cookies to proceed.");
      setError(true);

      return;
    }
    window.location.href = URL_SERVER + GOOGLE_REDIRECT;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isAcceptedCookies) {
      setErrMsg("Please accept cookies to proceed.");
      setError(true);
      return;
    }
    try {
      const response = await axios.post(
        URL_LOGIN,
        { username: user, password: pwd },
        {
          withCredentials: true,
        }
      );

      const accessToken = decryptData(response?.data?.access);

      setAuth({ user, accessToken });
      setUser("");
      setPwd("");

      navigate(from, { replace: true });
    } catch (error) {
      setError(true);
      console.error("Login error:", error);

      const errorResponse = error.response?.data?.detail;
      setErrMsg(errorResponse || "No Server Response");
    }
  };

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                borderRadius: 4,
                marginTop: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 1,
                boxShadow: 4,
                backgroundColor: "rgba(255, 255, 255, 0.9)",
              }}
            >
              <Typography sx={{ pt: 0 }} component="h1" variant="h5" />
              <Button sx={customStyle.Button} onClick={handleGoogleLogin}>
                <img src={GoogleLogo} alt="GoogleLogo" width={25} />
                Sign in with Google
              </Button>
              {}
              {}
              {isError && (
                <Typography
                  component="h1"
                  variant="h6"
                  color="error"
                  sx={{ mt: 3 }}
                >
                  {errMsg}
                </Typography>
              )}

              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{
                  mt: 5,
                }}
              >
                {}
                <TextField
                  required
                  fullWidth
                  type="email"
                  id="email"
                  ref={userRef}
                  autoComplete="off"
                  onChange={(e) => setUser(e.target.value)}
                  value={user}
                  label="Username or Email Address"
                  name="email"
                  autoFocus
                  InputProps={{
                    sx: {
                      py: 1,
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                      borderRadius: "4px",
                    },
                  }}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                  required
                  autoComplete="current-password"
                  InputProps={{
                    sx: {
                      py: 1,
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                      borderRadius: "4px",
                    },
                  }}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, fontSize: "18px", fontWeight: 500 }}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Typography
                      variant="body1"
                      sx={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        transition:
                          "background-color 0.3s ease, color 0.3s ease",
                        "&:hover": {
                          color: "blue",
                        },
                      }}
                      onClick={(e) => navigate("/forgotPassword")}
                    >
                      Forgot password?
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      sx={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        transition:
                          "background-color 0.3s ease, color 0.3s ease",
                        "&:hover": {
                          color: "blue",
                        },
                      }}
                      onClick={(e) => navigate("/register")}
                    >
                      Don't have an account? Sign Up
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
        <LightFooter positioning={"fixed"} />
      </div>
    </>
  );
};

export default Login;
