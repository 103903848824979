import emailjs from "@emailjs/browser";
import { Alert, Button, Snackbar, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import useGetUserInfo from "../../hooks/useGetUserInfo";

const EMJS_SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const EMJS_TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const EMJS_PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

const ContactForm = ({ contact, setContact }) => {
  const user = useGetUserInfo();
  const [formData, setFormData] = useState({
    username: user.username,
    email: user.email,
    fullName:
      user.prefix + " " + user.firstName + " " + user.lastName ||
      user.prefix + " " + user.lastName,
    subject: "Licensing Inquiry",
  });
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  const form = useRef();

  useEffect(() => {
    setFormData({
      username: user.username,
      email: user.email,
      fullName:
        user.prefix + " " + user.firstName + " " + user.lastName ||
        user.prefix + " " + user.lastName,
      subject: "Licensing Inquiry",
    });
  }, [user]);

  const handleClose = () => {
    setOpen(false);
    setMsg("");
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      const result = await emailjs.sendForm(
        EMJS_SERVICE_ID,
        EMJS_TEMPLATE_ID,
        form.current,
        EMJS_PUBLIC_KEY
      );

      setSuccessMsg("Message sent successfully!");
      setIsError(false);
      setOpen(true);
      setErrMsg("");
    } catch (error) {
      setErrMsg("An error occurred. Please try again later.");
      setIsError(true);
      setOpen(true);
      setSuccessMsg("");
    }
  };

  return (
    <form className="contact-form" ref={form}>
      <Typography sx={{ mb: "5px", fontWeight: "bold" }}>
        Dear {formData.fullName},
      </Typography>
      <Typography sx={{ mb: "15px" }}>
        If you have any questions regarding licensing, please contact us using
        the form below.{" "}
      </Typography>
      <Typography sx={{ mb: "10px" }}>
        {" "}
        Subject: <span style={{ fontWeight: "bold" }}>{formData.subject} </span>
      </Typography>

      <input
        type="text"
        name="email"
        value={
          "USER QUERY ON " +
          formData.subject +
          " from " +
          formData.fullName +
          " with username: " +
          formData.username +
          " and email: " +
          formData.email
        }
        style={{ display: "none" }}
      />

      <textarea
        name="message"
        placeholder="Message"
        value={msg}
        onChange={(e) => setMsg(e.target.value)}
        className="form-input"
        required
      />

      <br />

      <div sx={{ display: "flex", justifyContent: "space-between !important" }}>
        <Button
          type="submit"
          onClick={sendEmail}
          disabled={msg === ""}
          sx={{ width: "40% !important", marginRight: "5%", marginLeft: "5%" }}
        >
          Send
        </Button>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          {isError ? (
            <Alert icon={false} severity="error">
              {errMsg}
            </Alert>
          ) : (
            <Alert icon={false} severity="success">
              {successMsg}
            </Alert>
          )}
        </Snackbar>
        <Button
          type="cancel"
          variant="outlined"
          onClick={() => setContact(false)}
          sx={{ width: "40% !important", marginRight: "5%", marginLeft: "5%" }}
        >
          Close
        </Button>
      </div>
    </form>
  );
};

export default ContactForm;
