import { useCountUp } from "react-countup";
import "./DBStatistics.css";
const DBStatistics = (props) => {
  useCountUp({
    ref: props.reff.replaceAll(" ", ""),
    end: props.number,
    delay: 1,
    duration: 5,
  });
  return <span id={props.reff.replaceAll(" ", "")} />;
};

export default DBStatistics;
