import { Box, Card } from "@mui/material";
import RTable from "./table/Table";

const ResultsCard = ({ title, data }) => {
  return (
    <>
      <Card>
        <Box sx={{ p: 3, pb: 1 }}>
          <RTable title={title} data={data} />
        </Box>
      </Card>
    </>
  );
};

export default ResultsCard;
