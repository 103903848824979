import { useEffect, useState } from "react";
import useAxiosPrivate from "./useAxiosPrivate";

const URL_CSRF_TOKEN = process.env.REACT_APP_URL_CSRF_TOKEN;
export default function useCSRFToken() {
  const [csrftoken, setCSRFToken] = useState(null);
  const axiosPrivate = useAxiosPrivate();

  const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };

  const callCSRFToken = async () => {
    try {
      const response = await axiosPrivate.get(URL_CSRF_TOKEN);
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  useEffect(() => {
    const storedCSRFToken = getCookie("csrftoken");
    if (!storedCSRFToken) {
      callCSRFToken().then(() => {
        setCSRFToken(getCookie("csrftoken"));
      });
    } else {
      setCSRFToken(storedCSRFToken);
    }
  }, []);

  return csrftoken;
}
