import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  banner: {
    background: "linear-gradient(to bottom right, #00e4fd , #0a1930 50%)",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "30px 0 ",
    borderBottom: "1.5px solid white",
  },
  textbanner: {
    color: "white",
    width: "80%",
    justifyItems: "center",
    fontSize: "1.1rem",
    "& a": {
      color: "inherit",
      textDecoration: "underline",
      transition: "color 0.3s ease",
      fontWeight: "inherit",
      fontSize: "inherit",
    },
    "& a:hover": {
      color: "#ffa500 !important",
    },
  },
  sectiontopic: {
    background: "#0a1930",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 0 ",
    borderBottom: "1.5px solid white",
  },
  textsectiontopic: {
    color: "white",
  },

  cardGrid: {
    padding: "50px 20px",
    width: "100%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "row",
  },

  card: {
    width: "300px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.1)",
    transition: "box-shadow 0.3s ease",
    "&:hover": {
      boxShadow: "0 2px 5px 1px rgba(0,0,0,0.4)",
    },
  },

  cardsecondary: {
    width: "200px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  cardMedia: {},

  cardContent: {
    flexGrow: 1,
  },

  cardLink: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

export default useStyles;
