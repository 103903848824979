import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  Button,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { alpha, useTheme } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import * as React from "react";
import { Link } from "react-router-dom";
import "./Table.css";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "#",
    numeric: false,
    disablePadding: true,
    label: "#",
  },
  {
    id: "mat_id",
    numeric: false,
    disablePadding: true,
    label: "Materials ID",
  },
  {
    id: "formula",
    numeric: false,
    disablePadding: true,
    label: "Formula",
  },
  {
    id: "spg",
    numeric: false,
    disablePadding: true,
    label: "Space group",
  },
  {
    id: "e_hull",
    numeric: false,
    disablePadding: true,
    label: "E Above Hull (eV/atom)",
  },

  {
    id: "density",
    numeric: false,
    disablePadding: true,
    label: "Density",
  },
  {
    id: "volume",
    numeric: false,
    disablePadding: true,
    label: "Volume",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const [open, setOpen] = React.useState(false);

  return (
    <TableHead>
      <TableRow>
        {}
        {}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, many, total } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 0 },
        pr: { xs: 0, sm: 0 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%", ml: 4 }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {total} {many ? "Compounds" : "Compound"} are found
      </Typography>

      {}
    </Toolbar>
  );
};
const EnhancedSimilarLinks = (props) => {
  let { name, link } = props;
  name = name.split(",");
  link = link.split(",");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        whiteSpace: "nowrap",
      }}
    >
      {name.map((n, i) => (
        <Button
          sx={{
            textDecoration: "none",
            color: "black !important",
            textTransform: "lowercase",
            fontSize: "inherit",
            ":hover": {
              textDecoration: "underline",
              color: "red !important",
            },
          }}
          target="_blank"
          href={link[i]}
        >
          {n}
        </Button>
      ))}
    </Box>
  );
};
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function MTable(props) {
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const rows = props.data;
  const count = props.count;
  const order = props.order;
  const setOrder = props.setOrder;
  const orderBy = props.orderBy;
  const setOrderBy = props.setOrderBy;
  const page = props.page;
  const setPage = props.setPage;
  const rowsPerPage = props.rowsPerPage;
  const setRowsPerPage = props.setRowsPerPage;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.MaterialsId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, MaterialsId) => {
    const selectedIndex = selected.indexOf(MaterialsId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, MaterialsId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (MaterialsId) => selected.indexOf(MaterialsId) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const [open, setOpen] = React.useState("");
  return (
    <>
      {rows.length > 0 ? (
        <Box
          sx={{
            width: "100%",
            mx: "1.5vw",
          }}
        >
          <Paper
            sx={{
              width: "100%",
            }}
          >
            <EnhancedTableToolbar
              numSelected={selected.length}
              many={count > 1 ? true : false}
              total={count}
            />
            <TableContainer
              sx={{
                py: 3,
                px: 3,
              }}
            >
              <Table size={"small"}>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const isItemSelected = isSelected(row.mat_id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <>
                          <TableRow
                            hover
                            aria-checked={isItemSelected}
                            key={page * rowsPerPage + index + 1}
                            selected={isItemSelected}
                            onClick={() =>
                              setOpen(open === row.mat_id ? "" : row.mat_id)
                            }
                          >
                            <TableCell id={labelId} padding="none">
                              {page * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.mat_id}
                            </TableCell>
                            <TableCell>{row.formula}</TableCell>
                            <TableCell>{row.spg}</TableCell>

                            <TableCell>
                              {row.e_hull || row.e_hull === 0
                                ? Number(row.e_hull).toFixed(3)
                                : ""}
                            </TableCell>
                            <TableCell>
                              {Number(row.density).toFixed(3)}
                            </TableCell>
                            <TableCell>
                              {Number(row.volume).toFixed(3)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={6}
                            >
                              <Collapse
                                in={open === row.mat_id ? true : false}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box
                                  sx={{
                                    margin: 1,
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    justifyItems: "center",
                                    justifySelf: "center",
                                  }}
                                >
                                  <Table
                                    size="small"
                                    aria-label="purchases"
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      justifyItems: "center",
                                      justifySelf: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "inline-flex",
                                        justifyContent: "center",
                                        justifyItems: "center",
                                        justifySelf: "center",
                                        backgroundColor: "rgb(235, 235, 235)",
                                        width: "100%",
                                        paddingBottom: "10px",
                                        border: "1px solid #ffffff",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <Box>
                                        <TableHead>
                                          <TableRow>
                                            <TableCell align="center">
                                              Crystal system
                                            </TableCell>
                                            <TableCell align="center">
                                              Crystal family
                                            </TableCell>
                                            <TableCell align="center">
                                              Enthalpy of formation
                                            </TableCell>
                                            <TableCell align="center">
                                              Atomization energy
                                            </TableCell>
                                            <TableCell align="center">
                                              Is electride?
                                            </TableCell>
                                            <TableCell align="center">
                                              DFT code
                                            </TableCell>
                                            <TableCell align="center">
                                              Basis set
                                            </TableCell>
                                            <TableCell align="center">
                                              xc functional
                                            </TableCell>
                                            <TableCell align="center">
                                              Similar structure
                                            </TableCell>
                                            <TableCell align="center">
                                              Full details:
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          <TableRow>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              align="center"
                                            >
                                              {row.crystal_system || "-"}
                                            </TableCell>
                                            <TableCell align="center">
                                              {row.crystal_system || "-"}
                                            </TableCell>
                                            <TableCell align="center">
                                              {Number(row.f_energy).toFixed(3)}
                                            </TableCell>
                                            <TableCell align="center">
                                              {Number(row.a_energy).toFixed(3)}
                                            </TableCell>
                                            <TableCell align="center">
                                              {row.is_electride !== null
                                                ? row.is_electride === false
                                                  ? "No"
                                                  : "Yes"
                                                : "-"}
                                            </TableCell>
                                            <TableCell align="center">
                                              {}
                                              {"VASP(5.4.4)"}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              sx={{
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              {"plane waves"}
                                            </TableCell>
                                            <TableCell align="center">
                                              {"GGA-PBE"}
                                            </TableCell>
                                            <TableCell align="center">
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                }}
                                              >
                                                {row.mp_id ? (
                                                  <EnhancedSimilarLinks
                                                    link={
                                                      row.get_similar_link.mp_id
                                                    }
                                                    name={row.mp_id}
                                                  />
                                                ) : null}

                                                {row.cod_id ? (
                                                  <EnhancedSimilarLinks
                                                    link={
                                                      row.get_similar_link
                                                        .cod_id
                                                    }
                                                    name={row.cod_id}
                                                  />
                                                ) : null}
                                              </Box>
                                            </TableCell>
                                            <TableCell align="center">
                                              <Box>
                                                <Button
                                                  className="abstract--link--btn"
                                                  to={`/compound/${row.key}`}
                                                  target="_blank"
                                                  rel="noopener"
                                                  component={Link}
                                                >
                                                  {row.mat_id}
                                                </Button>
                                              </Box>
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Box>
                                    </Box>
                                  </Table>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[15, 50, 100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Paper>
        </Box>
      ) : null}
    </>
  );
}
