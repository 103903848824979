import { Grid } from "@mui/material";
import { Footer, Header } from "../../components/index.js";

import "./PolicyStyle.css";

const CookiePolicy = () => {
  return (
    <>
      <br />
      <Header />
      <Grid container sx={{ height: "100%" }}>
        <div className="policy-container">
          <h1>Cookie Policy</h1>
          <p>Last Updated: 2024-02-22 </p>
          <section>
            <h2>1. What are cookies?</h2>
            <p>
              This Cookie Policy explains what cookies are and how we use them,
              the types of cookies we use i.e, the information we collect using
              cookies and how that information is used, and how to manage the
              cookie settings.
              <br></br>
              Cookies are small text files that are used to store small pieces
              of information. They are stored on your device when the website is
              loaded on your browser. These cookies help us make the website
              function properly, make it more secure, provide better user
              experience.
            </p>
          </section>
          <section>
            <h2>2. Types of Cookies We Use</h2>
            <p>
              Authentication Cookies: We use cookies to store authentication
              tokens, including refresh tokens and CSRF tokens. These cookies
              are crucial for the proper functioning and security of the
              website.
            </p>
          </section>
          <section>
            <h2>3. How We Use Cookies</h2>
            <p>
              Authentication cookies are used to maintain user sessions,
              allowing seamless access to authenticated areas of the website.
              These cookies are encrypted and securely stored. Refresh tokens,
              valid for 2 weeks, are automatically removed when the user logs
              out from the site.
            </p>
          </section>
          <section>
            <h2>4. Managing Cookies</h2>
            <p>
              Users can manage cookies through their browser settings. However,
              disabling authentication cookies may impact the website's
              functionality, particularly in accessing secure areas and
              maintaining user sessions.
            </p>
          </section>

          <section>
            <h2>5. Contact Information</h2>
            <p>
              If you have any questions or concerns about our Privacy Policy,
              please contact us at: <br />
              Email:
              <a href="mailto:info@daics.net" style={{ fontSize: "20px" }}>
                info@daics.net
              </a>
              <br />
              Alternate Email:{" "}
              <a
                href="mailto:info.daics@gmail.com"
                style={{ fontSize: "20px" }}
              >
                info.daics@gmail.com
              </a>
              <br />
            </p>
          </section>
          <section>
            <h2>6. Changes to This Policy</h2>
            <p>
              We reserve the right to update our Cookies Policy. Any changes
              will be posted on this page, so please review it regularly.
            </p>
          </section>
        </div>
        <Footer />
        {/* <div id="foooter"></div> */}
      </Grid>
    </>
  );
};

export default CookiePolicy;
