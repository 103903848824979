import { useState } from "react";
import { elements } from "../../data/_data";

function Element(props) {
  const [hover, setHover] = useState(false);

  const addElement = (event) => {
    props.addElement(props.num);
  };

  const onMouseEnter = (event) => {
    setHover(true);
  };

  const onMouseLeave = (event) => {
    setHover(false);
  };
  let { num } = props;

  let element = elements[num];

  const selectedElements = props.selectedElements.split(", ");
  const elementSet = new Set(props.elementNumbers);
  return (
    <div
      key={element.name}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={addElement}
      className={`element element-${num} ${element.category} ${
        hover ? " active" : ""
      }${props.category === element.category ? " active" : ""}${
        selectedElements.includes(element.symbol + " ") ? " active" : ""
      }${elementSet.has(element.number) ? " active" : ""}`}
    >
      <div className="number">{element.number}</div>
      {element.radio_active ? (
        <i className="fas fa-radiation-alt element-icon"></i>
      ) : null}
      {}
      <div className="symbol">{element.symbol}</div>
      <div className={`element-name ele-${num}`}>{element.name}</div>
    </div>
  );
}

export default Element;
