import axios from "./axios";

const NEWS_URL = process.env.REACT_APP_URL_NEWS;

const fetchNews = async () => {
  try {
    const response = await axios.get(NEWS_URL, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      throw new Error("Failed to fetch data");
    }

    return response.data.results;
  } catch (error) {
    console.error("Error fetching news:", error);
    return [];
  }
};

export default fetchNews;
