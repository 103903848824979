import { Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <Container
      sx={{
        background: "#0a1930",
        color: "#fff",
        maxWidth: "unset !important",
        display: "flex",
        flexDirection: "column",
        pl: "0 !important",
        pr: "0 !important",
        ml: "0 !important",
        mr: "0 !important",
        justifyContent: "end",
        alignSelf: "self-end",
      }}
    >
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1rem",
          marginBottom: "1rem",
          p: "0.5rem",
        }}
      >
        {}
        <Grid
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "start" },
            alignItems: "center",
            flexWrap: "inherit",
            gap: "0.5rem",
            pr: "1rem",
            pl: "1rem",
            mb: { xs: "1rem", md: "0" },
          }}
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
        >
          <p
            style={{
              fontSize: "0.9rem",
              color: "white",
              marginRight: "1rem",
              fontWeight: 600,
            }}
          >
            Powered by :
          </p>

          <a
            href="https://www.djangoproject.com/"
            target="_blank"
            rel="noreferrer"
            style={{
              fontSize: "0.9rem",
              fontWeight: 400,
              textUnderlineOffset: "0.15em",
              ":hover": {
                color: "#ffa500 !important",
              },
            }}
          >
            Django
          </a>

          <a
            href="https://uspex-team.org/en/uspex/tools"
            target="_blank"
            rel="noreferrer"
            style={{
              fontSize: "0.9rem",
              fontWeight: 400,
              textUnderlineOffset: "0.15em",
              ":hover": {
                color: "#ffa500 !important",
              },
            }}
          >
            {" "}
            USPEX utilities
          </a>

          <a
            href="https://pymatgen.org/"
            target="_blank"
            rel="noreferrer"
            style={{
              fontSize: "0.9rem",
              fontWeight: 400,
              textUnderlineOffset: "0.15em",
              ":hover": {
                color: "#ffa500 !important",
              },
            }}
          >
            pymatgen
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://materialsproject.org/"
            style={{
              fontSize: "0.9rem",
              fontWeight: 400,
              textUnderlineOffset: "0.15em",
              ":hover": {
                color: "#ffa500 !important",
              },
            }}
          >
            xtaltoolkit
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.postgresql.org/"
            style={{
              fontSize: "0.9rem",
              fontWeight: 400,
              textUnderlineOffset: "0.15em",
              ":hover": {
                color: "#ffa500 !important",
              },
            }}
          >
            PostgreSQL
          </a>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "end",
            alignItems: "center",
            flexWrap: "inherit",
            gap: "0.5rem",
            pr: "1rem",
            pl: "1rem",
          }}
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
        >
          {}
          <Link
            to="/terms-and-conditions"
            target="_blank"
            rel="noreferrer"
            style={{
              fontSize: "0.9rem",
              fontWeight: 400,
              textDecoration: "underline",
              textUnderlineOffset: "0.15em",
              ":hover": {
                color: "#ffa500 !important",
              },
            }}
          >
            Terms and Conditions of Use
          </Link>

          <Link
            to="/privacy-policy"
            target="_blank"
            rel="noreferrer"
            style={{
              fontSize: "0.9rem",
              fontWeight: 400,
              textDecoration: "underline",
              textUnderlineOffset: "0.15em",
              ":hover": {
                color: "#ffa500 !important",
              },
            }}
          >
            Privacy Policy
          </Link>

          <Link
            to="/cookie-policy"
            target="_blank"
            rel="noreferrer"
            style={{
              fontSize: "0.9rem",
              fontWeight: 400,
              textDecoration: "underline",
              textUnderlineOffset: "0.15em",
              ":hover": {
                color: "#ffa500 !important",
              },
            }}
          >
            Cookie Policy
          </Link>
          {}
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "inherit",
          justifyContent: "center",
          borderTop: "1.5px solid white",
        }}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Typography
          sx={{
            color: "inherit !important",
            fontSize: "0.9rem",
            textAlign: "center",
            mt: "0.8rem",
            mb: "0.8rem",
          }}
        >
          Copyright © {new Date().getFullYear()} DAICS
        </Typography>
      </Grid>
    </Container>
  );
}

export default Footer;
