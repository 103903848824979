import ReportIcon from "@mui/icons-material/Report";
import ReportOffIcon from "@mui/icons-material/ReportOff";
import { Box, Button, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useCSRFToken from "../../hooks/useCSRFToken";

const URL_REPORT = process.env.REACT_APP_URL_REPORT;

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: "15px",
  },
}));

const ReportStructure = ({ id, reported }) => {
  const axiosPrivate = useAxiosPrivate();
  const csrfToken = useCSRFToken();
  const [isReported, setIsReported] = useState(reported);
  const [isError, setIsError] = useState(false);

  const handleReport = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosPrivate.post(
        URL_REPORT,
        { key: id },
        {
          headers: {
            "X-CSRFToken": csrfToken,
            accept:
              "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7",
          },
        }
      );
      setIsReported(!isReported);
      setIsError(false);
      return response;
    } catch (error) {
      console.error("Error handling report:", error);
      setIsError(true);
    }
  };

  const tooltipTitle = isReported
    ? "Undo Report"
    : "Report if the Structure is Unconverged or Contains Wrong Data";

  const textIconColor = isReported
    ? "#5B8F78 !important"
    : "#4996A6 !important";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#F2F7F2",
        padding: { xs: "10px", sm: "15px", md: "20px" },
        margin: "auto",
        marginTop: "30px",
        marginBottom: "10px",
        borderRadius: "20px",
      }}
    >
      <BootstrapTooltip title={tooltipTitle} arrow>
        <Button
          type="submit"
          onClick={handleReport}
          size="large"
          sx={{
            color: textIconColor,
            fontSize: { xs: "16px", sm: "16px", md: "17px", lg: "18px" },
            textTransform: "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          {isReported ? (
            <ReportOffIcon
              size="large"
              sx={{
                color: textIconColor,
                fontSize: "30px",
                marginRight: "5px",
              }}
            />
          ) : (
            <ReportIcon
              size="large"
              sx={{
                color: textIconColor,
                fontSize: "30px",
                marginRight: "5px",
              }}
            />
          )}
          {isReported
            ? "Undo Report - You've reported this structure. Was it a mistake? Undo the report"
            : "Report - If this structure is not converged or contains wrong calculated data"}
        </Button>
      </BootstrapTooltip>
      {isError && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Typography variant="body2" sx={{ color: "red" }}>
            Unable to perform the action. Please try again in 10 minutes
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ReportStructure;
