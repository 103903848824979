import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { resendEmail } from "../../api/newAuth";

const SuccessRegistration = ({ email }) => {
  const navigate = useNavigate();

  const handleResentEmail = () => {
    resendEmail(email);
  };

  return (
    <>
      <Typography component="h1" variant="h6">
        Thank you for registering!
      </Typography>
      <Typography variant="body1" sx={{ fontSize: "18px", p: 1 }}>
        Please check your email for a verification link.
      </Typography>
      <Button
        variant="outlined"
        size="medium"
        onClick={() => navigate("/login")}
        sx={{
          p: 1,
          border: 2,
          mt: "10px",
          ml: "5px",
          maxWidth: "200px",
          fontWeight: "bold",
          "&:hover": {
            border: 2,
          },
        }}
      >
        Login
      </Button>

      <Typography component="h1" variant="h6" sx={{ mt: "20px" }}>
        Did not receive a confirmation email?
      </Typography>

      <Typography variant="body1" sx={{ fontSize: "18px", p: 1 }}>
        Please check your spam folder.
        <br></br>
        If you still can't find it, click on resend email.
      </Typography>
      <Button
        variant="outlined"
        size="medium"
        onClick={handleResentEmail}
        sx={{
          p: 1,
          border: 2,
          mt: "10px",
          ml: "5px",
          maxWidth: "200px",
          fontWeight: "bold",
          "&:hover": {
            border: 2,
          },
        }}
      >
        Resend Email
      </Button>
    </>
  );
};

export default SuccessRegistration;
