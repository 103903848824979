import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import FunctionItems from "./FunctionItems";

export function FunctionSubMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const functionItems = FunctionItems();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (page) => {
    navigate("/" + page);
    handleClose();
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          fontSize: "0.9rem",
          color: "white",
          ":hover": {
            color: "#ffa500",
          },
        }}
      >
        Functions{" "}
        <ExpandMoreIcon
          fontSize="small"
          sx={{
            color: "white !important",
            ":hover": {
              color: "#ffa500 !important",
            },
          }}
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {Object.entries(functionItems).map(([key, value]) => (
          <MenuItem
            key={key}
            onClick={() => handleNavigate(key)}
            sx={{
              borderBottom: "1px solid #e0e0e0",
              "&:last-child": {
                borderBottom: "none",
              },
            }}
          >
            {value}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export function MobileFunctionSubMenu() {
  const navigate = useNavigate();
  const functionItems = FunctionItems();

  const handleNavigate = (page) => {
    if (page === "My Account") {
      navigate("/userpanel");
    } else {
      navigate("/" + page.replace(" ", "-").toLowerCase());
    }
  };

  return (
    <>
      <List
        sx={{
          padding: "0 10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {Object.entries(functionItems).map(([key, value]) => (
          <ListItem
            key={key}
            disablePadding
            onClick={() => handleNavigate(key)}
            sx={{
              cursor: "pointer",
              width: "100%",
              backgroundColor: "#f6f6f6",
              transition: "background-color 0.3s ease",
              "&:hover": { backgroundColor: "lightgray" },
            }}
          >
            <ListItemText primary={value} sx={{ textAlign: "center" }} />
          </ListItem>
        ))}
      </List>
    </>
  );
}
