import axios from "../api/axios";
import useAuth from "./useAuth";
import { decryptData } from "../pages/Results/utils";

const URL_REFRESH_TOKEN = process.env.REACT_APP_URL_REFRESH_TOKEN;

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    try {
      const response = await axios.post(
        URL_REFRESH_TOKEN,
        {},
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      setAuth((prev) => {
        return {
          ...prev,
          accessToken: decryptData(response.data.access),
        };
      });

      return decryptData(response.data.access);
    } catch (error) {
      console.error("Error refreshing token:", error);
      throw error;
    }
  };

  return refresh;
};

export default useRefreshToken;
