import StarIcon from "@mui/icons-material/StarBorder";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import SubscriptionContact from "./SubscriptionContact";

const tiers = [
  {
    title: "Basic",
    price: "0",
    period: "Free forever",
    description: [
      "1 user included",
      "Explore DAICS under web",
      "Access to search features",
      "Access to phase diagram function",
      "Help center access",
    ],
    audience: ["Tailored for individual research scientists.", ""],
    buttonText: "You Always Have this Plan",
    buttonVariant: "outlined",
    buttonColor: "gray",
  },
  {
    title: "Premium",
    price: "10,000",
    period: "/year",
    description: [
      "Up to 20 users included",
      "Explore DAICS under web",
      "Access to all available functions",
      "Download all data from DAICS",
      "Phone & email support",
    ],
    audience: [
      "Tailored for Universities, Companies, Institutes, etc. Enjoy Full Access for your entire organization.",
    ],
    buttonText: "Contact us",
    buttonVariant: "outlined",
    buttonColor: "green",
  },
];

export default function SubscriptionsContent() {
  const [contact, setContact] = useState(false);

  const handleOpen = () => {
    setContact(true);
  };

  return (
    <>
      {" "}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          maxWidth: "1200px",
          margin: "40px auto",
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "30px",
          backgroundColor: "rgba(255, 255, 255, 0.94)",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Typography
          component="h1"
          variant="h6"
          align="center"
          color="text.primary"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          Subscription Plans
        </Typography>

        <Box>
          <Grid container border={3}>
            {tiers.map((tier) => (
              <Grid item key={tier.title} xs={12} sm={6} md={6} lg={6}>
                <Card
                  sx={{
                    minWidth: "300px",
                  }}
                >
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: "center" }}
                    action={
                      tier.title === "Premium" ? (
                        <StarIcon sx={{ color: "#0a1930 !important" }} />
                      ) : null
                    }
                    subheaderTypographyProps={{
                      align: "center",
                    }}
                    sx={{
                      backgroundColor: "#DDF0FF",
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="h4"
                        color="text.primary"
                      >
                        €{tier.price}
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                        {tier.period}
                      </Typography>
                    </Box>
                    <Typography>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="left"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    {tier.audience.map((line) => (
                      <Typography
                        component="mark"
                        variant="subtitle1"
                        align="left"
                        key={line}
                      >
                        {line}
                        <br />
                      </Typography>
                    ))}
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      {...(tier.title === "Basic" ? { disabled: true } : null)}
                      variant="contained"
                      onClick={handleOpen}
                    >
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
        {contact && (
          <>
            <SubscriptionContact contact={contact} setContact={setContact} />
          </>
        )}
      </Box>
    </>
  );
}
