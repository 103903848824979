import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GitHubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import mobileLogo from "../../assets/logo/LogoDAICS.png";
import useAuth from "../../hooks/useAuth";
import useLogout from "../../hooks/useLogout";
import { MobileFunctionSubMenu } from "./FunctionButton";
import useStyles from "./styles";

const navItems = ["News", "Contact", "About Us"];

export default function MobileHeader() {
  const { auth } = useAuth();
  const logout = useLogout();
  const isLoggedIn = auth?.accessToken && auth.accessToken !== "";

  const [loggedIn, setLoggedIn] = React.useState(isLoggedIn);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navigate = useNavigate();

  const handleSignOut = () => {
    setLoggedIn(false);
    logout().then(() => {
      navigate("/", { replace: true });
    });
  };

  const handleNavigate = (page) => {
    if (page === "My Account") {
      navigate("/userpanel");
    } else {
      navigate("/" + page.replace(" ", "-").toLowerCase());
    }
  };

  const handleFunctionsClick = () => {
    setMobileOpen(false);
    setOpenFunctions(!openFunctions);
  };

  const authItems = loggedIn
    ? ["My Account", "Functions", ...navItems]
    : [...navItems];

  const [openFunctions, setOpenFunctions] = React.useState(false);

  return (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img
          src={mobileLogo}
          alt="DAICS"
          onClick={(e) => handleNavigate("")}
          style={{
            cursor: "pointer",
            height: "37px",
            margin: "10px",
          }}
        />
        {loggedIn ? (
          <Button
            onClick={handleSignOut}
            sx={{
              cursor: "pointer",
            }}
          >
            {}
            <Typography
              sx={{
                fontSize: { xs: "15px", sm: "18px" },
                fontWeight: "bold",
                paddingRight: "10px",
              }}
            >
              Logout
            </Typography>
            <LogoutIcon
              sx={{
                fontSize: { xs: "22px", sm: "30px" },
                cursor: "pointer",
                color: "#0a1930 !important",
                marginRight: "10px",
              }}
            />
          </Button>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "right",
            }}
          >
            <Button onClick={(e) => handleNavigate("register")}>
              <Typography
                sx={{
                  fontSize: { xs: "15px", sm: "18px" },
                  fontWeight: "bold",
                  paddingRight: "0px",
                }}
              >
                Register
              </Typography>
              <AppRegistrationIcon
                sx={{
                  fontSize: { xs: "22px", sm: "30px" },
                  cursor: "pointer",
                  color: "#0a1930 !important",
                  marginRight: "0px",
                }}
              />
            </Button>

            <Button onClick={(e) => handleNavigate("login")}>
              <Typography
                sx={{
                  fontSize: { xs: "15px", sm: "18px" },
                  fontWeight: "bold",
                  paddingRight: "0px",
                }}
              >
                Login
              </Typography>
              <LoginIcon
                sx={{
                  fontSize: { xs: "22px", sm: "30px" },
                  cursor: "pointer",
                  color: "#0a1930 !important",
                  marginRight: "10px",
                }}
              />
            </Button>
          </Box>
        )}
      </Box>
      <Divider />
      <List>
        {authItems.map((item, index) => (
          <div key={index}>
            <ListItem
              disablePadding
              sx={{
                "&:hover": {
                  transition: "background-color 0.3s ease",
                  backgroundColor: "lightgray",
                },
              }}
            >
              <ListItemButton
                sx={{ textAlign: "center" }}
                onClick={
                  item === "Functions"
                    ? handleFunctionsClick
                    : () => handleNavigate(item)
                }
              >
                <ListItemText
                  primary={item === "Functions" ? "Functions" : item}
                />
                {item === "Functions" && (
                  <ExpandMoreIcon sx={{ fontSize: "15px" }} />
                )}
              </ListItemButton>
            </ListItem>
            {item === "Functions" && openFunctions && <MobileFunctionSubMenu />}
          </div>
        ))}
      </List>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "center", padding: "15px" }}>
        <a
          href="https://github.com/zxwtstar"
          target="_blank"
          rel="noopener noreferrer"
        >
          <GitHubIcon className={classes.icon} />
        </a>
        <a
          href="https://linkedin.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon className={classes.icon} />
        </a>
        <a
          href="https://www.instagram.com/_daics_"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon className={classes.icon} />
        </a>
      </Box>
    </Box>
  );
}
