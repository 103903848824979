import axios from "axios";
const URL_SERVER = process.env.REACT_APP_URL_SERVER;

export default axios.create({
  baseURL: URL_SERVER,
});

export const axiosPrivate = axios.create({
  baseURL: URL_SERVER,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

export const axiosClient = axios.create({
  baseURL: URL_SERVER,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
