import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { axiosPrivate } from "../../../api/axios";
import MTable from "../../../components/Home/table/Table";
import Loading from "../../../components/UI/Loading";
import "./ByStructure.css";

const fileTypes = ["vasp", "cif", "cssr"];
const URL_STRUCTURE = process.env.REACT_APP_URL_STRUCTURE;

const searchByStructure = async (file) => {
  const response = await axiosPrivate.post(URL_STRUCTURE, {
    file: file,
  });
  return response;
};

const ByStructure = () => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [results, setResults] = useState([]);
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const handleChange = (selectedFile) => {
    setFileName(selectedFile.name);
    getBase64(selectedFile);
  };

  const executeScroll = () => {
    const element = document.getElementById("footer");
    element.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const sendRequest = () => {
    setIsLoading(true);
    searchByStructure(file)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          const extractedResults = data.flatMap((item) => item.props);
          setResults(extractedResults);
          setIsLoading(false);
          setLoaded(true);
          executeScroll();
          setError(false);
          setMessage("");
        }
      })
      .catch((error) => {
        setMessage(error.message);
        setError(true);
      });
  };

  const clearFile = () => {
    setFile(null);
    setError(false);
    setMessage("");
    setLoaded(false);
    setIsLoading(false);
  };

  const getBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFile(reader.result);
    };
    reader.onerror = (error) => {};
  };

  return (
    <Box>
      <Stack
        direction="row"
        spacing={3}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          // mt: { xs: 0, md: -5 },
        }}
      >
        <Button
          color="success"
          variant="contained"
          size="small"
          onClick={sendRequest}
        >
          Search
        </Button>
        <Button
          color="error"
          size="small"
          onClick={clearFile}
          variant="contained"
          sx={{
            mx: 10,
          }}
        >
          Clear
        </Button>
      </Stack>
      <FileUploader
        id="drop-box"
        children={
          <div className="drop--aria">
            Click to select *.cif or POSCAR/CONTCAR file.
            {file && (
              <>
                <br />
                <p>Selected file: {fileName}</p>{" "}
              </>
            )}
            <div id="footer"></div>
          </div>
        }
        handleChange={handleChange}
        name="file"
        types={fileTypes}
      ></FileUploader>
      {isError && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: "3vw",
          }}
        >
          <Typography variant="h6" color="error">
            {message}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          justifyItems: "center",
          my: "3vw",
        }}
      >
        {isLoading ? (
          <Loading type={"spinningBubbles"} color="#4A5073" />
        ) : loaded ? (
          <MTable data={results} />
        ) : null}
      </Box>
    </Box>
  );
};

export default ByStructure;
