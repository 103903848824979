import { MutatingDots } from "react-loader-spinner";

const Loading = ({ type, color }) => (
  // <ReactLoading type={type} color={color} height={"5%"} width={"5%"} />
  <MutatingDots
    visible={true}
    height="100"
    width="100"
    color="#0a1930"
    secondaryColor="#2ED4ED"
    radius="12.5"
    ariaLabel="mutating-dots-loading"
    wrapperStyle={{}}
    wrapperClass=""
  />
);

export default Loading;
