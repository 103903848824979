import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Pareto from "../../../assets/icon/Pareto.svg";
import Pettifor_blackWhite from "../../../assets/icon/Pettifor_blackWhite.svg";
import PhaseDiagram from "../../../assets/icon/PhaseDiagram.svg";
import Search from "../../../assets/icon/Search.svg";
// import XRD from "../../../assets/icon/XRD.svg";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: 12,
  },
}));

export default function Functions() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "18px !important",
        paddingBottom: "8px !important",
        flexWrap: "wrap",
        color: "black !important",
        background: "linear-gradient(to bottom, #124c5a, #549fb9)",
        marginTop: "0px",
        marginRight: { xs: "0px", md: "11px" },
        border: "1px solid #30435f !important",
        borderRadius: { xs: "none", md: "5px" },
        marginLeft: "0px",
        paddingRight: "0px",
        width: "100%",
      }}
    >
      <Link to="/database">
        <BootstrapTooltip title="Search" arrow placement="top-start">
          <Box
            component="img"
            sx={{
              height: "2rem",
              width: "auto",
              backgroundColor: "#EAE7DC",
              color: "#D9B08C",
              marginLeft: "10px",
              border: "1px solid rgb(137, 143, 136) !important",
              borderRadius: "50px",
              marginBottom: "10px",
            }}
            alt="Search"
            src={Search}
          />
        </BootstrapTooltip>
      </Link>
      <Link to="/phase_diagram">
        <BootstrapTooltip title="Phase Diagram" arrow placement="top-start">
          <Box
            component="img"
            sx={{
              height: "2rem",
              width: "auto",
              backgroundColor: "#EAE7DC",
              color: "#D9B08C",
              marginLeft: "10px",
              border: "1px solid rgb(137, 143, 136) !important",
              borderRadius: "50px",
              marginBottom: "10px",
            }}
            alt="PhaseDiagram"
            src={PhaseDiagram}
          />
        </BootstrapTooltip>
      </Link>
      {}
      <Link to="/pareto">
        <BootstrapTooltip title="Pareto plot" arrow placement="top-start">
          <Box
            component="img"
            sx={{
              height: "2rem",
              width: "auto",
              backgroundColor: "#EAE7DC",
              color: "#D9B08C",
              marginLeft: "10px",
              border: "1px solid rgb(137, 143, 136) !important",
              borderRadius: "50px",
              marginBottom: "10px",
            }}
            alt="Pareto"
            src={Pareto}
          />
        </BootstrapTooltip>
      </Link>
      <Link to="/pettifor">
        <BootstrapTooltip title="Pettifor's map" arrow placement="top-start">
          <Box
            component="img"
            sx={{
              height: "2rem",
              width: "auto",
              backgroundColor: "#EAE7DC",
              color: "#D9B08C",
              marginLeft: "10px",
              border: "1px solid rgb(137, 143, 136) !important",
              borderRadius: "50px",
              marginBottom: "10px",
            }}
            alt="Pettifor_blackWhite"
            src={Pettifor_blackWhite}
          />
        </BootstrapTooltip>
      </Link>
      {/* <Link to="/xrd">
        <BootstrapTooltip title="XRD matcher" arrow placement="top-start">
          <Box
            component="img"
            sx={{
              height: "2rem",
              width: "auto",
              backgroundColor: "#EAE7DC",
              color: "#D9B08C",
              marginLeft: "10px",
              border: "1px solid rgb(137, 143, 136) !important",
              borderRadius: "50px",
              marginBottom: "10px",
            }}
            alt="XRD"
            src={XRD}
          />
        </BootstrapTooltip>
      </Link> */}
    </Box>
  );
}
