import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { axiosClient } from "./axios";

const URL_REGISTER = process.env.REACT_APP_URL_REGISTER;
const URL_RESEND_EMAIL = process.env.REACT_APP_URL_RESEND_EMAIL;
const URL_EMAIL_TOKEN = process.env.REACT_APP_URL_VERIFY_EMAIL;
const URL_PROFILE = process.env.REACT_APP_URL_PROFILE;
const URL_FORGOT_PASSWORD = process.env.REACT_APP_URL_FORGOT_PASSWORD;
const URL_RESET_PASSWORD = process.env.REACT_APP_URL_RESET_PASSWORD;

export async function register(username, email, password, password2) {
  try {
    const response = await axiosClient.post(
      URL_REGISTER,
      JSON.stringify({
        username,
        email,
        password1: password,
        password2,
      }),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    if (error.request && error.request.response) {
      const errorResponse = JSON.parse(error.request.response);
      if (
        errorResponse &&
        errorResponse.username &&
        errorResponse.username.length > 0
      ) {
        throw new Error(errorResponse.username[0]);
      } else if (
        errorResponse &&
        errorResponse.email &&
        errorResponse.email.length > 0
      ) {
        throw new Error(errorResponse.email[0]);
      } else if (
        errorResponse &&
        errorResponse.password1 &&
        errorResponse.password1.length > 0
      ) {
        throw new Error(errorResponse.password1[0]);
      }
    }
    throw new Error("Error during registration");
  }
}

export async function resendEmail(email) {
  try {
    const response = await axiosClient.post(URL_RESEND_EMAIL, {
      email: email,
    });

    return response.data;
  } catch (error) {
    console.error("Error in resendEmail:", error);
    throw error;
  }
}

export async function sendEmailToken(token) {
  try {
    const response = await axiosClient.post(URL_EMAIL_TOKEN, {
      key: token,
    });

    return response.data;
  } catch (error) {
    console.error("Error in sendEmailToken:", error.response.status);
    return error.response.status;
  }
}

export async function forgotPassword(email) {
  try {
    const response = await axiosClient.post(URL_FORGOT_PASSWORD, {
      email: email,
    });

    return response;
  } catch (error) {
    console.error("Error in forgotPassword:", error);
    throw error;
  }
}

export async function resetPassword(uid, token, newPassword1, newPassword2) {
  try {
    const response = await axiosClient.post(URL_RESET_PASSWORD, {
      uid: uid,
      token: token,
      new_password1: newPassword1,
      new_password2: newPassword2,
    });

    return response.data;
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
}

export async function SetNewEmail(userData) {
  const axiosPrivate = useAxiosPrivate();
  try {
    const formData = new FormData();
    formData.append("email", userData.email);

    const response = await axiosPrivate.put(URL_PROFILE, formData);
    return response;
  } catch (error) {
    console.error("Error setting user email:", error);
    throw error;
  }
}
