import { useEffect, useState } from "react";
import useGetUserInfo from "../../../hooks/useGetUserInfo";
import CountrySelect from "./CountryList";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useRefreshToken from "../../../hooks/useRefreshToken";
const URL_PROFILE = process.env.REACT_APP_URL_PROFILE;

const commonInputProps = {
  InputProps: {
    sx: {
      py: 1.5,
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      borderRadius: "6px",
      marginBottom: 3,
    },
  },
};

export default function GetUserData() {
  const refresh = useRefreshToken();
  const axiosPrivate = useAxiosPrivate();
  const user = useGetUserInfo();
  const [userData, setUserData] = useState(user);
  useEffect(() => {
    setUserData(user);
  }, [user]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [instEmail, setInstEmail] = useState("");
  const [avatar, setAvatar] = useState(
    localStorage.getItem("userAvatarBase64") || ""
  );

  useEffect(() => {
    const avatarUpdate = localStorage.getItem("avatarUpdate") || "false";

    const fetchAvatarAndUpload = async () => {
      const avatarBase64 = localStorage.getItem("userAvatarBase64") || "";
      setAvatar(avatarBase64);
      if (avatarBase64 !== "" && avatarUpdate === "true") {
        const formData = new FormData();
        formData.append("avatar", avatarBase64);

        try {
          const response = await axiosPrivate.put(URL_PROFILE, formData);
          localStorage.setItem("avatarUpdate", false);
        } catch (error) {
          console.error("Error uploading avatar:", error);
        }
      }
    };

    fetchAvatarAndUpload();
  }, []);

  useEffect(() => {
    setErrorMessage("");
    setSuccessMessage("");
    const accountEmails = userData.account_emails;

    accountEmails.forEach((emailInfo) => {
      if (!emailInfo.primary) {
        setInstEmail(emailInfo.email);
      }
    });
  }, [userData]);

  const handleChangeCountry = (event, newValue) => {
    setUserData({ ...userData, country: newValue.label ? newValue.code : "" });
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (name === "dob") {
      if (dayjs(value).isValid()) {
        const formattedDate = dayjs(value).format("YYYY-MM-DD");

        setUserData({ ...userData, [name]: formattedDate });
      } else {
        console.error("Invalid date format");
      }
    } else {
      const newValue = type === "checkbox" ? checked : value;
      setUserData({ ...userData, [name]: newValue });
    }
  };

  const setProfile = async (userData) => {
    try {
      const formData = new FormData();
      if (avatar) {
        formData.append("avatar", avatar);
      }
      formData.append("first_name", userData.firstName);
      formData.append("last_name", userData.lastName);
      formData.append("institute", userData.institute);
      formData.append("institutional_email", instEmail);
      formData.append("prefix", userData.prefix);
      formData.append("number", userData.number);
      formData.append("birth_date", dayjs(userData.dob).format("YYYY-MM-DD"));
      formData.append("city", userData.city);
      formData.append("zip", userData.zip);
      formData.append("country", userData.country);
      formData.append("website", userData.website);
      formData.append("is_subscribed", userData.subscribe);

      const response = await axiosPrivate.put(URL_PROFILE, formData);

      return response;
    } catch (error) {
      console.error("Error sending user profile:", error);
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await setProfile(userData);

      setSuccessMessage("Profile updated successfully!");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          await refresh();

          const updatedResponse = await setProfile(userData);

          setSuccessMessage("Profile updated successfully!");
        } catch (refreshError) {
          console.error("Error refreshing token:", refreshError);
          setErrorMessage("Unable to update profile. Please try again.");
        }
      } else {
        setErrorMessage("Unable to update profile. Please try again.");
        console.error("Failed to update profile:", error.message);
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          maxWidth: "800px",
          minWidth: "400px",
          margin: "40px auto",
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "40px",
          backgroundColor: "rgba(255, 255, 255, 0.94)",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        {" "}
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            mt: 3,
            mb: 3,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            {...commonInputProps}
            id="username"
            label="Username"
            value={userData.username}
            disabled
          />
          <TextField
            {...commonInputProps}
            disabled
            id="email"
            label="Primary Email Address"
            value={userData.email}
          />
          <TextField
            id="email"
            label="Institute Email Address"
            disabled
            helperText="*Recommended to add, if different from the primary email"
            value={instEmail}
            InputProps={{
              sx: {
                py: 1.5,
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                borderRadius: "6px",
              },
            }}
            sx={{
              marginBottom: 3,
              color: "#000 !important",
            }}
            onChange={(e) =>
              handleChange({
                target: { name: "instituteEmail", value: e.target.value },
              })
            }
          />
          <FormControl sx={{ marginBottom: 3 }}>
            <InputLabel htmlFor="prefix">Title:</InputLabel>
            <Select
              name="prefix"
              id="prefix"
              value={userData.prefix}
              onChange={(e) =>
                handleChange({
                  target: { name: "prefix", value: e.target.value },
                })
              }
            >
              <MenuItem value="Mr." sx={{ paddingLeft: "50px" }}>
                Mr.
              </MenuItem>
              <MenuItem value="Mrs." sx={{ paddingLeft: "50px" }}>
                Mrs.
              </MenuItem>
              <MenuItem value="Ms." sx={{ paddingLeft: "50px" }}>
                Ms.
              </MenuItem>
              <MenuItem value="Dr." sx={{ paddingLeft: "50px" }}>
                Dr.
              </MenuItem>
              <MenuItem value="Prof." sx={{ paddingLeft: "50px" }}>
                Prof.
              </MenuItem>
              <MenuItem value="Others" sx={{ paddingLeft: "50px" }}>
                Others
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            {...commonInputProps}
            type="text"
            id="firstName"
            label="First Name"
            value={userData.firstName}
            onChange={(e) =>
              handleChange({
                target: { name: "firstName", value: e.target.value },
              })
            }
            required
          />
          <TextField
            {...commonInputProps}
            type="text"
            id="lastName"
            label="Last Name"
            value={userData.lastName}
            onChange={(e) =>
              handleChange({
                target: { name: "lastName", value: e.target.value },
              })
            }
            required
          />
          {}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DemoItem>
                <DatePicker
                  label="Date of Birth"
                  value={userData.dob || null}
                  format="YYYY-MM-DD"
                  disableFuture={true}
                  minDate={dayjs().subtract(90, "years")}
                  maxDate={dayjs().subtract(15, "years")}
                  onChange={(date) =>
                    handleChange({
                      target: { name: "dob", value: date },
                    })
                  }
                  sx={{
                    pb: 3,
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    "& input": {
                      padding: "28px 15px",
                    },
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>

          <TextField
            {...commonInputProps}
            type="text"
            id="institute"
            label="Institute"
            value={userData.institute}
            onChange={(e) =>
              handleChange({
                target: { name: "institute", value: e.target.value },
              })
            }
            required
          />
          <TextField
            {...commonInputProps}
            type="text"
            id="city"
            label="City"
            value={userData.city}
            onChange={(e) =>
              handleChange({
                target: { name: "city", value: e.target.value },
              })
            }
          />
          <TextField
            {...commonInputProps}
            type="text"
            id="zip"
            label="Zip"
            value={userData.zip}
            onChange={(e) =>
              handleChange({
                target: { name: "zip", value: e.target.value },
              })
            }
          />
          <CountrySelect
            id="country"
            label="Country"
            value={userData.country}
            onChange={handleChangeCountry}
          />
          <TextField
            {...commonInputProps}
            type="text"
            id="number"
            label="Phone"
            value={userData.number}
            onChange={(e) =>
              handleChange({
                target: { name: "number", value: e.target.value },
              })
            }
            placeholder="e.g., +1 1234567890"
            sx={{ marginTop: 3 }}
          />
          <TextField
            {...commonInputProps}
            type="url"
            id="website"
            label="Website"
            value={userData.website}
            onChange={(e) =>
              handleChange({
                target: { name: "website", value: e.target.value },
              })
            }
          />
          <FormGroup>
            <FormControlLabel
              sx={{ marginBottom: 1 }}
              control={
                <Switch
                  checked={userData.subscribe}
                  onChange={handleChange}
                  name="subscribe"
                />
              }
              label="Subscribe to newsletter and receive updates"
            />
          </FormGroup>
          <Button variant="contained" color="primary" type="submit">
            Update Profile
          </Button>
        </Box>
        {successMessage && (
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 18,
              color: "green",
            }}
          >
            {successMessage}
          </Typography>
        )}
        {errorMessage && (
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 18,
              color: "red",
            }}
          >
            {errorMessage}
          </Typography>
        )}
      </Box>
    </>
  );
}
