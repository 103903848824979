import { useEffect, useRef } from "react";
import * as echarts from "echarts";
import Box from "@mui/material/Box";

const BarChart = ({ data, x_label, y_label, height, width }) => {
  const chartRef = useRef(null);
  useEffect(() => {
    const chart = echarts.init(chartRef.current);
    const option = {
      tooltip: {
        axisPointer: {
          type: "cross",
        },
      },

      toolbox: {
        show: true,
        feature: {
          show: true,
          dataZoom: {},
          saveAsImage: { name: "ParetoPlot", pixelRatio: 2 },
        },
      },
      xAxis: {
        type: "category",
        data: data[0],
        name: x_label,
        nameGap: 35,
        nameLocation: "middle",
        nameTextStyle: {
          fontSize: 14,
        },
        axisLine: { onZero: false },
        splitLine: {
          show: true,
        },
      },
      yAxis: {
        type: "value",
        name: y_label,
        nameGap: 45,
        nameRotate: 90,
        nameLocation: "middle",
        axisLine: { onZero: false },
        nameTextStyle: {
          fontSize: 14,
        },
        splitLine: {
          show: true,
        },
      },
      itemStyle: {
        borderColor: "#555",
      },
      series: [
        {
          type: "bar",
          data: data[1],
          color: "#000000",
          barWidth: 2,
        },
      ],
      grid: {
        left: 50,
        right: 20,
        bottom: 40,
        containLabel: true,
      },
    };

    chart.setOption(option);
    return () => {
      chart.dispose();
    };
  }, [data, x_label, y_label, width, height]);
  let size;
  if (window.matchMedia("screen and (max-width: 768px)").matches) {
    size = ["90vw", "90vw"];
  } else {
    size = ["90vw", "45vw"];
  }
  return (
    <Box
      ref={chartRef}
      style={{
        width: width ? width : size[0],
        height: height ? height : size[1],
      }}
    />
  );
};

export default BarChart;
