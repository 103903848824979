import { Fragment } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/HomePage/Home";
import DataBase from "./pages/HomePage/DataBase";
import Results from "./pages/Results/Results";
import Login from "./pages/Register/Login";
import SignUp from "./pages/Register/SignUp";
import ResetPassword from "./pages/Register/ResetPassword";
import ForgotPassword from "./pages/Register/ForgotPassword";
import Pettifor from "./pages/Pettifor/Pettifor";
import Pareto from "./pages/Pareto/Pareto";
// import XRD from "./pages/XRD/XRD";
import PhaseDiagram from "./pages/PhaseDiagram/PhaseDiagram";
import News from "./pages/News/News";
import Tutorials from "./pages/Tutorials/Tutorials";
import Contact from "./pages/Contact/ContactPage";
import AboutUs from "./pages/AboutUs/AboutUs";
import TermsOfUse from "./pages/Policy/TermsOfUse";
import PrivacyPolicy from "./pages/Policy/PrivacyPolicy";
import CookiePolicy from "./pages/Policy/CookiePolicy";
import EmailConfirm from "./pages/EmailConfirm/EmailConfirm";
import Panel from "./pages/UserPanel/UserPanel";
import Zahed from "./pages/ZahedPage/ZxwtStar";
import IndividualNewsPage from "./components/News/IndividualNewsPage";
import ExampleXRD from "./pages/XRD/ExampleXRD";
import NotFoundPage from "./pages/Utils/PageNotFound";
import Unauthorized from "./pages/Utils/Unauthorized";
import { PersistLogin, RequireAuth } from "./components/index";
import HandleGoogle from "./components/Google/HandleGoogle";
import useAuth from "./hooks/useAuth";
import CookiesConsent from "./components/Cookies/CookiesConsent";
import "./App.css";

const ROLES = {
  Basic: "Basic",
  Standard: "Standard",
  Premium: "Premium",
};
function App() {
  const { auth } = useAuth();

  const PublicRoute = ({ element }) =>
    !auth.accessToken ? element : <Navigate to="/" replace />;

  return (
    <>
      <Routes>
        <Fragment>
          <Route element={<PersistLogin />}>
            {}
            <Route
              path="/login"
              element={<PublicRoute element={<Login />} />}
            />
            <Route
              path="/register"
              element={<PublicRoute element={<SignUp />} />}
            />
            <Route
              path="/forgotPassword"
              element={<PublicRoute element={<ForgotPassword />} />}
            />
            <Route
              path="/resetPassword/:uid/:token"
              element={<PublicRoute element={<ResetPassword />} />}
            />

            <Route exact path="/" element={<Home />} />
            <Route path="/news/:newsId" element={<IndividualNewsPage />} />
            <Route exact path="/news" element={<News />} />
            <Route exact path="/tutorials" element={<Tutorials />} />
            <Route exact path="/about-us" element={<AboutUs />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/zxwtstar" element={<Zahed />} />
            <Route path="/handle_google/:key" element={<HandleGoogle />} />

            <Route
              path="/all_auth_accounts/confirm-email/:token"
              element={<EmailConfirm />}
            />

            <Route
              exact
              path="/terms-and-conditions"
              element={<TermsOfUse />}
            />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/cookie-policy" element={<CookiePolicy />} />
            <Route exact path="/experimental-xrd" element={<ExampleXRD />} />

            {}
            <Route
              element={
                <RequireAuth
                  allowedRoles={[ROLES.Basic, ROLES.Standard, ROLES.Premium]}
                />
              }
            >
              <Route exact path="/database" element={<DataBase />} />
              <Route exact path="/phase_diagram" element={<PhaseDiagram />} />
              {}
              <Route exact path="/compound/:name" element={<Results />} />
              <Route exact path="/userpanel" element={<Panel />} />
            </Route>

            <Route
              element={
                <RequireAuth allowedRoles={[ROLES.Standard, ROLES.Premium]} />
              }
            >
              {/* <Route exact path="/xrd" element={<XRD />} /> */}
              <Route exact path="/pettifor" element={<Pettifor />} />
              <Route exact path="/pareto" element={<Pareto />} />
            </Route>
          </Route>
        </Fragment>
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <CookiesConsent />
    </>
  );
}

export default App;
