import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import useAuth from "../../hooks/useAuth";

const FunctionItems = () => {
  const { auth } = useAuth();
  const [functionItems, setFunctionItems] = useState({});

  useEffect(() => {
    const decoded = auth?.accessToken
      ? jwtDecode(auth?.accessToken)
      : undefined;

    const userRoles = decoded?.roles;

    if (
      userRoles &&
      (userRoles.includes("Standard") || userRoles.includes("Premium"))
    ) {
      setFunctionItems({
        database: "Search in Database",
        phase_diagram: "Phase Diagram",
        pareto: "Pareto optimality - Ashby plot",
        pettifor: "Pettifor map - Materials Prediction",
        // XRD: "XRD Tools",
      });
    } else if (userRoles && userRoles.includes("Basic")) {
      setFunctionItems({
        database: "Search in Database",
        phase_diagram: "Phase Diagram",
      });
    } else {
      setFunctionItems({});
    }
  }, [auth]);

  return functionItems;
};

export default FunctionItems;
