import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useCSRFToken from "../../../hooks/useCSRFToken";

const URL_ACCOUNT_EMAIL = process.env.REACT_APP_URL_ACCOUNT_EMAIL;

const ChangeEmail = () => {
  const [email, setEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [accountEmails, setAccountEmails] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const csrfToken = useCSRFToken();

  const fetchData = async () => {
    try {
      const response = await axiosPrivate.get(
        process.env.REACT_APP_URL_PROFILE
      );
      setEmail(response.data.data.email);
      setAccountEmails(response.data.data.account_emails);
      setIsLoaded(true);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [successMessage]);

  const handleSubmit = async (e, currentemail, actionType) => {
    e.preventDefault();
    setSuccessMessage("");

    try {
      const response = await axiosPrivate.post(
        URL_ACCOUNT_EMAIL,
        { email: currentemail, [actionType]: true },
        {
          headers: {
            "X-CSRFToken": csrfToken,
            accept:
              "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7",
          },
        }
      );

      if (actionType === "action_add") {
        setSuccessMessage(
          "Email address added. Please check your inbox and verify your email."
        );
      } else if (actionType === "action_remove") {
        setSuccessMessage("Email address removed successfully.");
      } else if (actionType === "action_primary") {
        setSuccessMessage("Primary email address changed successfully.");
      } else if (actionType === "action_send") {
        setSuccessMessage("Verification code sent to your new email address.");
      }

      setIsEditing(false);
      setErrorMessage("");
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage("Unable to perform the action.");
      console.error(`Error performing ${actionType}:`, error);
    }
  };

  return (
    <>
      <form>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "600px",
            minWidth: "400px",
            margin: "40px auto",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "30px",
            backgroundColor: "rgba(255, 255, 255, 0.94)",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Box
            component="form"
            noValidate
            sx={{
              mt: 1,
              mb: 2,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography variant="h6" sx={{ marginBottom: "10px" }}>
              Change Email
            </Typography>

            {!isLoaded ? (
              <TextField
                label="Email"
                type="email"
                value={email}
                disabled={true}
                sx={{
                  py: 1.5,
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  borderRadius: "6px",
                }}
              />
            ) : (
              accountEmails.map((emailInfo, index) => (
                <Box
                  key={index}
                  sx={{
                    mt: 1,
                    mb: 2,
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <TextField
                    label={
                      isEditing
                        ? "Email"
                        : emailInfo.primary
                        ? "Primary Email"
                        : "Secondary Email"
                    }
                    type="email"
                    value={isEditing ? newEmail : accountEmails[index].email}
                    disabled={!isEditing}
                    onChange={(e) => setNewEmail(e.target.value)}
                    sx={{
                      py: 1.5,
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                      borderRadius: "6px",
                      marginBottom: 2,
                    }}
                  />

                  {accountEmails.length === 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={
                        isEditing
                          ? (e) => handleSubmit(e, newEmail, "action_add")
                          : () => {
                              setIsEditing(!isEditing);
                              setSuccessMessage("");
                              setErrorMessage("");
                            }
                      }
                    >
                      {isEditing ? "Add Email" : "Change Email"}
                    </Button>
                  )}

                  {!emailInfo.primary && emailInfo.verified && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="success"
                        onClick={(e) =>
                          handleSubmit(
                            e,
                            accountEmails[index].email,
                            "action_primary"
                          )
                        }
                        sx={{ marginBottom: "10px" }}
                      >
                        Make primary
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={(e) =>
                          handleSubmit(
                            e,
                            accountEmails[index].email,
                            "action_remove"
                          )
                        }
                        sx={{ marginBottom: "10px" }}
                      >
                        Remove email
                      </Button>
                    </Box>
                  )}

                  {!emailInfo.primary && !emailInfo.verified && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={(e) =>
                          handleSubmit(
                            e,
                            accountEmails[index].email,
                            "action_send"
                          )
                        }
                        sx={{ marginBottom: "10px" }}
                      >
                        Resend verification code
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={(e) =>
                          handleSubmit(
                            e,
                            accountEmails[index].email,
                            "action_remove"
                          )
                        }
                        sx={{ marginBottom: "10px" }}
                      >
                        Remove email
                      </Button>
                    </Box>
                  )}
                </Box>
              ))
            )}
            {successMessage && (
              <Typography style={{ color: "green" }}>
                {successMessage}
              </Typography>
            )}

            {errorMessage && (
              <Typography style={{ color: "red" }}>{errorMessage}</Typography>
            )}
          </Box>
        </Box>
      </form>
    </>
  );
};

export default ChangeEmail;
