import { Footer, Header } from "../../components/index.js";
import "./PolicyStyle.css";

const TermsOfUse = () => {
  return (
    <>
      <Header />
      <br />
      <div sx={{ height: "100%" }}>
        <div className="policy-container">
          {}
          <section>
            <h1>Terms and Conditions of Use</h1>
            <p>
              These terms and conditions ("Terms") govern the use of the DAICS
              database ("Database" or "DAICS") offered by Zahed Allahyari
              ("Provider"). By accessing or using the Database, you agree to be
              bound by these Terms. If you do not agree to these Terms, please
              do not access or use the Database.
            </p>
          </section>

          <section>
            <h2>1. Access and Use</h2>
            <p>
              <h3>1.1. Membership Plans</h3>
              The Database currently offers two membership plans for access:
              <br></br>
              a. <b>**Basic Plan**</b> : Upon registration, users are granted
              the Basic Plan. Under this plan, users may:
              <br></br>
              i. View all data via the web interface.<br></br>
              ii. Use provided search features on the web interface.<br></br>
              iii. Access to phase diagram function is included in this plan.
              <br></br>
              iv. Reference the DAICS when using data obtained under this plan.
              <br></br>
            </p>

            {}
            <p>
              b. <b>**Premium Plan**</b> : The Premium Plan delivers complete
              Database access, including access to the provided premium
              functions under the web interface, i.e. multi-objective pareto
              ranking, Pettifor's chart, and provided Xrd tools, and the ability
              to download data for machine-readable applications. To access the
              Premium Plan, users must pay the designated fee. This plan is
              tailored for larger groups, such as universities, institutes, and
              companies. Please be aware that only individuals within the
              purchasing organization are authorized to utilize the data. Any
              unauthorized transfer of data to third parties constitutes a
              violation of our Terms of Use.
            </p>
          </section>

          <section>
            <p>
              <h3>1.2. Use Restrictions</h3>
              Regardless of the membership plan, you agree to use the Database
              only for lawful purposes and in accordance with these Terms. You
              may not:<br></br>
              a. Modify, adapt, reverse engineer, decompile, disassemble, or
              otherwise attempt to access or derive the source code of the
              Database.<br></br>
              b. Distribute, publish, or share the Database or any part thereof
              without explicit permission from the Provider.<br></br>
              c. Use the Database in any way that infringes upon the
              intellectual property rights, privacy, or other rights of third
              parties.<br></br>
              d. Use automated scripts or software to access, collect, or
              interact with the Database without permission.<br></br>
              e. Use the Database for any illegal, unethical, or harmful
              purposes.<br></br>
            </p>
          </section>

          <section>
            <h2>2. Membership Fees and Access Duration</h2>
            <p>
              <h3>2.1. Fees</h3>
              Access to each membership plan is subject to a fee, as specified
              on the DAICS website (
              <a href="https://daics.net" style={{ fontSize: "20px" }}>
                https://daics.net
              </a>
              ). Users are responsible for paying the appropriate fee to access
              their chosen plan.
              <br></br>
              <h3>2.2. Access Duration</h3>
              Each membership plan is valid for the duration specified on the
              Website. Users will have access to the plan's features during this
              period.
            </p>
          </section>

          <section>
            <h2>3. Intellectual Property</h2>
            <p>
              <h3>3.1. Ownership</h3>
              The Database and all related content, including but not limited to
              text, images, logo, data, and software, are the property of the
              Provider and are protected by copyright and other intellectual
              property laws.
              <h3>3.2. License</h3>
              Subject to compliance with these Terms and the selected membership
              plan, the Provider grants you a limited, non-exclusive,
              non-transferable, revocable license to access and use the Database
              solely for your personal or internal purposes.
            </p>
          </section>

          <section>
            <h2>4. Privacy</h2>
            <p>
              <h3>4.1. Privacy Policy</h3>
              Your use of the Database is also governed by the Provider's
              Privacy Policy, which can be found on the Website.
            </p>
          </section>

          <section>
            <h2>5. Termination</h2>
            <p>
              <h3>5.1. Termination</h3>
              The Provider retains the right to cease or suspend your access to
              the Database at any time, without prior notice, for any reason,
              including, but not limited to, a violation of these Terms. In the
              event that a member of an organization breaches these terms and
              conditions (e.g., as described in section 1.2), the entire
              organization may face suspension or termination of its membership
            </p>
          </section>

          <section>
            <h2>6. Disclaimer</h2>
            <p>
              <h3>6.1. No Warranty</h3>
              The Database is provided “as is” and “as available” without any
              warranties, express or implied. The Provider makes no
              representations or warranties regarding the accuracy,
              completeness, or reliability of the Database.
            </p>
          </section>

          <section>
            <h2>7. Governing Law</h2>
            <p>
              <h3>7.1. Governing Law</h3>
              Governing Law: Any claims, disputes, or complaints arising out of
              or in connection with these Terms of Use, that cannot be resolved
              through amicable negotiations between the parties, shall be
              exclusively submitted to and resolved by the competent courts of
              the Republic of Estonia. Both parties hereby consent to the
              personal jurisdiction of such courts and waive any objection to
              venue in those courts on any ground.
            </p>
          </section>

          <section>
            <h2>8. Contact Information</h2>
            <p>
              <h3>8.1. Contact</h3>
              For inquiries or concerns related to these Terms, the Database, or
              membership plans, please contact{" "}
              <a href="mailto:info@daics.net" style={{ fontSize: "20px" }}>
                info@daics.net
              </a>
              |{" "}
              <a
                href="mailto:info.daics@gmail.com"
                style={{ fontSize: "20px" }}
              >
                {" "}
                info.daics@gmail.com.
              </a>
            </p>
          </section>

          <section>
            <h2>9. Citation and Attribution</h2>
            <p>
              <h3>9.1. Citation Requirement</h3>
              Users who access and utilize data from the DAICS for any works,
              publications, conferences, presentations, or other forms of
              dissemination are required to provide proper citation and
              attribution to the Database as the source of the data.
              <h3>9.2. Citation Format</h3>
              When citing the DAICS, users should follow a citation format
              specified by the Provider. The citation format may include, but is
              not limited to, the Database's name, URL, release date, and
              relevant dataset identifiers.
              <h3>9.3. Publication Acknowledgment</h3>
              In the case of publications, users should include an
              acknowledgment section in their work explicitly recognizing the
              use of data from the DAICS and providing the citation.
              <h3>9.4. Conference Presentations</h3>
              For conference presentations, posters, and talks, users should
              acknowledge the use of data from the DAICS during the presentation
              and provide the citation as part of the presentation materials.
              <h3>9.5. Notification of Usage</h3>
              Users are encouraged to notify the Provider when they have
              utilized data from the Database in their works, publications, or
              presentations. This helps the Provider track the impact and use of
              the Database.
              <h3>9.6. Usage Tracking</h3>
              The Provider may use tracking mechanisms or other methods to
              monitor compliance with citation and attribution requirements.
            </p>
          </section>

          <section>
            <h2>10. Intellectual Property</h2>
            <p>
              <h3>10.1. Ownership</h3>
              The citation and attribution requirements do not diminish or alter
              the Provider's ownership of the Database and its contents. Users
              are granted access and use rights in accordance with their
              selected membership plan and these Terms.
              <br></br>
              Failure to properly cite and attribute the Database as specified
              herein may result in a violation of these Terms.
            </p>
          </section>

          <section>
            <p>
              <b>
                By accessing or using the Database, you acknowledge that you
                have read, understood, and agreed to these Terms. These Terms
                may be updated or modified by the Provider from time to time,
                and such changes will be effective upon posting on the Website.
                It is your responsibility to review these Terms periodically.
              </b>
              <br></br>
              <br></br>
              <b>Last updated: February 22, 2024.</b>
            </p>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TermsOfUse;
