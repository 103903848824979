import axios from "axios";
import { useEffect, useState } from "react";

const GetIPData = () => {
  const [ipData, setIpData] = useState({
    ip: "",
    country: "",
    country_name: "",
    timezone: "",
    org: "",
    asn: "",
    postal: "",
    city: "",
  });

  const getGeoInfo = async () => {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      let data = response.data;
      setIpData({
        ip: data.ip,
        country: data.country_code,
        country_name: data.country_name,
        timezone: data.timezone,
        org: data.org,
        asn: data.asn,
        postal: data.postal,
        city: data.city,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  return ipData;
};

export default GetIPData;
