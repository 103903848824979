import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import fetchNews from "../../api/NewsAPI";
import { formatDate } from "../../components/Utils/FormatData.js";
import { Footer, Header } from "../../components/index.js";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const EachGrid = ({ id, author, title, description, image, content, date }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        minHeight: "500px",
      }}
    >
      <Link
        to={`/news/${id}`}
        target="_blank"
        rel="noreferrer"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Card
          key={id}
          sx={{
            display: "flex",
            flexDirection: "column",
            boxShadow: "none",
            marginRight: "-10px",
          }}
        >
          {image && (
            <CardMedia
              component="img"
              image={image}
              title={title}
              sx={{
                objectFit: "cover",
                width: "100%",
                height: "220px",
              }}
            />
          )}
          <CardContent
            sx={{
              flexGrow: 1,
              textAlign: "left",
              overflow: "auto",
              height: "250px",
            }}
          >
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          </CardContent>
        </Card>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            paddingTop: "10px",
            paddingBottom: "0px",
            borderTop: "1px solid black",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            Date: {formatDate(date)}
          </Typography>
          <Link to={`/news/${id}`} target="_blank" rel="noreferrer">
            <Button size="small">Learn More</Button>
          </Link>
        </Box>
      </Link>
    </div>
  );
};

const News = () => {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const getNews = async () => {
      const fetchedNews = await fetchNews();
      setNewsData(fetchedNews);
    };

    getNews();
  }, []);

  return (
    <>
      <Header />
      <Box
        sx={{ flexGrow: 1, margin: "20px", minHeight: `calc(100vh - 260px)` }}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 1, sm: 8, md: 12 }}
        >
          {newsData.length === 0 ? (
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              No news to show yet, please stay tuned!
            </Typography>
          ) : (
            newsData.map((news, index) => (
              <Grid xs={2} sm={4} md={4} key={index}>
                <Item>
                  <EachGrid
                    id={news.code}
                    author={news.author}
                    title={news.title}
                    description={news.description}
                    image={news.image}
                    content={news.content}
                    date={news.create}
                  />
                </Item>
              </Grid>
            ))
          )}
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default News;
