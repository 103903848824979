import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import DBStatistics from "../DBStatistics/DBStatistics";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
}));

const Details = (props) => {
  const items = props.items;
  return (
    <div className="details">
      <Grid container spacing={{ xs: 2, md: 3, pb: 10 }}>
        {Object.keys(items).map((onKey, i) =>
          items[onKey] > 0 ? (
            <Grid item xs={6} sm={4} md={3} key={i}>
              <Item className="database-box">
                <p className="database_records">
                  <DBStatistics reff={onKey} number={items[onKey]} />
                </p>
                {}
                <p className="database_records">
                  {onKey === "Electrides" ? "Electride fitness" : onKey}
                </p>
              </Item>
            </Grid>
          ) : null
        )}
      </Grid>
    </div>
  );
};

export default Details;
