import * as echarts from "echarts";
import { useEffect, useRef } from "react";

const HeatmapChart = ({ data, label, colorbar_label, send }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = echarts.init(chartRef.current);

    const heatMapData = data.chartinfo;
    const maxValue = data.maxvalue;
    const axisData = [];
    const xData = [];
    const yData = [];
    const maxZ = [];
    function capitalizeFirstLetter(string) {
      const str_arr = string.split(" ");

      for (let i = 0; i < str_arr.length; i++) {
        str_arr[i] = str_arr[i][0].toUpperCase() + str_arr[i].slice(1);
      }
      return str_arr.join(" ");
    }
    Object.entries(heatMapData).forEach(([key, value]) => {
      axisData.push({
        name: key,
        value: [value.X, value.Y, value.Z],
      });
      maxZ.push(value.Z);
    });

    for (var j = 0; j < maxValue; j++) {
      yData.push(j);
      xData.push(j);
    }
    const lebelWithUnit = {
      hardness: "Hardness (GPa)",
      magnetization: "Magnetization (μ_B/Å^3)",
      "enthalpy of formation": "Enthalpy of formation (eV/atom)",
      "atomization energy": "Atomization energy (eV/atom)",
      Density: "Density (g/cm^3)",
      "ML Bulk Modulus": "ML Bulk Modulus (GPa)",
      "ML Shear Modulus": "ML Shear Modulus (GPa)",
      "ML Young Modulus": "ML Young Modulus (GPa)",
      "ML Pugh Modulus": "ML Pugh Modulus ",
      "ML Possions ratio": "ML Possions ratio",
      "ML Vickers hardness": "ML Vickers hardness (GPa)",
      "ML Fracture toughness": "ML Fracture toughness (MPa m^0.5)",
    };
    let itemH;
    itemH = "200em";
    const option = {
      grid: {
        bottom: 20,
        containLabel: true,
        top: 70,
      },
      title: {
        subtext: lebelWithUnit[colorbar_label.replaceAll("_", " ")],
        left: "center",
        textStyle: {
          fontSize: "1rem",
        },
        subtextStyle: {
          fontSize: 11,
        },
      },
      toolbox: {
        show: true,
        feature: {
          show: true,
          dataZoom: {},
          saveAsImage: { name: "PettiforsMap", pixelRatio: 2 },
        },
      },
      tooltip: {
        formatter: function (params) {
          return `${params.name}<br><hr size=1 style="margin: 3px 0">Z: ${params.value[2]}`;
        },
      },
      xAxis: {
        type: "category",
        data: xData,
        name: capitalizeFirstLetter(label.replaceAll("_", " ")),
        nameGap: 25,
        nameLocation: "middle",
        nameTextStyle: {
          fontSize: 14,
        },
        splitLine: {
          show: false,
        },
        min: 1,
      },
      yAxis: {
        type: "category",
        data: yData,
        name: capitalizeFirstLetter(label.replaceAll("_", " ")),
        nameGap: 30,
        nameLocation: "middle",
        min: 1,
        nameTextStyle: {
          fontSize: 14,
        },
        splitLine: {
          show: false,
        },
      },
      visualMap: {
        orient: "horizontal",
        left: "center",
        top: 25,
        min: Math.min(...maxZ),
        max: Math.max(...maxZ),
        calculable: true,
        realtime: false,
        inRange: {
          color: [
            "#313695",
            "#4575b4",
            "#74add1",
            "#abd9e9",
            "#e0f3f8",
            "#ffffbf",
            "#fee090",
            "#fdae61",
            "#f46d43",
            "#d73027",
            "#a50026",
          ],
        },
        itemHeight: itemH,
        itemWidth: "15em",
      },
      series: [
        {
          name: "Heatmap",
          type: "heatmap",
          data: axisData,
          label: {
            show: false,
          },
        },
      ],
    };

    chart.setOption(option);
    chart.on("click", function (params) {
      send(params.name.replace("-", ", "));
    });
    return () => {};
  }, [colorbar_label, data, label]);
  let size;
  if (window.matchMedia("screen and (max-width: 768px)").matches) {
    size = ["90vw", "90vw"];
  } else {
    size = ["45vw", "45vw"];
  }
  return <div ref={chartRef} style={{ width: size[0], height: size[1] }} />;
};

export default HeatmapChart;
