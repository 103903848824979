import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { useState } from "react";
import Loading from "../../components/UI/Loading";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const URL_DBI_PLOT_DATA = process.env.REACT_APP_URL_DBI_PLOT_DATA;

function DosBandImage({ matId }) {
  const [DBIPlotImage, setDBIPlotImage] = useState(false);
  const [DBIPlotLoaded, setDBIPlotLoaded] = useState(false);
  const [DBIPlotIsLoading, setDBIPlotIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [Error, setError] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  async function getDBIPlot(params) {
    const response = await axiosPrivate.get(URL_DBI_PLOT_DATA, {
      params: params,
    });
    return response;
  }
  const sendDBIPlot = (event) => {
    const params = {
      mat_id: matId,
    };
    setDBIPlotIsLoading(true);
    getDBIPlot(params)
      .then((response) => {
        if (response.status === 200) {
          setDBIPlotImage(response.data.image);
          setDBIPlotIsLoading(false);
          setDBIPlotLoaded(true);
        }
      })
      .catch((e) => {
        setMessage(e.response);
        setError(true);
      });
  };
  return (
    <>
      <Card
        sx={{
          display: "flex",

          textAlign: "justify",
          m: { xs: 1, md: 3 },
          mt: { xs: 0, md: 1 },
        }}
        variant="outlined"
      >
        <Grid xs={12} lg={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              p: 3,
              justifyContent: "space-around",
            }}
          >
            <Typography
              sx={{
                fontWeight: "500 !important",
                fontSize: "1rem !important",
                fontFamily: "Roboto",
              }}
            >
              Generate Dos Band Image
            </Typography>
            <Button
              color="success"
              variant="contained"
              onClick={(e) => sendDBIPlot(e)}
              sc={{
                mx: "3vw !important",
              }}
            >
              generate
            </Button>
          </Box>
        </Grid>
      </Card>
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          textAlign: "justify",
          m: { xs: 1, md: 3 },
          mt: { xs: 0, md: 1 },
          justifyContent: "space-around",
        }}
      >
        {DBIPlotIsLoading ? (
          <Loading
            style={{ margin: "1rem" }}
            type={"spinningBubbles"}
            color="#4A5073"
          />
        ) : DBIPlotLoaded ? (
          <>
            <Box
              component="img"
              alt="dos band image"
              src={`data:image/gif;base64,${DBIPlotImage}`}
            />
          </>
        ) : null}
      </Card>
    </>
  );
}

export default DosBandImage;
