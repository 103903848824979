import { Box, Button, Card, Grid, Typography } from "@mui/material";
function ParetoDes() {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        textAlign: "justify",
        m: { xs: 1, md: 3 },
        mt: 5,
      }}
      variant="outlined"
    >
      <Grid xs={12} lg={6}>
        <Box
          sx={{
            p: 3,
          }}
        >
          <Typography
            variant="h6"
            component="h6"
            color="textPrimary"
            gutterBottom
          >
            Description
          </Typography>
          <Typography variant="body1" component="body1" color="#7A7A7A">
            This function ranks selected structures based on two chosen
            properties. When optimization involves multiple properties, it often
            results in not just one best solution but a set of top solutions.
            These top solutions, known as non-dominated solutions, collectively
            form what is known as the Pareto front.
          </Typography>
        </Box>
      </Grid>
      <Grid xs={12} lg={6}>
        <Box
          sx={{
            p: 3,
          }}
        >
          <Typography variant="h6" component="h6" gutterBottom>
            Citation
          </Typography>
          <Typography variant="body1" component="body1" color="#7A7A7A">
            If you utilize this function in your work, we kindly request that
            you cite the corresponding reference paper. Proper attribution helps
            acknowledge the authors’ efforts and ensures the integrity of the
            research community. Thank you for your cooperation.
          </Typography>
          <Typography variant="h6" component="h6" sx={{ mt: 3 }} gutterBottom>
            References
          </Typography>
          <Button
            sx={{
              textDecoration: "none",
              textTransform: "none",
              color: "#7A7A7A !important",
              ":hover": {
                textDecoration: "underline",
                color: "red !important",
              },
            }}
            target="_blank"
            href={
              "https://link.springer.com/referenceworkentry/10.1007/978-3-319-44680-6_71"
            }
          >
            - Multi-objective Optimization as a Tool for Material Design.
          </Button>
        </Box>
      </Grid>
    </Card>
  );
}

export default ParetoDes;
