import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          margin: 0,
          padding: 0,
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100vh",
            minWidth: "350px",
            maxWidth: "724px",
            padding: "20px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: "190px",
                fontWeight: "bold",
                color: "#0a1930",
              }}
            >
              404
            </Typography>
          </div>
          <div>
            <Typography
              variant="h2"
              sx={{ fontWeight: "bold", color: "#0a1930", mb: 2 }}
            >
              Page Not Found
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "gray",
                fontSize: "18px",
                lineHeight: "24px",
                fontWeight: "normal",
                marginBottom: "20px",
                textAlign: "justify",
                marginRight: "15px",
              }}
            >
              The page you are looking for does not exist. Please check the URL
              for possible errors and try again.
            </Typography>

            <Link to="/">
              <Button
                variant="contained"
                style={{
                  margin: 0,
                  padding: "8px 20px",
                  width: "100%",
                  textTransform: "none",
                  fontSize: "15px",
                  borderRadius: "6px",
                  color: "white",
                  backgroundColor: "#0a1930",
                  transition: "color 0.3s ease",
                  "&:hover": {
                    backgroundColor: "#0a1930",
                    color: "#ffa500 !important",
                  },
                }}
              >
                Return to DAICS...
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
