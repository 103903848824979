import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const URL_CHANGE_PASSWORD = process.env.REACT_APP_URL_CHANGE_PASSWORD;

const commonInputProps = {
  InputProps: {
    sx: {
      py: 1.5,
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      borderRadius: "6px",
      marginBottom: 2,
    },
  },
};

const ChangePassword = () => {
  const axiosPrivate = useAxiosPrivate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChangePassword = async (currentPassword, newPassword) => {
    try {
      const response = await axiosPrivate.post(URL_CHANGE_PASSWORD, {
        old_password: currentPassword,
        new_password1: newPassword,
        new_password2: newPassword,
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (newPassword !== confirmPassword) {
        setError("New password and confirm password don't match");
        return;
      }

      await handleChangePassword(currentPassword, newPassword);
      setSuccessMessage("Password changed successfully");

      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setError("");
    } catch (error) {
      setSuccessMessage("");
      if (
        error.response &&
        error.response.data &&
        error.response.data.new_password2
      ) {
        setError(error.response.data.new_password2[0]);
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.old_password
      ) {
        setError(error.response.data.old_password[0]);
      } else {
        setError("Unable to change password. Please try again.");
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "600px",
          minWidth: "400px",
          margin: "40px auto",
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "30px",
          backgroundColor: "rgba(255, 255, 255, 0.94)",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        {" "}
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            mt: 1,
            mb: 2,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography variant="h6" sx={{ mb: "10px" }}>
            Change Password
          </Typography>
          <TextField
            {...commonInputProps}
            type="password"
            id="current-password"
            label="Current Password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
          />

          <TextField
            {...commonInputProps}
            type="password"
            id="new-password"
            label="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />

          <TextField
            {...commonInputProps}
            type="password"
            id="confirm-password"
            label="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          {error && <div style={{ color: "red" }}>{error}</div>}
          {successMessage && (
            <div style={{ color: "green" }}>{successMessage}</div>
          )}
          <Button variant="contained" color="primary" type="submit">
            Change Password
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ChangePassword;
