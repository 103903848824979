import { Box, Button, Card, TextField, Typography } from "@mui/material";
import { useState } from "react";
import BarChart from "../../components/Charts/BarChart.js";
import Loading from "../../components/UI/Loading";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const URL_XRD_PLOT_DATA = process.env.REACT_APP_URL_XRD_PLOT_DATA;

const XRDPlot = ({ matId }) => {
  const [startDegree, setStartDegree] = useState("");
  const [endDegree, setEndDegree] = useState("");
  const [wavelength, setWavelength] = useState("");
  const [XRDPlotData, setXRDPlotData] = useState(null);
  const [isXRDPlotLoading, setXRDPlotIsLoading] = useState(false);
  const [XRDPlotLoaded, setXRDPlotLoaded] = useState(false);
  const [error, setError] = useState(null);
  const axiosPrivate = useAxiosPrivate();

  async function getXRDPlot(params) {
    const response = await axiosPrivate.get(URL_XRD_PLOT_DATA, {
      params: params,
    });
    return response;
  }
  const x_label = "2θ (degree)";
  const y_label = "Intensity (a.u.)";

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const sendXRDPlot = async () => {
    setXRDPlotIsLoading(true);

    try {
      const response = await getXRDPlot({
        mat_id: matId,
        start_deg: startDegree,
        end_deg: endDegree,
        wavelength: wavelength,
      });

      if (response.status === 200) {
        setXRDPlotData(response.data.result);
        setXRDPlotLoaded(true);
      }
    } catch (error) {
      setError(error.response);
    } finally {
      setXRDPlotIsLoading(false);
    }
  };

  const XRDPlotSize = window.matchMedia("screen and (max-width: 768px)").matches
    ? ["60vw", "90vw"]
    : ["30vw", "90vw"];
  const Input = ({ name }) => (
    <Typography
      sx={{
        fontWeight: "500 !important",
        fontSize: "0.8rem !important",
        whiteSpace: "pre-line",
        fontFamily: "Roboto",
      }}
    >
      {name}
    </Typography>
  );
  return (
    <>
      <Card
        sx={{
          display: "flex",
          mt: { xs: 0, md: 1 },
          justifyContent: "center",
          flexDirection: "column",
        }}
        variant="outlined"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            my: 3,
          }}
        >
          <Box
            sx={{
              ml: 2,
            }}
          >
            <Input name="Start Degree" />
            <TextField
              sx={{
                minWidth: 80,
                width: "min-content",
              }}
              value={startDegree}
              onChange={(e) => setStartDegree(e.target.value)}
              id="outlined-number"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box
            sx={{
              ml: 2,
            }}
          >
            <Input name="End Degree" />
            <TextField
              sx={{
                minWidth: 80,
                width: "min-content",
              }}
              value={endDegree}
              onChange={(e) => setEndDegree(e.target.value)}
              id="outlined-number"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box
            sx={{
              ml: 2,
            }}
          >
            <Input name="Wavelength, Å" />
            <TextField
              sx={{
                minWidth: 80,
                width: "min-content",
              }}
              value={wavelength}
              onChange={(e) => setWavelength(e.target.value)}
              id="outlined-number"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              ml: 2,
              justifyContent: { xs: "start", md: "end" },
              my: { xs: 1, md: 0 },
            }}
          >
            <Button
              color="success"
              variant="contained"
              size="small"
              onClick={sendXRDPlot}
            >
              {isXRDPlotLoading ? "Loading..." : "Generate"}
            </Button>
          </Box>
        </Box>
      </Card>

      <Card
        variant="outlined"
        sx={{
          display: "flex",
          textAlign: "justify",
          mt: { xs: 0, md: 1 },
          justifyContent: "space-around",
        }}
      >
        {isXRDPlotLoading ? (
          <Loading style={{ m: 4 }} type={"spinningBubbles"} color="#4A5073" />
        ) : XRDPlotLoaded ? (
          <BarChart
            data={XRDPlotData}
            x_label={x_label}
            y_label={y_label}
            height={XRDPlotSize[0]}
            width={XRDPlotSize[1]}
          />
        ) : null}
      </Card>
    </>
  );
};

export default XRDPlot;
