import { Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { TreeItem } from "@mui/x-tree-view";
function SpaceGroup(props) {
  const {
    handleOpen,
    nodeId,
    label,
    valuesSN,
    valuesSS,
    valuesSGS,
    handleSNChange,
    handleSSChange,
    MenuProps,
  } = props;

  return (
    <TreeItem
      sx={{
        backgroundColor: "#61acc5",
        margin: "1vw auto",
        border: "1px solid  #30435f",
        borderRadius: "5px",
        "& .MuiTreeItem-iconContainer svg": {
          fontSize: "26px",
          color: "#EAE7DC !important",
        },
      }}
      nodeId={nodeId}
      label={<Typography>{label}</Typography>}
      onClick={() => handleOpen(nodeId)}
    >
      <FormControl sx={{ m: 1, width: 170 }} size="small">
        <InputLabel id="demo-multiple-checkbox-label">
          Spacegroup Number
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={valuesSN}
          onChange={handleSNChange}
          input={<OutlinedInput label="Spacegroup Number" />}
          renderValue={(selected) => selected.length}
          MenuProps={MenuProps}
          defaultValue="asd"
        >
          {valuesSGS.map((name) => (
            <MenuItem key={name} value={valuesSGS.indexOf(name) + 1}>
              <Checkbox
                checked={valuesSN.indexOf(valuesSGS.indexOf(name) + 1) > -1}
              />
              <ListItemText primary={valuesSGS.indexOf(name) + 1} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Divider sx={{ mb: 2 }} />
      <FormControl sx={{ m: 1, width: 170 }} size="small">
        <InputLabel id="demo-multiple-checkbox-label">
          Spacegroup Symbol
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={valuesSS}
          onChange={handleSSChange}
          input={<OutlinedInput label="Spacegroup Symbol" />}
          renderValue={(selected) => selected.length}
          MenuProps={MenuProps}
          defaultValue="asd"
        >
          {valuesSGS.map((name) => (
            <MenuItem key={name} value={valuesSGS.indexOf(name) + 1}>
              <Checkbox
                checked={valuesSN.indexOf(valuesSGS.indexOf(name) + 1) > -1}
              />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </TreeItem>
  );
}

export default SpaceGroup;
