import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Grid from "@mui/material/Grid";
import { TreeItem, TreeView } from "@mui/x-tree-view";

const ResultsGrid = ({ nodeId, label, data, children }) => {
  const hasData = Object.values(data).length > 0;
  return (
    <>
      {hasData ? (
        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
          <div className="results--table">
            <TreeView
              defaultCollapseIcon={<KeyboardDoubleArrowDownIcon />}
              defaultExpandIcon={<KeyboardDoubleArrowRightIcon />}
            >
              <TreeItem
                className="results-tree--item"
                nodeId={nodeId}
                label={label}
                disabled={data.filter((item) => item.value).length === 0}
              >
                {children}
              </TreeItem>
            </TreeView>
          </div>
        </Grid>
      ) : null}
    </>
  );
};

export default ResultsGrid;
