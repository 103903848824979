import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import defAvatar from "../../assets/img/Users/defaultUserPhoto.jpg";
import useGetUserInfo from "../../hooks/useGetUserInfo";
import "./style.css";

const Avatar = ({ username }) => {
  const [avatars, setAvatars] = useState(defAvatar);
  const [user, setUser] = useState(localStorage.getItem("daicsUser"));

  useEffect(() => {
    const storedAvatar = localStorage.getItem("userAvatar") || defAvatar;
    setAvatars(storedAvatar);
    username && setUser(username);
  }, [username]);

  return (
    <Box
      style={{
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <img
        src={avatars}
        alt="Avatar"
        style={{
          margin: "10px auto",
          width: 150,
          height: 150,
          borderRadius: "40%",
        }}
      />
      <h3 style={{ marginBottom: "10px" }}>{user}</h3>
    </Box>
  );
};

const DashboardContent = () => {
  const user = useGetUserInfo();
  const [userData, setUserData] = useState(user);

  useEffect(() => {
    setUserData(user);
  }, [user]);

  const getColorByRole = (role) => {
    switch (role) {
      case "Premium":
        return "green";
      case "Standard":
        return "blue";
      case "Basic":
        return "orange";
      default:
        return "black";
    }
  };

  return (
    <>
      <Box className="containerStyle">
        <Box sx={{ minWidth: "160px" }}>
          <Avatar username={userData.username} />
        </Box>
        <Box
          sx={{
            width: "100%",
            margin: "0 15px",
          }}
        >
          {userData.firstName ? (
            <Typography variant="h4" className="names">
              {userData.prefix} {userData.firstName} {userData.lastName}
            </Typography>
          ) : (
            <Typography variant="h4" className="names">
              Please update your name
            </Typography>
          )}

          <Box textAlign={{ xs: "left", md: "right" }} marginTop={"20px"}>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              DAICS ID: {userData.daics_id}
            </Typography>
            {userData.roles && (
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Subscription:{" "}
                <span style={{ color: getColorByRole(userData.highestRole) }}>
                  {userData.highestRole}
                </span>
              </Typography>
            )}
            {userData.joinDated && (
              <Typography variant="body2">
                Join Date: {userData.joinDated}
              </Typography>
            )}
            {userData.lastLogin && (
              <Typography variant="body2">
                Last Login: {userData.lastLogin}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      {}
      <Box className="containerStyle">
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "left",
            margin: "15px",
            gap: "10px",
          }}
        >
          {userData.lastName ? (
            <Typography variant="h6" className="names">
              Dear {userData.prefix} {userData.lastName},
            </Typography>
          ) : (
            <Typography variant="h6" className="names">
              Dear {userData.username},
            </Typography>
          )}
          <Typography
            variant="body1"
            sx={{ textAlign: { xs: "left", sm: "justify" } }}
          >
            To enhance your experience with the Database of Ab Initio Crystal
            Structures (DAICS), we encourage you to update your profile and
            verify your institutional email. This step is particularly
            beneficial for obtaining access to the DAICS's data in a
            machine-readable format, if your organization opts for a Premium
            subscription for its members.
            <br />
            <br />
            Kindly note that you can update your profile by navigating to the
            "My Details" tab. In the "Settings" section, you can add your
            institutional email, and the API key is accessible exclusively to
            Premium members in the "API key" section.
            <br />
            <br />
            Best regards,
            <br />
            DAICS Team
          </Typography>

          {userData.subscribe ? (
            <Typography
              variant="body2"
              sx={{ textAlign: "right", fontWeight: "bold" }}
            >
              <span style={{ color: "green", marginRight: "5px" }}>●</span>
              You are subscribed to DAICS newsletters
            </Typography>
          ) : (
            <Typography
              variant="body2"
              sx={{ textAlign: "right", fontWeight: "bold" }}
            >
              <span style={{ color: "red", marginRight: "5px" }}>●</span>
              You are NOT subscribed to DAICS newsletters
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default DashboardContent;
