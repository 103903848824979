import "cropperjs/dist/cropper.css";
import { useEffect, useState } from "react";
import Cropper from "react-cropper";
import "./Avatar.css";

const Avatar = () => {
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [randomAvatar, setRandomAvatar] = useState(null);
  const [avatars, setAvatars] = useState([]);
  const [avatarsLoaded, setAvatarsLoaded] = useState(false);
  const [tempAvatar, setTempAvatar] = useState(null);
  const [saveButtonActive, setSaveButtonActive] = useState(false);

  const [isCropping, setIsCropping] = useState(false);
  const [cropper, setCropper] = useState(null);
  const [cropButtonActive, setCropButtonActive] = useState(false);

  const importAvatars = async () => {
    const importedAvatars = [];
    for (let i = 0; i <= 50; i++) {
      try {
        const avatar = await import(
          `../../../assets/img/Users/AbstractAvatar/av_${i
            .toString()
            .padStart(2, "0")}.jpg`
        );
        importedAvatars.push(avatar.default);
      } catch (error) {
        console.error("Error loading avatar:", error);
      }
    }
    setAvatars(importedAvatars);
    setAvatarsLoaded(true);
  };

  useEffect(() => {
    importAvatars();
    const storedAvatar = localStorage.getItem("userAvatar");

    if (storedAvatar) {
      setSelectedAvatar(storedAvatar);
      setTempAvatar(storedAvatar);
    }
  }, []);

  useEffect(() => {
    if (avatarsLoaded && avatars.length > 0 && !selectedAvatar) {
      const randNumber = Math.floor(Math.random() * avatars.length);
      setRandomAvatar(avatars[randNumber]);
      setTempAvatar(avatars[randNumber]);
      setSaveButtonActive(true);
    }
  }, [avatars, avatarsLoaded, selectedAvatar]);

  const handleButtonClick = () => {
    if (avatars.length > 0) {
      const randNumber = Math.floor(Math.random() * avatars.length);
      setRandomAvatar(avatars[randNumber]);
      setTempAvatar(avatars[randNumber]);
      setSaveButtonActive(true);
      setIsCropping(false);
      setCropButtonActive(false);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const maxSize = 1 * 1024 * 1024;

    if (file.size > maxSize) {
      alert("Please select a file smaller than 1MB.");
      return;
    }
    const reader = new FileReader();

    reader.onloadend = () => {
      setTempAvatar(reader.result);
      setSaveButtonActive(true);
      setIsCropping(true);
      setCropButtonActive(true);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleCrop = () => {
    if (cropper) {
      setTempAvatar(cropper.getCroppedCanvas().toDataURL());
      setIsCropping(false);
      setCropButtonActive(false);
    }
  };

  const convertImageToBase64 = (tempAvatar) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = tempAvatar;

      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;

        const context = canvas.getContext("2d");
        context.drawImage(image, 0, 0);

        const base64String = canvas.toDataURL("image/png");
        resolve(base64String);
      };

      image.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSave = async () => {
    if (tempAvatar) {
      try {
        const base64Image = await convertImageToBase64(tempAvatar);
        setSelectedAvatar(tempAvatar);
        localStorage.setItem("userAvatar", tempAvatar);
        localStorage.setItem("userAvatarBase64", base64Image);
        localStorage.setItem("avatarUpdate", true);
        setSaveButtonActive(false);
      } catch (error) {
        console.error("Error converting image to base64:", error);
      }
    }
  };

  return (
    <div className="avatar-container">
      <h2>Select Cool Avatar</h2>
      <input type="file" accept="image/*" onChange={handleFileUpload} />
      <br />
      {isCropping && tempAvatar && (
        <Cropper
          style={{ height: 300, width: "100%", maxWidth: "800px" }}
          zoomTo={0.5}
          aspectRatio={1}
          src={tempAvatar}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides={true}
        />
      )}
      <img
        src={tempAvatar || randomAvatar}
        alt="Avatar"
        style={{
          marginTop: "7px",
          width: 150,
          height: 150,
          borderRadius: "50%",
        }}
      />
      <div className="avatar-buttons">
        <button
          className="specificbutton"
          onClick={handleCrop}
          disabled={!cropButtonActive}
        >
          Crop Image
        </button>

        <button className="specificbutton" onClick={handleButtonClick}>
          Get Random Avatar
        </button>
        <button
          className="specificbutton"
          onClick={handleSave}
          disabled={!saveButtonActive}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Avatar;
