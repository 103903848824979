import { Box, Button, Container, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const setItemWithExpiration = (key, value, expirationDays) => {
  const expirationMs = expirationDays * 24 * 60 * 60 * 1000;
  const expirationTime = new Date().getTime() + expirationMs;
  const item = { value, expirationTime };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getItemWithExpiration = (key) => {
  const storedItem = localStorage.getItem(key);

  if (!storedItem) {
    return null;
  }

  const parsedItem = JSON.parse(storedItem);

  if (new Date().getTime() > parsedItem.expirationTime) {
    localStorage.removeItem(key);
    return null;
  }

  return parsedItem.value;
};

const CookiesConsent = () => {
  const location = useLocation();
  const [consentGiven, setConsentGiven] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [inLogin, setInLogin] = useState(false);

  const bannerStyle = {
    opacity: showBanner ? 1 : 0,
    transition: "opacity 0.5s ease-in-out",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    position: "fixed",
    top: 0,
    background: "rgba(255, 255, 255, 0.9)",
    py: 1,
    textAlign: "justify",
    zIndex: 10002,
  };

  const handleConsent = () => {
    setItemWithExpiration("consent", true, 180);
    setConsentGiven(true);

    inLogin && window.location.reload();
  };

  const handleDecline = () => {
    setShowBanner(false);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const consentFromStorage = getItemWithExpiration("consent");
      if (location.pathname === "/login") {
        setInLogin(true);
      }
      if (location.pathname === "/register") {
        setInLogin(true);
      }
      if (!consentFromStorage) {
        setShowBanner(true);
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [location]);

  if (!showBanner || consentGiven) {
    return null;
  }

  return (
    <>
      {inLogin ? (
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            backgroundColor: "#0a1930",
            position: "fixed",
            zIndex: 10003,
            top: "50%",
            left: "50%",
            minHeight: "200px",
            transform: "translate(-50%, -50%)",
            textAlign: "left",
            borderRadius: 5,
            px: 2,
            py: 1,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "8px 0",
              fontWeight: "bold",
              color: "white",
            }}
          >
            <Box>
              {" "}
              <span role="img" aria-label="cookies">
                🍪
              </span>{" "}
              Cookies Notice: We use essential cookies for site functionality
              and security. These are necessary for the proper functioning of
              the site. For more details, review our{" "}
              <Link
                color="inherit"
                href="/cookie-policy"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  color: "#fff !important",
                  fontWeight: "bold",
                  fontSize: 15,
                  transition: "color 0.3s",
                  "&:hover": { color: "#f50057" },
                }}
              >
                Cookie Policy.
              </Link>
            </Box>

            <Box sx={{ alignSelf: "flex-end" }}>
              <Button
                onClick={handleConsent}
                variant="contained"
                sx={{
                  padding: "4px 10px",
                  mt: "15px",
                  mr: "5px",
                  backgroundColor: "#49A580",
                  ":hover": { backgroundColor: "#409171" },
                }}
              >
                Accept
              </Button>
            </Box>
          </Typography>
        </Container>
      ) : (
        <Box sx={bannerStyle}>
          <Typography
            variant="body2"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "8px 0",
              fontWeight: "bold",
              width: { xs: "96%", sm: "85%", md: "75%", lg: "70%", xl: "60%" },
            }}
          >
            <Box>
              {" "}
              <span role="img" aria-label="cookies">
                🍪
              </span>{" "}
              {}
              {}
              Cookies Notice: We use essential cookies for site functionality
              and security. These are necessary for the proper functioning of
              the site. For more details, review our{" "}
              <Link
                color="inherit"
                href="/cookie-policy"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  color: "#000000 !important",
                  fontWeight: "bold",
                  fontSize: 15,
                  transition: "color 0.3s",
                  "&:hover": { color: "#f50057" },
                }}
              >
                Cookie Policy.
              </Link>
            </Box>

            <Box sx={{ alignSelf: "flex-end" }}>
              <Button
                onClick={handleConsent}
                variant="contained"
                sx={{
                  padding: "4px 10px",
                  mt: "4px",
                  mr: "5px",
                  backgroundColor: "#49A580",
                  ":hover": { backgroundColor: "#409171" },
                }}
              >
                Only essential
              </Button>
              <Button
                onClick={handleDecline}
                variant="contained"
                sx={{
                  padding: "4px 10px",
                  mt: "4px",
                  ml: "5px",
                  backgroundColor: "#f50057",
                  ":hover": { backgroundColor: "#D32B4A" },
                }}
              >
                Notify me later
              </Button>
            </Box>
          </Typography>
        </Box>
      )}
    </>
  );
};

export default CookiesConsent;
