import { Box, Typography, Link } from "@mui/material";

const LightFooter = ({ positioning }) => {
  const footerStyle = {
    width: "100%",
    position: positioning,
    bottom: 0,
    background:
      "linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 10%, rgba(255, 255, 255, 0.9) 80%)",
    py: 1,
    textAlign: "center",
    height: 40,
    zIndex: 1,
  };

  return (
    <Box sx={footerStyle}>
      <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: 15 }}>
        <Link
          color="inherit"
          href="/"
          sx={{
            color: "#000000 !important",
            fontWeight: "bold",
            fontSize: 15,
            transition: "color 0.3s",
            "&:hover": { color: "#f50057" },
          }}
        >
          DAICS
        </Link>
        {" © "}
        {new Date().getFullYear()}
      </Typography>
    </Box>
  );
};

export default LightFooter;
