import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Crystal from "../../components/Results/Crystal";
import DosBandImage from "../../components/Results/DosBandImage";
import ResultsCard from "../../components/Results/ResultsCard";
import ResultsGrid from "../../components/Results/ResultsGrid";
import VASPCalc from "../../components/Results/VASPCalc";
import XRDPlot from "../../components/Results/XRDPlot";
import ReportStructure from "../../components/Utils/ReportStructure";
import { Footer, Header, Loading } from "../../components/index.js";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "./Results.css";
import {
  decryptData,
  formatDielectricProperties,
  formatDosAndBandStructurePhoto,
  formatElasticProperties,
  formatElectronicProperties,
  formatEnergeticProperties,
  formatGeneralProperties,
  formatGeneralizedConvexHullProperties,
  formatGeometricProperties,
  formatMLProperties,
  formatMagneticProperties,
  formatOtherProperties,
  reduceChemicalFormula,
} from "./utils";

function Results(props) {
  const { name } = useParams();

  const [results, setResults] = useState({});
  const [loaded, setLoaded] = useState(false);

  const [Error, setError] = useState(false);
  const [gen, setGen] = useState([]);
  const [geo, setGeo] = useState([]);
  const [dosbandPic, setDosBandPic] = useState([]);
  const [thp, setThp] = useState([]);
  const [mep, setMep] = useState([]);
  const [dielp, setDielp] = useState([]);
  const [elthp, setElthp] = useState([]);
  const [mlp, setMlp] = useState([]);
  const [magp, setMagp] = useState([]);
  const [gch, setGch] = useState([]);
  const [otherp, setOtherp] = useState([]);
  const [input, setInput] = useState([]);
  const [scene, setScene] = useState({});
  const [atoms, setAtoms] = useState(true);
  const [bonds, setBonds] = useState(true);
  const [cell, setCell] = useState(true);
  const [polyhedra, setPolyhedra] = useState(true);
  const [axes, setAxes] = useState(true);
  const [inletSize, setInletSize] = useState({});
  const [structure, setStructure] = useState("optimized");

  const URL_GENERAL_PROPERTIES = process.env.REACT_APP_URL_GENERAL_PROPERTIES;
  const URL_EXPORT_CIF = process.env.REACT_APP_URL_EXPORT_CIF;
  const URL_EXPORT_POSCAR = process.env.REACT_APP_URL_EXPORT_POSCAR;

  const axiosPrivate = useAxiosPrivate();

  async function generalProperties(id) {
    try {
      const response = await axiosPrivate.get(URL_GENERAL_PROPERTIES + id);
      const RES = decryptData(response.data);
      console.log("DATATATTADATA", RES);
      console.log("RES.KEY", RES[0].key);
      return decryptData(response.data);
    } catch (error) {
      console.error("Error fetching or decrypting data:", error);
      throw error;
    }
  }

  async function get_cif(query) {
    const response = await axiosPrivate.get(URL_EXPORT_CIF + query);
    return response;
  }
  async function get_poscar(query) {
    const response = await axiosPrivate.get(URL_EXPORT_POSCAR + query);
    return response;
  }
  useEffect(() => {
    generalProperties(name)
      .then((r) => {
        setResults(r[0]);
        setGen(formatGeneralProperties(r[0]));
        setGeo(formatGeometricProperties(r[0]));
        setThp(formatEnergeticProperties(r[0]));
        setMep(formatElasticProperties(r[0]));
        setMagp(formatMagneticProperties(r[0]));
        setGch(formatGeneralizedConvexHullProperties(r[0]));
        setOtherp(formatOtherProperties(r[0]));
        setDielp(formatDielectricProperties(r[0]));
        setElthp(formatElectronicProperties(r[0]));
        setDosBandPic(formatDosAndBandStructurePhoto(r[0]));
        setMlp(formatMLProperties(r[0]));
        setInput(r[0].calculation_setup || null);
        setScene(r[0].crystal_field);
        setLoaded(true);
        setError(false);
      })
      .catch((e) => {
        console.error("Error:", e);
        setError(true);
      });
  }, [name, Error]);
  const handleReset = () => {
    setInletSize({
      position: { x: 0.01, y: 0.01, z: 0.01 },
      quaternion: { x: 0.01, y: 0.01, z: 0.01, w: 0.01 },
      zoom: 4,
      setByComponentId: "mtp--canvas",
      following: true,
    });
  };
  const handleAtoms = (e) => {
    setAtoms(!atoms);
  };
  const handleBonds = (e) => {
    setBonds(!bonds);
  };
  const handleCell = (e) => {
    setCell(!cell);
  };
  const handlePoleyhedra = (e) => {
    setPolyhedra(!polyhedra);
  };
  const handleAxes = (e) => {
    setAxes(!axes);
  };
  const handleStructure = (e) => {
    setStructure(e.target.value);
  };
  const handleCIFDownload = (e) => {
    const element = document.createElement("a");

    let data = "mat_id=" + results.mat_id + "&option=" + structure;
    get_cif(data).then((r) => {
      const file = new Blob([r.data], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = `${results.mat_id}.cif`;
      document.body.appendChild(element);
      element.click();
    });
  };
  const handlePoscarDownload = (e) => {
    const element = document.createElement("a");

    let data = "mat_id=" + results.mat_id + "&option=" + structure;

    get_poscar(data).then((r) => {
      const file = new Blob([r.data], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = `${results.mat_id}.vasp`;
      document.body.appendChild(element);
      element.click();
    });
  };
  let navigate = useNavigate();
  const handleNavigate = (e) => {
    navigate("/");
  };
  const ResultsCardData = {
    "General information table": gen,
    "Geometry Information table": geo,
  };

  const ResultsGridData = {
    "Thermodynamic properties": thp,
    "Mechanical Properties": mep,
    "Dielectric Properties": dielp,
    "Electronic and Thermoelectric Properties": elthp,
    "ML Calculated Properties": mlp,
    "Magnetic Properties ": magp,
    "Generalized convex hull": gch,
    "Miscellaneous properties": otherp,
  };
  return (
    <Box
      sx={{
        minHeight: "100%",
        top: "0",
      }}
    >
      <Header />
      {loaded ? (
        <Box>
          <Card>
            <CardHeader title="" subheader="" />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1vw",
                mt: "1vw",
                mb: "2vw",
                mx: "2vw",
              }}
            >
              <Box>
                <Typography variant="h5">
                  {" "}
                  Materials ID: {results.mat_id}
                </Typography>
                <Typography variant="h5">
                  {" "}
                  Formula: {reduceChemicalFormula(results.formula)}
                </Typography>
              </Box>
            </Box>
          </Card>

          <Box>
            <Grid container sx={{ p: 1 }}>
              <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="result--card">
                  <Card variant="outlined">
                    <CardHeader
                      title="Crystal Toolkit Scene"
                      subheader=""
                      titleTypographyProps={{
                        variant: "h5",
                        color: "white",
                      }}
                      sx={{
                        textAlign: "center",
                        backgroundColor: "#247BA0",
                      }}
                    />
                    {}
                    <div className="reset--button">
                      <Button onClick={(e) => handleReset()}>
                        <RestartAltIcon fontSize="large" />
                      </Button>
                    </div>
                    <Box>
                      <Crystal
                        scene={scene}
                        atoms={atoms}
                        bonds={bonds}
                        cell={cell}
                        polyhedra={polyhedra}
                        axes={axes}
                        inletSize={inletSize}
                      />
                    </Box>
                    <Box
                      sx={{
                        margin: "auto 2vw  !important",
                        justifyContent: "center !important",
                        mt: "1vw !important",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            onClick={(e) => handleAtoms(e)}
                          />
                        }
                        label="Atoms"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            onClick={(e) => handleBonds(e)}
                          />
                        }
                        label="Bonds"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            onClick={(e) => handleCell(e)}
                          />
                        }
                        label="Unit Cell"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            onClick={(e) => handlePoleyhedra(e)}
                          />
                        }
                        label="Polyhedra"
                      />
                    </Box>
                    <Divider />
                    {}
                    <Box noValidate sx={{ mt: 3, ml: 5, mb: 1 }}>
                      <FormLabel id="dwonload">Download</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="label"
                        name="row-radio-buttons-group"
                        value={structure}
                        onChange={handleStructure}
                      >
                        <FormControlLabel
                          value="optimized"
                          default
                          control={<Radio />}
                          sx={{ fontSize: 1, color: "inherit" }}
                          label="Optimized structure"
                        />
                        <FormControlLabel
                          value="primitive"
                          control={<Radio />}
                          label="Primitive structure"
                        />
                        <FormControlLabel
                          value="symmetrized"
                          control={<Radio />}
                          label="Symmetrized structure"
                        />
                        <FormControlLabel
                          value="conventional"
                          control={<Radio />}
                          label="Conventional Structure"
                        />
                      </RadioGroup>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        mb: 2,
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        onClick={(e) => handleCIFDownload()}
                        style={{
                          backgroundColor: "#247BA0",
                          minWidth: "100px",
                        }}
                      >
                        CIF
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        onClick={(e) => handlePoscarDownload()}
                        style={{
                          backgroundColor: "#247BA0",
                          minWidth: "100px",
                        }}
                      >
                        POSCAR
                      </Button>
                    </Box>
                  </Card>
                </div>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="result--card">
                  <ResultsCard
                    nodeId="1"
                    title={"General information table"}
                    data={gen}
                  />
                </div>
                <div className="result--card">
                  <ResultsCard
                    nodeId="2"
                    title={"Geometry Information table"}
                    data={geo}
                  />
                </div>
              </Grid>
              <ResultsGrid
                nodeId="3"
                label="XRD Plot"
                data={[{ value: 1 }]}
                children={<XRDPlot matId={results.mat_id} />}
              />
              <ResultsGrid
                nodeId="4"
                label="Dos Band Image"
                data={dosbandPic}
                children={<DosBandImage matId={results.mat_id} />}
              />
              <ResultsGrid
                nodeId="5"
                label={"Thermodynamic properties"}
                data={thp}
                children={
                  <ResultsCard title={"Thermodynamic properties"} data={thp} />
                }
              />
              <ResultsGrid
                nodeId="6"
                label={"Mechanical Properties"}
                data={mep}
                children={
                  <ResultsCard title={"Mechanical Properties"} data={mep} />
                }
              />
              <ResultsGrid
                nodeId="7"
                label={"Dielectric Properties"}
                data={dielp}
                children={
                  <ResultsCard title={"Dielectric Properties"} data={dielp} />
                }
              />
              <ResultsGrid
                nodeId="8"
                label={"Electronic and Thermoelectric Properties"}
                data={elthp}
                children={
                  <ResultsCard
                    title={"Electronic and Thermoelectric Properties"}
                    data={elthp}
                  />
                }
              />
              <ResultsGrid
                nodeId="9"
                label={"ML Calculated Properties"}
                data={mlp}
                children={
                  <ResultsCard title={"ML Calculated Properties"} data={mlp} />
                }
              />
              <ResultsGrid
                nodeId="10"
                label={"Magnetic Properties "}
                data={magp}
                children={
                  <ResultsCard title={"Magnetic Properties "} data={magp} />
                }
              />
              <ResultsGrid
                nodeId="11"
                label={"Generalized convex hull"}
                data={gch}
                children={
                  <ResultsCard title={"Generalized convex hull"} data={gch} />
                }
              />
              <ResultsGrid
                nodeId="12"
                label={"Miscellaneous properties"}
                data={otherp}
                children={
                  <ResultsCard
                    title={"Miscellaneous properties"}
                    data={otherp}
                  />
                }
              />
              <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="results--table">
                  <TreeView
                    defaultCollapseIcon={<KeyboardDoubleArrowDownIcon />}
                    defaultExpandIcon={<KeyboardDoubleArrowRightIcon />}
                  >
                    <TreeItem
                      key="VASP"
                      className="results-tree--item"
                      nodeId="13"
                      label="VASP calculation's setup"
                      disabled={input === null}
                    >
                      <VASPCalc input={input} />
                    </TreeItem>
                  </TreeView>
                </div>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                p: 1,
              }}
            >
              <ReportStructure
                id={results.key}
                reported={results.compound_is_reported}
              />
            </Box>
          </Box>
        </Box>
      ) : null}
      {!loaded && !Error ? (
        <div className="full-height">
          <Loading type={"spinningBubbles"} color="#4A5073" />
        </div>
      ) : null}
      {Error ? (
        <div className="full-height">
          <Grid container sx={{ p: 1, maxWidth: "1000px" }}>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography component="h1" variant="h5">
                We apologize, but the page you are trying to access does not
                exist. This could be due to the page being removed or if there
                was an error in typing the address.
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={(e) => handleNavigate()}
                  variant="contained"
                  sx={{
                    my: 2,
                    mt: 2,
                    display: "block",
                    marginTop: "1rem",
                    padding: "0.5rem 1rem",
                  }}
                >
                  Return to Homepage
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      ) : null}
      <Footer />
    </Box>
  );
}

export default Results;
