import Box from "@mui/material/Box";
import * as echarts from "echarts";
import { useEffect, useRef } from "react";

const ParetoRanking = ({ data, x_label, y_label }) => {
  const chartRef = useRef(null);
  useEffect(() => {
    const chart = echarts.init(chartRef.current);

    const scaterNormal = [];
    const scater_1 = [];
    const scater_tooltip = [];
    const sortedData = Object.keys(data)
      .sort((a, b) => data[a].X - data[b].X)
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});
    var callback = (args) => {
      if (args?.data[0]) {
        return (
          Object.entries(
            Object.entries(data).filter(
              ([key, value]) =>
                (value.X === args.data[0]) & (value.Y === args.data[1])
            )
          )[0][1][0] +
          "<br />" +
          "X: " +
          args.data[0] +
          "<br />" +
          "Y: " +
          args.data[1]
        );
      }
    };
    Object.entries(sortedData).forEach(([key, value]) => {
      scater_tooltip.push([key]);
      if (value.Pareto === 1) {
        scater_1.push([value.X, value.Y]);
      } else {
        scaterNormal.push([value.X, value.Y]);
      }
    });
    const lebelWithUnit = {
      hardness: "Hardness (GPa)",
      magnetization: "Magnetization (μ_B/Å^3)",
      enthalpy_of_formation: "Enthalpy of formation (eV/atom)",
      atomization_energy: "Atomization energy (eV/atom)",
      e_hull: "Energy above hull (eV/atom)",
      electrides: "Nonuclear charge density(e/A^3)",
      density: "Density (g/cm^3)",
      ml_bulk_modulus: "ML Bulk Modulus (GPa)",
      ml_shear_modulus: "ML Shear Modulus (GPa)",
      ml_young_modulus: "ML Young Modulus (GPa)",
      ml_pugh_modulus: "ML Pugh Modulus ",
      ml_possions_ratio: "ML Possions ratio",
      ml_vickers_hardness: "ML Vickers hardness (GPa)",
      ml_fracture_toughness: "ML Fracture toughness (MPa m^0.5)",
    };
    const option = {
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "cross",
        },
        formatter: callback,
      },
      toolbox: {
        show: true,
        feature: {
          show: true,
          dataZoom: {},
          saveAsImage: { name: "ParetoPlot", pixelRatio: 2 },
        },
      },
      xAxis: {
        type: "value",
        name: lebelWithUnit[x_label],
        nameGap: 35,
        nameLocation: "middle",
        nameTextStyle: {
          fontSize: 14,
        },
        axisLine: { onZero: false },
        splitLine: {
          show: true,
        },
      },
      yAxis: {
        type: "value",
        name: lebelWithUnit[y_label],
        nameGap: 45,
        nameRotate: 90,
        nameLocation: "middle",
        axisLine: { onZero: false },
        nameTextStyle: {
          fontSize: 14,
        },
        splitLine: {
          show: true,
        },
      },
      itemStyle: {
        borderColor: "#555",
      },
      series: [
        {
          name: "Hardness",
          type: "scatter",
          data: scaterNormal,
          color: "#000000",
        },
        {
          name: "Hardness",
          type: "scatter",
          symbol: "circle",
          symbolSize: 15,
          lineStyle: {
            width: 5,
          },
          itemStyle: {
            borderWidth: 2,
            borderColor: "#555",
            color: "#ff0000",
          },
          data: scater_1,
        },
        {
          name: "e_hull",
          type: "line",
          data: scater_1,
          smooth: true,
          symbol: "none",
        },
      ],
      grid: {
        left: 70,
        bottom: 70,
      },
    };

    chart.setOption(option);
    chart.on("click", function (params) {
      Object.entries(data).forEach(([key, value]) => {
        if ((value.X === params.data[0]) & (value.Y === params.data[1])) {
          window.open(`/compound/${value.key}`, "_blank");
        }
      });
    });
    return () => {
      chart.dispose();
    };
  }, [data]);
  let size;
  if (window.matchMedia("screen and (max-width: 768px)").matches) {
    size = ["90vw", "90vw"];
  } else {
    size = ["45vw", "45vw"];
  }
  return <Box ref={chartRef} style={{ width: size[0], height: size[1] }} />;
};

export default ParetoRanking;
