import { Grid } from "@mui/material";
import { Footer, Header } from "../../components/index.js";
import "./PolicyStyle.css";

const PrivacyPolicy = () => {
  return (
    <>
      <br />
      <Header />
      <Grid container sx={{ height: "100%" }}>
        <div className="policy-container">
          <h1>Privacy Policy</h1>
          <p>Last Updated: 2024-02-22 </p>
          <section>
            <h2>1. Who We Are</h2>
            <p>
              Our website address is:{" "}
              <a href="https://www.daics.net" style={{ fontSize: "20px" }}>
                https:
              </a>
              and more can be found in the “About Us” section. This Privacy
              Policy explains how we collect, use, and safeguard the information
              provided by users of our website.
            </p>
          </section>

          <section>
            <h2>2. Information We Collect</h2>
            <p>
              <b>2.1 User Registration:</b> To access the database, users must
              register, providing an email address and password or using Google
              Authentication. We collect IP addresses during the registration
              process.
              <br />
              <br />
              <b>2.2 User Profiles: </b> Users can voluntarily provide
              additional information, such as avatar, name, last name, second
              email (institution email), institute, country, city, zip code,
              phone, date of birth, and their website URL. Country, Name, Last
              name, and Institute are mandatory fields if user tries to fill the
              personal information. Users can see, edit, or delete their
              personal information at any time (except for the username that
              cannot be changed or removed, and the primary email address that
              cannot be removed). Website administrators can also see and edit
              user's information. We store the personal information that users
              provide in their user panel.
              <br />
              <br />
              <b>2.3 Contact Form:</b> When users or non-users contact us
              through the contact form, we collect their IP addresses.
            </p>
          </section>
          <section>
            <h2>3. How We Use Your Information</h2>
            <p>
              We use the collected information to enhance user experience, get
              analytics of the website, manage user accounts, and ensure
              security. User data, including profile information, is stored on
              our servers. We do not share any information with third parties
              unless obligated by law.
            </p>
          </section>

          <section>
            <h2>4. Authentication Tokens</h2>
            <p>
              We utilize JSON Web Token (JWT) authentication on our website.
              During the authentication process, we store the refresh token and
              CSRF token in users' cookies. These tokens are essential for the
              proper functioning and security of the website. The refresh token,
              valid for 2 weeks, is used to obtain a new access token without
              requiring users to re-enter their credentials. The CSRF token
              helps prevent cross-site request forgery attacks. Refresh tokens
              are automatically removed when the user logs out from the site.
            </p>
          </section>
          {}
          <section>
            <h2>5. Cookies and Tracking Technologies</h2>
            <p>
              We use essential cookies for the proper functioning of the
              website, including storing user avatars, random avatars,
              usernames, and useful keywords. These cookies are necessary for
              the website's functionality.
            </p>
          </section>
          <section>
            <h2>6. Third-Party Services</h2>
            <p>
              We use Cloudflare and Google Analytics. Please refer to the
              respective privacy policies of these services for more
              information.
            </p>
          </section>

          <section>
            <h2>7. Embedded content from other websites</h2>
            <p>
              Articles on this site may include embedded content (e.g. videos,
              images, articles, etc.). Embedded content from other websites
              behaves in the exact same way as if the visitor has visited the
              other website.
              <br></br>
              These websites may collect data about you, use cookies, embed
              additional third-party tracking, and monitor your interaction with
              that embedded content, including tracking your interaction with
              the embedded content if you have an account and are logged in to
              that website.
            </p>
          </section>
          <section>
            <h2>8. Who we share your data with</h2>
            <p>
              Users can edit their profiles and change information at any time.
              We do not engage in advertising, and we do not share your personal
              information with third parties, except as described in this
              privacy policy.
            </p>
          </section>

          <section>
            <h2>9. What rights you have over your data</h2>
            <p>
              If you have an account on this site, you have the option to
              request the deletion of any personal data we have stored about
              you. It's important to note that this request does not apply to
              data that we are obligated to retain for administrative, legal, or
              security reasons. Furthermore, you can request the removal of your
              account, and upon your request, we have a 14-day timeframe to
              complete the deletion process.
            </p>
          </section>

          <section>
            <h2>10. Contact Information</h2>
            <p>
              If you have any questions or concerns about our Privacy Policy,
              please contact us at: <br />
              Email:{" "}
              <a href="mailto:info@daics.net" style={{ fontSize: "20px" }}>
                info@daics.net
              </a>
              <br />
              Alternate Email:{" "}
              <a
                href="mailto:info.daics@gmail.com"
                style={{ fontSize: "20px" }}
              >
                info.daics@gmail.com
              </a>
              <br />
            </p>
          </section>
          <section>
            <h2>11. Changes to This Policy</h2>
            <p>
              We may update our Privacy Policy. Users are encouraged to review
              this page regularly for any changes.
            </p>
          </section>
        </div>
        <Footer />
        {}
      </Grid>
    </>
  );
};

export default PrivacyPolicy;
