import { CrystalToolkitScene } from "@materialsproject/mp-react-components";
import { Container } from "@mui/material";
import { useEffect, useState } from "react";

function Crystal(props) {
  const [scene, setScene] = useState({});
  let updatedScene = props.scene;
  let inletSize = props.inletSize;
  updatedScene.contents[0]["clickable"] = false;

  for (let i = 0; i < updatedScene.contents[0].contents.length; i++) {
    updatedScene.contents[0].contents[i].clickable = false;
  }
  for (let i = 0; i < updatedScene.contents[5].contents.length; i++) {
    updatedScene.contents[5].contents[i].headLength = 0.09;
    updatedScene.contents[5].contents[i].headWidth = 0.08;
    updatedScene.contents[5].contents[i].radius = 0.04;
  }

  useEffect(() => {
    setScene(updatedScene);
  }, []);

  return (
    <>
      <Container
        sx={{
          backgroundColor: "#EEF1EF",
          minWidth: "350px",
          minHeight: "350px",
        }}
      >
        {scene ? (
          <CrystalToolkitScene
            id="mtp--canvas"
            data={scene}
            sceneSize={"var(--mtpsize)"}
            toggleVisibility={{
              atoms: props.atoms,
              bonds: props.bonds,
              axes: 0,
              magmoms: 1,
              unit_cell: props.cell,
              polyhedra: props.polyhedra,
            }}
            inletSize={150}
            currentCameraState={inletSize}
            customCameraState={inletSize}
            settings={{
              staticScene: true,
              extractAxis: false,
              isMultiSelectionEnabled: false,
              secondaryObjectView: false,
            }}
            imageRequest={{
              filetype: "png",
            }}
            showControls={false}
            showExpandButton={false}
            showExportButton={false}
            showImageButton={false}
            showPositionButton={false}
          ></CrystalToolkitScene>
        ) : null}
      </Container>
    </>
  );
}

export default Crystal;
