import { Box } from "@mui/material";
import backgroundImage from "../../assets/img/crystal2WP.jpg";
import { Footer, Header } from "../../components/index.js";
import ContactForm from "./ContactForm";
import "./ContactPage.css";

const ContactPage = () => {
  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "repeat",
        }}
      >
        <Header />

        {}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: { xs: "90%", sm: "90%", md: "90%", lg: "80%" },
            margin: "40px auto",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "40px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.7)",
          }}
        >
          <div className="contact-container">
            {}
            <div className="contact-column">
              <div className="contact-left">
                <h1>We appreciate your interest in us.</h1>
              </div>
              <div className="contact-right">
                <h2>Let's Get in Touch</h2>
                <p>
                  Whether you have questions about DAICS, are interested in
                  licensing for full data access, wish to explore collaboration
                  opportunities within the field of Material Science, or are
                  considering supporting the DAICS project through financial or
                  computational resources, we welcome your inquiries.
                  <br />
                  <br />
                  Please don't hesitate to reach out to us via email or by using
                  the contact form below. We look forward to connecting with
                  you.
                </p>
              </div>
              <div className="contact-left">
                <h2>Contact Information</h2>
                <p>
                  Email:
                  <br />
                  info@daics.net
                  <br />
                  info.daics@gmail.com
                </p>
              </div>
            </div>

            {}
            <div className="contact-column">
              <div className="contact-right">
                <h2>Contact Form</h2>
                <ContactForm />
              </div>
            </div>
          </div>
        </Box>
        <Footer />
        {}
      </div>
    </>
  );
};

export default ContactPage;
