import CryptoJS from "crypto-js";

export function decryptData(data) {
  const SECRET_KEY = process.env.REACT_APP_DECODE_KEY;
  const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);

  const decrypted = CryptoJS.AES.decrypt(data, key, {
    mode: CryptoJS.mode.ECB,
  });

  const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

  try {
    return JSON.parse(decryptedData);
  } catch (error) {
    return decryptedData;
  }
}

const formatValue = (value, precision) =>
  value !== null ? Number(value).toFixed(precision) : null;

const formatLength = (value) =>
  value !== null ? `${formatValue(value, 3)} Å` : null;

const formatAngle = (value) =>
  value !== null ? `${formatValue(value, 1)}°` : null;

const formatInteger = (value) =>
  value !== null ? Number(value).toFixed(0) : null;

export const formatEnergeticProperties = (data) => [
  { name: "Total energy (eV)", value: formatValue(data.energy, 3) },
  {
    name: "Enthalpy of formation (eV/atom)",
    value: formatValue(data.f_energy, 3),
  },
  {
    name: "Atomization energy (eV/atom)",
    value: formatValue(data.a_energy, 3),
  },
  { name: "Entropy", value: formatValue(data.entropy, 3) },
  {
    name: "Free energy of formation",
    value: formatValue(data.free_energy, 3),
  },
  { name: "Heat capacity (Cp)", value: formatValue(data.heat_capacity, 3) },
  {
    name: "Zero point energy",
    value: formatValue(data.zero_point_energy, 3),
  },
  {
    name: "Energy above convex hull (eV/atom)",
    value: formatValue(data.e_hull, 3),
  },
];

export const formatElasticProperties = (data) => [
  { name: "Elastic tensor", value: formatValue(data.elastic_matrix, 1) },
  { name: "Bulk Modulus (GPa)", value: formatValue(data.bulk_modulus, 1) },
  { name: "Shear Modulus (GPa)", value: formatValue(data.shear_modulus, 1) },
  {
    name: "Young's Modulus (GPa)",
    value: formatValue(data.youngs_modulus, 1),
  },
  { name: "Poisson's ratio", value: formatValue(data.poissons_ratio, 2) },
  { name: "Pugh's Modulus", value: formatValue(data.pughs_modulus, 2) },
  {
    name: "Vickers Hardness (GPa)",
    value: formatValue(data.hardness_vick, 1),
  },
  {
    name: "Fracture toughness (MPa m^0.5)",
    value: formatValue(data.fracture_toughness, 2),
  },
  {
    name: "Lyakhov-Oganov model of Knoop hardness (GPa)",
    value: formatValue(data.hardness_og, 1),
  },
];

export const formatMagneticProperties = (data) => [
  {
    name: "Magnetization (μ_B/Å³)",
    value: formatValue(data.magnetization, 3),
  },
  { name: "MAE", value: formatValue(data.mae, 3) },
  { name: "Curie Temperature", value: formatValue(data.currie_temp, 3) },
  {
    name: "Local magnetic moment",
    value: formatValue(data.local_mag_mom, 3),
  },
];

export const formatGeneralProperties = (data) => [
  { name: "Formula", value: data.formula },
  { name: "Crystal system", value: data.crystal_system },
  { name: "Crystal class", value: data.crystal_class },
  { name: "Crystal family", value: data.crystal_family },
  { name: "Bravais lattice system", value: data.bravais_lattice_system },
  { name: "Bravais lattice type", value: data.bravais_lattice_type },
  { name: "Pearson symbol", value: data.pearson_symbol },
  { name: "Prototype", value: data.prototype },
  { name: "Volume (Å³)", value: formatValue(data.volume, 3) },
  { name: "Density (g/cm³)", value: formatValue(data.density, 3) },
];

export const formatGeometricProperties = (data) => [
  { name: "Lattice", value: data.lattice },
  { name: "A", value: formatLength(data.a) },
  { name: "B", value: formatLength(data.b) },
  { name: "C", value: formatLength(data.c) },
  { name: "Alpha", value: formatAngle(data.alpha) },
  { name: "Beta", value: formatAngle(data.beta) },
  { name: "Gamma", value: formatAngle(data.gamma) },
  { name: "PBC", value: data.pbc },
  { name: "Space group", value: data.spg },
  { name: "Space group number", value: formatInteger(data.spgnum) },
  { name: "Topology", value: data.topology },
];

export const formatDielectricProperties = (data) => [
  { name: "Dielectric constants", value: formatValue(data.dielectric, 3) },
];

export const formatDosAndBandStructurePhoto = (data) => [
  { name: "DOS at Fermi level", value: formatValue(data.dos, 3) },
  { name: "BandGap (eV)", value: formatValue(data.band_gap, 3) },
];

export const formatElectronicProperties = (data) => [
  { name: "DOS at Fermi level", value: formatValue(data.dos, 3) },
  { name: "BandGap (eV)", value: formatValue(data.band_gap, 3) },
  { name: "Fermi energy (eV)", value: formatValue(data.ferrmi_energy, 3) },
  { name: "Electride fitness (e/Å³)", value: formatValue(data.electride, 3) },
];

export const formatOtherProperties = (data) => [
  { name: "Charge", value: formatValue(data.charge, 3) },
  { name: "Sound velocity", value: formatValue(data.sound_velocity, 3) },
];

export const formatGeneralizedConvexHullProperties = (data) => [
  { name: "Generalized convex hull", value: formatValue(data.g_e_hull, 3) },
  {
    name: "Convex hull at pressure",
    value: formatValue(data.convexhull_at_pressure, 3),
  },
];

export const formatMLProperties = (data) => [
  { name: "Elastic tensor", value: data.ml_elastic_matrix },
  {
    name: "Bulk Modulus (GPa)",
    value: formatValue(data.ml_elasticproperties[0], 1),
  },
  {
    name: "Shear Modulus (GPa)",
    value: formatValue(data.ml_elasticproperties[1], 1),
  },
  {
    name: "Young's Modulus (GPa)",
    value: formatValue(data.ml_elasticproperties[2], 1),
  },
  {
    name: "Poisson's ratio",
    value: formatValue(data.ml_elasticproperties[3], 2),
  },
  {
    name: "Pugh's Modulus",
    value: formatValue(data.ml_elasticproperties[4], 2),
  },
  {
    name: "Vickers Hardness (GPa)",
    value: formatValue(data.ml_elasticproperties[5], 1),
  },
  {
    name: "Fracture toughness (MPa m^0.5)",
    value: formatValue(data.ml_elasticproperties[6], 2),
  },
];

export function reduceChemicalFormula(formula) {
  const elements = formula.split(" ");
  const counts = {};

  for (const element of elements) {
    const [symbol, count] = element.split(/(\d+)/).filter(Boolean);
    counts[symbol] = (counts[symbol] || 0) + (parseInt(count) || 1);
  }

  const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
  const countsArray = Object.values(counts);
  const commonDivisor = countsArray.reduce(gcd);

  const reducedFormula = Object.entries(counts)
    .map(([symbol, count]) =>
      count / commonDivisor === 1 ? symbol : symbol + count / commonDivisor
    )
    .join(" ");

  return reducedFormula;
}
