import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Snackbar, TextField, Tooltip } from "@mui/material";
import Tab from "@mui/material/Tab";
import { useState } from "react";

const CopyButton = ({ text }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCopy2Clipboard = () => {
    setOpenSnackbar(true);
    navigator.clipboard.writeText(text);
  };

  return (
    <Tooltip title="Copy text" placement="top">
      <Button
        sx={{
          textTransform: "none",
          right: 0,
          my: 1,
          mx: 0,
          fontWeight: "500 !important",
          fontSize: "1rem !important",
          whiteSpace: "pre-line",
          fontFamily: "Roboto",
          color: "inherit",
        }}
        onClick={handleCopy2Clipboard}
        aria-label="copy"
        size="small"
      >
        <ContentCopyIcon sx={{ color: "#555555 !important" }} />
      </Button>

      <Snackbar
        message="Copied to clipboard"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
        open={openSnackbar}
      />
    </Tooltip>
  );
};

function VASPCalc({ input }) {
  const [tab, setTab] = useState(0);

  const handleTabs = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleTabs}
            aria-label="lab API tabs example"
            TabIndicatorProps={{ sx: { display: "none" } }}
            sx={{
              "& .MuiTabs-flexContainer": {
                flexWrap: "wrap",
              },
            }}
          >
            {Object.entries(input).map((entry, i) => (
              <Tab key={i} label={entry[0]} value={i} />
            ))}
          </TabList>
        </Box>
        {Object.entries(input).map((entry, i) => (
          <TabPanel key={i} sx={{ p: 0, textAlign: "end" }} value={i}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <TextField
                variant="outlined"
                rows={5}
                multiline
                sx={{
                  fontWeight: "500 !important",
                  fontSize: "1rem !important",
                  whiteSpace: "pre-line",
                  fontFamily: "Roboto",
                  width: "100%",
                  "& > .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root": {
                    p: 1,
                  },
                }}
                value={entry[1]}
                InputProps={{ endAdornment: <CopyButton text={entry[1]} /> }}
              />
            </Box>
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
}

export default VASPCalc;
