const FiltersLink = (filters) => {
  const objectToQueryString = (obj) => {
    if (obj.hasOwnProperty("volume_max") && obj["volume_max"] === 2500) {
      delete obj["volume_max"];
    }

    const queryString = Object.entries(obj)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          value = value.join(",");
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join("&");

    return queryString;
  };

  const updateLink = () => {
    const queryString = objectToQueryString(filters);
    return `${queryString}`;
  };

  const link = updateLink();

  return link;
};

export default FiltersLink;
