import { Grid } from "@mui/material";
import { Footer, Header } from "../../components/index.js";

const Tutorials = () => {
  return (
    <Grid
      container
      sx={{
        height: "100%",
      }}
    >
      <Header />

      <div>
        <h1>Tutorials</h1>
        {}
      </div>
      <Footer />
      {}
    </Grid>
  );
};

export default Tutorials;
