import "./ByFormula.css";

const ByFormula = (props) => {
  return (
    <div className="id--box">
      <div className="button-group-box">
        <div
          onClick={(e) => props.addNumber(props.num)}
          className={`number-button ${props.active}`}
        >
          <div className="number-button-name"> {props.num}</div>
        </div>
      </div>
    </div>
  );
};

export default ByFormula;
