import { Box, Button, Card, Grid, Typography } from "@mui/material";
function PettiforDes() {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        textAlign: "justify",
        m: { xs: 1, md: 3 },
        mt: 5,
      }}
      variant="outlined"
    >
      <Grid xs={12} lg={6}>
        <Box
          sx={{
            p: 3,
          }}
        >
          <Typography
            variant="h6"
            component="h6"
            gutterBottom
            color="textPrimary"
          >
            Description
          </Typography>
          <Typography variant="body1" component="body1" color="#7A7A7A">
            This function generates a visual representation (Pettifor’s map) of
            binary materials on a two-dimensional space of elements. The
            sequence of elements plays a crucial role here. When similar
            elements are positioned closely to each other in this sequence, it
            often indicates that materials located nearby on the Pettifor’s map
            share similar properties. This concept enables us to predict new
            materials with properties akin to those of other predicted
            materials. The effectiveness of this prediction process hinges on
            the quality of the element sequence. A well-organized sequence leads
            to more accurate clustering of materials with similar properties. We
            offer a selection of renowned element sequences for user
            convenience. For additional details, please refer to the mentioned
            reference.
          </Typography>
        </Box>
      </Grid>
      <Grid xs={12} lg={6}>
        <Box
          sx={{
            p: 3,
          }}
        >
          <Typography variant="h6" component="h6" gutterBottom>
            Citation
          </Typography>
          <Typography variant="body1" component="body1" color="#7A7A7A">
            If you utilize this function in your work, we kindly request that
            you cite the corresponding reference paper. Proper attribution helps
            acknowledge the authors’ efforts and ensures the integrity of the
            research community. Thank you for your cooperation.
          </Typography>
          <Typography variant="h6" component="h6" gutterBottom sx={{ mt: 3 }}>
            References
          </Typography>
          <Button
            sx={{
              textDecoration: "none",
              textTransform: "none",
              color: "#7A7A7A !important",
              ":hover": {
                textDecoration: "underline",
                color: "red !important",
              },
            }}
            target="_blank"
            href={"https://www.nature.com/articles/s41524-020-0322-9"}
          >
            - Coevolutionary search for optimal materials in the space of all
            possible compounds.
          </Button>
          <br />
          <Button
            sx={{
              textDecoration: "none",
              textTransform: "none",
              color: "#7A7A7A !important",
              ":hover": {
                textDecoration: "underline",
                color: "red !important",
              },
            }}
            target="_blank"
            href={"https://pubs.acs.org/doi/10.1021/acs.jpcc.0c07857"}
          >
            - Nonempirical Definition of the Mendeleev Numbers: Organizing the
            Chemical Space.
          </Button>
        </Box>
      </Grid>
    </Card>
  );
}

export default PettiforDes;
