import Avatar from "../../components/UserPanel/Avatar/Avatar";
import GetUserData from "../../components/UserPanel/UserData/GetUserData";
const MyDetailsContent = () => {
  return (
    <div>
      <Avatar />
      <GetUserData />
    </div>
  );
};

export default MyDetailsContent;
