import { Box, Grid, Typography } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import Element from "../../components/Element/Element";
import ByFormula from "../../components/Home/byFormula/ByFormula";
import ById from "../../components/Home/byId/ById";
import ByStructure from "../../components/Home/byStructure/ByStructure";
import Filters from "../../components/Home/filters/Filters";
import Functions from "../../components/Home/functions/Functions";
import Search from "../../components/Home/search/Search";
import MTable from "../../components/Home/table/Table";
import GButton from "../../components/Utils/Button";
import { Footer, Header, Loading } from "../../components/index.js";
import { elements } from "../../data/_data";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { decryptData } from "../Results/utils";
import FiltersLink from "./FiltersLink";
import "./main.css";

const URL_FORMULA_DATA = process.env.REACT_APP_URL_FORMULA_DATA;
const URL_MAT_ID_DATA = process.env.REACT_APP_URL_MAT_ID_DATA;
const URL_ATOMS = process.env.REACT_APP_URL_ATOMS;

function DataBase() {
  const [acitveElements, setActiveElement] = useState({});
  const [selectedElements, setSelectedElements] = useState("");
  const [by, setBy] = useState("e");
  const [elementNumbers, setElementNumbers] = useState(new Set());
  const [active, setActive] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [results, setResults] = useState([]);
  const [data, setData] = useState({});
  const [dataCount, setDataCount] = useState(0);
  const [clearData, setClearData] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const axiosPrivate = useAxiosPrivate();

  const searchByElement = async (request, d) => {
    const cleanedRequest = request.replace(/\s/g, "");
    const filterLink = Object.keys(d).length !== 0 ? `&${FiltersLink(d)}` : "";
    const orderingLink = orderBy
      ? order === "asc"
        ? `&ordering=${orderBy}`
        : `&ordering=-${orderBy}`
      : "";
    const requestLink = `${URL_ATOMS}${cleanedRequest}&page=${
      page + 1
    }&page_size=${rowsPerPage}${orderingLink}${filterLink}`;

    const response = await axiosPrivate.get(requestLink);
    return response;
  };

  const searchByFormula = async (request, d) => {
    const cleanedRequest = request.replace(/\s/g, "");
    const filterLink = Object.keys(d).length !== 0 ? `&${FiltersLink(d)}` : "";

    const orderingLink = orderBy
      ? order === "asc"
        ? `&ordering=${orderBy}`
        : `&ordering=-${orderBy}`
      : "";

    // Split the formula into individual elements using commas
    const eachFormula = cleanedRequest.split(",");

    // Modify each element where count is 1
    const modifiedElements = eachFormula.map((formula) => {
      const elements = formula.split(/(?=[A-Z])/);

      // Modify the elements where count is 1
      const modifiedElements = elements.map((el) => {
        const match = el.match(/([A-Za-z]+)([0-9]*)/);
        const symbol = match[1];
        const count = match[2] ? parseInt(match[2], 10) : 1;
        return `${symbol}${count}`;
      });

      return modifiedElements.join("");
    });
    const modifiedFormula = modifiedElements.join(",");
    const requestLink = `${URL_FORMULA_DATA}${modifiedFormula}&page=${
      page + 1
    }&page_size=${rowsPerPage}${orderingLink}${filterLink}`;

    const response = await axiosPrivate.get(requestLink);
    return response;
  };

  const searchById = async (request, d) => {
    const cleanedRequest = request.replace(/\s/g, "");
    const requestLink = `${URL_MAT_ID_DATA}${cleanedRequest}`;

    const response = await axiosPrivate.get(requestLink);
    return response;
  };

  const send = async () => {
    setIsLoading(true);
    setLoaded(false);
    executeScroll();
    let requestFunction;

    if (by === "e") {
      requestFunction = searchByElement;
    } else if (by === "f") {
      requestFunction = searchByFormula;
    } else if (by === "i") {
      requestFunction = searchById;
    }
    try {
      const response = await requestFunction(selectedElements, data);
      setDataCount(response.data.count);
      const responseData = decryptData(response.data.results);
      setResults(responseData);
      setIsLoading(false);
      setLoaded(true);
      executeScroll();
    } catch (error) {
      console.error("An error occurred while processing the request:", error);

      setResults([]);
      setIsLoading(false);
      setLoaded(true);
    }
  };

  useEffect(() => {
    setPage(0);
  }, [data]);

  useEffect(() => {
    selectedElements && send();
  }, [page, rowsPerPage, order, orderBy]);

  const handleData = (d) => {
    setData(d);
  };
  const a = /,/g;
  const re = /[^!:^()]*\w\s/g;
  const reOpenBraces = /[(].*/;
  const reTest = /[!:^(]/g;

  const addElement = (num) => {
    const currentElement = elements[num];

    if (num > 97 && num < 122) {
      // Do nothing if the number is between 97 and 122
    } else {
      if (selectedElements) {
        let modifiedSelectedElements = selectedElements.replace(
          [...selectedElements.matchAll(re)].slice(-1),
          ""
        );

        if (
          !reOpenBraces.test(selectedElements) &&
          modifiedSelectedElements.includes(currentElement.symbol + " ")
        ) {
          modifiedSelectedElements = modifiedSelectedElements
            .replace(", " + currentElement.symbol + " ", "")
            .replace(currentElement.symbol + " ,", "")
            .replace(currentElement.symbol + " ", "");

          setSelectedElements(modifiedSelectedElements);
        } else if (reTest.test(selectedElements.slice(-1))) {
          setSelectedElements(
            selectedElements + " " + currentElement.symbol + " "
          );
        } else {
          if (
            reOpenBraces.test(selectedElements) &&
            [...selectedElements.matchAll(re)]
              .slice(-1)
              .includes(currentElement.symbol + " ")
          ) {
            const lastMatch = [...selectedElements.matchAll(re)].slice(-1);
            modifiedSelectedElements = modifiedSelectedElements.replace(
              ", " + currentElement.symbol + " ",
              ""
            );
            modifiedSelectedElements = modifiedSelectedElements.replace(
              currentElement.symbol + " ,",
              ""
            );
            modifiedSelectedElements = modifiedSelectedElements.replace(
              currentElement.symbol + " ",
              ""
            );

            setSelectedElements(
              selectedElements.replace(lastMatch, modifiedSelectedElements)
            );
          } else if (
            String([...selectedElements.matchAll(re)].slice(-1)).includes(
              currentElement.symbol + " "
            )
          ) {
            const lastMatch = [...selectedElements.matchAll(re)].slice(-1);
            if (lastMatch.length > 0) {
              let replacement = String(lastMatch[0]);
              replacement = replacement.replace(
                ", " + currentElement.symbol + " ",
                ""
              );
              replacement = replacement.replace(
                " " + currentElement.symbol + " ,",
                ""
              );
              replacement = replacement.replace(
                currentElement.symbol + " ",
                ""
              );

              setSelectedElements(replacement);
            }
          } else {
            let modifiedSelectedElements = selectedElements;
            if (by === "f") {
              modifiedSelectedElements =
                modifiedSelectedElements + currentElement.symbol + " ";
            } else {
              modifiedSelectedElements = a.test(selectedElements.slice(-1))
                ? modifiedSelectedElements + " " + currentElement.symbol + " "
                : modifiedSelectedElements + ", " + currentElement.symbol + " ";
            }

            setSelectedElements(modifiedSelectedElements);
          }
        }
      } else {
        setSelectedElements(currentElement.symbol + " ");
      }
    }
  };
  const clear = (e) => {
    setSelectedElements("");
    setElementNumbers(new Set());
    setData({});
    setClearData(!clearData);
  };

  const addAnd = (a, b) => {
    setSelectedElements(String(selectedElements) + ",");
  };

  const addOr = (a, b) => {
    setSelectedElements(String(selectedElements) + ":");
  };
  const addNot = (a, b) => {
    setSelectedElements(String(selectedElements) + "!");
  };
  const openBracket = (a, b) => {
    let s = selectedElements;
    s = s + ", (";
    setSelectedElements(s);
    s = selectedElements;
  };

  const closeBracket = (a, b) => {
    setSelectedElements(String(selectedElements) + ")");
  };

  const activeCategory = (name, numbers) => {
    const newNumbers = new Set(elementNumbers);

    numbers.forEach((i) => {
      newNumbers.has(i) ? newNumbers.delete(i) : newNumbers.add(i);
    });

    setElementNumbers(newNumbers);

    if (selectedElements) {
      let modifiedSelectedElements = selectedElements;
      let substrings = [":", ",", "!", "(", ")"];
      if (selectedElements.includes(name)) {
        modifiedSelectedElements = substrings.includes(
          selectedElements.slice(-1)
        )
          ? modifiedSelectedElements + " " + name + " "
          : modifiedSelectedElements
              .replace(", " + name + " ", "")
              .replace(name + " ,", "")
              .replace(name + " ", "");
      } else {
        modifiedSelectedElements =
          selectedElements +
          (substrings.includes(selectedElements.slice(-1)) ? " " : ", ") +
          name +
          " ";
      }

      setSelectedElements(modifiedSelectedElements);
    } else {
      setSelectedElements(name.replace("-", "") + " ");
    }
  };

  const addNumber = (num) => {
    if (selectedElements) {
      let modifiedSelectedElements =
        selectedElements.replace(" ", "") + String(num);
      setSelectedElements(modifiedSelectedElements);
    }
  };
  const overActiveCategory = (name, numbers) => {
    if (name === active) {
      setActive("");
      setElementNumbers([]);
    } else {
      setActive(name);
      setElementNumbers(numbers);
    }
  };

  const handleSearch = (e) => {
    setSelectedElements(e.target.value);
  };

  const handleBy = (e) => {
    clear();
    setResults([]);
    setLoaded(false);
    setBy(e.target.value);
  };

  const populateElements = (start, end) => {
    return Array.from({ length: end - start + 1 }, (_, i) => (
      <Element
        key={start + i}
        addElement={addElement}
        num={start + i}
        category={acitveElements}
        selectedElements={selectedElements}
        elementNumbers={elementNumbers}
      />
    ));
  };

  const populateGroups = (start, end) => {
    return Array.from({ length: end - start + 1 }, (_, i) => (
      <GButton
        key={start + i}
        num={start + i}
        overActiveCategory={overActiveCategory}
        activeCategory={activeCategory}
      />
    ));
  };

  const table = useRef(null);

  const executeScroll = () => {
    var element = document.getElementById("foooter");
    element.scrollIntoView({ behavior: "smooth", block: "end" });
  };
  return (
    <>
      <Header />

      <Grid sx={{ mt: 1, display: "flex", top: "0" }} container>
        <Grid xs={12} sm={12} md={10} lg={10} xl={10}>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
            }}
          >
            <Functions />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Search
              selectedElements={selectedElements}
              clear={clear}
              handleSearch={handleSearch}
              sendReq={send}
              handleBy={handleBy}
              by={by}
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={2} lg={2} xl={2}>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <Functions />
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={12} sm={12} md={10} lg={10} xl={10}>
          <div>
            {(by === "e") | (by === "f") ? (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "repeat(17,5.1vw) 1fr",
                    md: "repeat(17, 4.1vw) 1fr",
                  },
                  gridColumnGap: { xs: "1px", md: "2px" },
                  gridRowGap: { xs: "1px", md: "2px" },
                  height: "auto",
                  margin: "auto 0",
                  mx: { xs: "0", sm: "1vw", md: "2vw" },
                  mt: { xs: "2vw", md: "0" },
                }}
              >
                {populateElements(1, 4)}
                <Fragment>
                  {by === "e" ? (
                    <Box
                      sx={{
                        gridColumn: "3/13",
                        gridRow: "1/4",
                        padding: "0.9vw",
                        fontSize: "1vw",
                        maxHeight: "6vw",
                        size: "inherit",
                      }}
                    >
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(7,7.4vw)",
                            md: "repeat(7,5.9vw)",
                          },
                          fontSize: { xs: "1vw", md: "0.8vw" },
                          justifyContent: "center",
                        }}
                      >
                        {populateGroups(0, 13)}
                        <GButton
                          num={20}
                          overActiveCategory={overActiveCategory}
                          activeCategory={addAnd}
                        />
                        <GButton
                          num={14}
                          overActiveCategory={overActiveCategory}
                          activeCategory={addAnd}
                        />
                        <GButton
                          num={15}
                          overActiveCategory={overActiveCategory}
                          activeCategory={addOr}
                        />
                        <GButton
                          num={16}
                          overActiveCategory={overActiveCategory}
                          activeCategory={addNot}
                        />
                        <GButton
                          num={18}
                          overActiveCategory={overActiveCategory}
                          activeCategory={openBracket}
                        />
                        <GButton
                          num={19}
                          overActiveCategory={overActiveCategory}
                          activeCategory={closeBracket}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        gridColumn: "3/13",
                        gridRow: "1/4",
                        padding: "0.9vw",
                        fontSize: "1vw",
                        maxHeight: "6vw",
                        size: "inherit",
                      }}
                    >
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(10,4vw)",
                          fontSize: "0.8vw",
                        }}
                      >
                        {Array.from(Array(10)).map((_, index) => (
                          <ByFormula
                            num={index}
                            addNumber={addNumber}
                            active={selectedElements ? true : false}
                          />
                        ))}
                      </Box>
                    </Box>
                  )}
                </Fragment>
                {populateElements(5, 56)}
                {populateElements(120, 120)}
                {populateElements(72, 88)}
                {populateElements(121, 121)}
                {populateElements(104, 118)}
                {populateElements(57, 71)}
                {populateElements(89, 103)}
              </Box>
            ) : null}
            {by === "f" ? <p>f</p> : null}
            {by === "i" ? <ById /> : null}
            {by === "s" ? <ByStructure /> : null}
          </div>
        </Grid>
        <Grid xs={12} sm={12} md={2} lg={2} xl={2}>
          {/* <br /> */}
          <Box
            sx={{
              marginRight: "11px",
              mt: "12px",
            }}
          >
            <Filters handleDataa={handleData} clearData={clearData} />
          </Box>
        </Grid>
      </Grid>
      <Grid xs={12} lg={12} sx={{ mt: "auto" }}>
        <Box
          ref={table}
          sx={{
            display: "flex",
            justifyContent: "center",
            justifyItems: "center",
            my: "3vw",
          }}
        >
          {isLoading ? (
            <Loading type={"spinningBubbles"} color="#4A5073" />
          ) : loaded ? (
            results.length > 0 ? (
              <MTable
                data={results}
                count={dataCount}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            ) : (
              <Typography variant="h6" sx={{ color: "red" }}>
                No data found
              </Typography>
            )
          ) : null}
        </Box>
        <Footer />
        <div id="foooter"></div>
      </Grid>
    </>
  );
}

export default DataBase;
