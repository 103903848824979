import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import ContactForm from "../Contact/ContactFormUser";

const SubscriptionContact = ({ contact, setContact }) => {
  const contactRef = useRef(null);

  useEffect(() => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [contact]);
  return (
    <>
      <Box
        ref={contactRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          maxWidth: "1200px",
          margin: "40px auto",
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "30px",
          backgroundColor: "rgba(255, 255, 255, 0.94)",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <ContactForm contact={contact} setContact={setContact} />
      </Box>
    </>
  );
};

export default SubscriptionContact;
