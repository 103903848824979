import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { useEffect, useState } from "react";
import CrystalSystems from "../../../components/Filters/CrystalSystems";
import SliderTreeItem from "../../../components/Filters/SliderTreeItem";
import SpaceGroup from "../../../components/Filters/SpaceGroup";
import SliderItem from "../../Filters/SliderItem";
import "./Filters.css";
import sg from "./sg";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "triclinic",
  "monoclinic",
  "orthorhombic",
  "tetragonal",
  "trigonal",
  "hexagonal",
  "cubic",
];

const default_values = {
  EAH_min: 0,
  EAH_max: 1,
  FE_min: -4,
  FE_max: 4,
  hardness_min: 0,
  hardness_max: 100,
  density_min: 0,
  density_max: 25,
  volume_min: 0,
  volume_max: 2500,
  PR_min: -1,
  PR_max: 0.5,
  SM_min: 0,
  SM_max: 398,
  BM_min: 2,
  BM_max: 523,
  YM_min: 6,
  YM_max: 436,
  PM_min: 6,
  PM_max: 436,
};

export default function Filters({ handleDataa, clearData }) {
  const [EAH, setEAH] = useState([0, 1]);
  const [FE, setFE] = useState([-4, 4]);
  const [hardness, setHardness] = useState([0, 100]);
  const [density, setDensity] = useState([0, 25]);
  const [volume, setVolume] = useState([0, 2500]);
  const [CS, setCS] = useState([]);
  const [SN, setSN] = useState([]);
  const [SS, setSS] = useState([]);
  const [elasticProperties, setElasticProperties] = useState(false);
  const [magneticProperties, setMagneticProperties] = useState(false);
  const [zt, setZT] = useState(false);
  const [dielectricProperties, setDielectricProperties] = useState(false);
  const [electride, setElectride] = useState(false);
  const [generalizedConvexHull, setGeneralizedConvexHull] = useState(false);
  const [dos, setDOS] = useState(false);
  const [superconductivity, setSuperconductivity] = useState(false);
  const [bandGap, setBandGap] = useState(false);
  const [PR, setPR] = useState([-1, 0.5]);
  const [SM, setSM] = useState([0, 398]);
  const [BM, setBM] = useState([2, 523]);
  const [YM, setYM] = useState([6, 436]);
  const [PM, setPM] = useState([6, 436]);
  const [openTree, setOpenTree] = useState([]);
  const [data, setData] = useState({});
  const [species, setSpecies] = useState([]);

  const sgs = sg.sg;

  const handleClear = () => {
    setEAH([0, 1]);
    setFE([-4, 4]);
    setHardness([0, 100]);
    setDensity([0, 25]);
    setVolume([0, 2500]);
    setCS([]);
    setSN([]);
    setSS([]);
    setElasticProperties(false);
    setMagneticProperties(false);
    setZT(false);
    setDielectricProperties(false);
    setElectride(false);
    setGeneralizedConvexHull(false);
    setDOS(false);
    setSuperconductivity(false);
    setPR([-1, 0.5]);
    setSM([0, 398]);
    setBM([2, 523]);
    setYM([6, 436]);
    setPM([6, 436]);
    setOpenTree([]);
    setData({});
    setSpecies([]);
  };
  useEffect(() => {
    handleClear();
  }, [clearData]);

  useEffect(() => {
    handleData();
  }, [data]);
  const handleOpen = (n) => {
    setOpenTree((oldExpanded) => (oldExpanded[0] === n ? [] : [n]));
  };
  const handleData = () => {
    handleDataa(data);
  };
  const handleEAH = (evnet, newEAH) => {
    handleRange("e_hull", setEAH, newEAH);
  };
  const handleFE = (evnet, newFE) => handleRange("f_energy", setFE, newFE);
  const handleHardness = (evnet, newHardness) =>
    handleRange("hardness_og", setHardness, newHardness);
  const handleDensity = (evnet, newDensity) =>
    handleRange("density", setDensity, newDensity);
  const handleVolume = (evnet, newVolume) =>
    handleRange("volume", setVolume, newVolume);

  const handleCS = (event) => handleSingleValue("crystal_system", setCS, event);
  const handleSN = (event) => {
    handleSingleValue("spg", setSS, event);
    handleSingleValue("spgnum", setSN, event);
  };
  const handleSS = (event) => {
    handleSingleValue("spg", setSS, event);
    handleSingleValue("spgnum", setSN, event);
  };

  const handleElasticProperties = (event) =>
    handleBooleanProperty("bulk_modulus", setElasticProperties, event);
  const handleMagneticProperties = (event) =>
    handleBooleanProperty("magnetization", setMagneticProperties, event);
  const handleZT = (event) => handleBooleanProperty("zt", setZT, event);
  const handleDielectricProperties = (event) =>
    handleBooleanProperty("dielectric", setDielectricProperties, event);
  const handleElectride = (event) =>
    handlePositiveProperty("electride", setElectride, event);
  const handleGeneralizedConvexHull = (event) =>
    handleBooleanProperty("g_e_hull", setGeneralizedConvexHull, event);
  const handleDOS = (event) => handleBooleanProperty("dos", setDOS, event);
  const handleSuperconductivity = (event) =>
    handleBooleanProperty("superconductivity", setSuperconductivity, event);

  const handleBandGap = (event) =>
    handleBooleanProperty("band_gap", setBandGap, event);
  const handlePR = (event, newPR) =>
    handleRangeProperty("poissons_ratio", setPR, event, newPR);
  const handleSM = (event, newSM) =>
    handleRangeProperty("shear_modulus", setSM, event, newSM);
  const handleBM = (event, newBM) =>
    handleRangeProperty("bulk_modulus", setBM, event, newBM);
  const handleYM = (event, newYM) =>
    handleRangeProperty("youngs_modulus", setYM, event, newYM);
  const handlePM = (event, newPM) =>
    handleRangeProperty("pughs_modulus", setPM, event, newPM);

  const handleRange = (property, stateSetter, rangeValues) => {
    stateSetter(rangeValues);
    let newdata = { ...data };
    newdata[`${property}_min`] = rangeValues[0];
    newdata[`${property}_max`] = rangeValues[1];
    setData(newdata);
  };

  const handleSingleValue = (property, stateSetter, event) => {
    const {
      target: { value },
    } = event;
    stateSetter(typeof value === "string" ? value.split(",") : value);

    let newdata = { ...data };

    if (value && value.length > 0) {
      newdata[`${property}__in`] = value;
    } else {
      delete newdata[`${property}__in`];
    }

    setData(newdata);
  };

  const handleBooleanProperty = (property, stateSetter, event) => {
    const isChecked = event.target.checked;
    stateSetter(isChecked);
    let newdata = { ...data };
    if (isChecked) {
      newdata[`${property}`] = !isChecked;
    } else {
      delete newdata[`${property}`];
    }

    setData(newdata);
  };

  const handlePositiveProperty = (property, stateSetter, event) => {
    const isChecked = event.target.checked;
    stateSetter(isChecked);
    let newdata = { ...data };
    if (isChecked) {
      newdata[`${property}`] = 0;
    } else {
      delete newdata[`${property}`];
    }

    setData(newdata);
  };

  const handleRangeProperty = (property, stateSetter, event, rangeValues) => {
    stateSetter(rangeValues);
    let newdata = { ...data };
    newdata[`${property}_min`] = rangeValues[0];
    newdata[`${property}_max`] = rangeValues[1];
    setData(newdata);
  };

  const handleMin = (e) => {
    let newSpecies = species;
    if (e.target.value > 0 && e.target.value < 9) {
      setSpecies([e.target.value, newSpecies?.length > 1 ? newSpecies[1] : []]);
      let newdata = data;
      newdata["complexity_min"] = Number(e.target.value);
      setData(newdata);
    }
    if (!e.target.value) {
      setSpecies([null, newSpecies?.length > 1 ? newSpecies[1] : []]);
      let newdata = data;
      newdata["complexity_min"] = null;
      setData(newdata);
    }
  };
  const handleMax = (e) => {
    let newSpecies = species;
    if (e.target.value < 9 && e.target.value > 0) {
      setSpecies([newSpecies?.length > 1 ? newSpecies[0] : [], e.target.value]);
      let newdata = data;
      newdata["complexity_max"] = Number(e.target.value);
      setData(newdata);
    }
    if (!e.target.value) {
      setSpecies([newSpecies?.length > 1 ? newSpecies[0] : [], null]);
      let newdata = data;
      newdata["complexity_max"] = null;
      setData(newdata);
    }
  };
  const ElasticitySliderItems = [
    {
      label: "Poisson's ratio",
      values: PR,
      handleSliderChange: handlePR,
      min: default_values.PR_min,
      max: default_values.PR_max,
      step: 0.01,
    },
    {
      label: "Shear Modulus",
      values: SM,
      handleSliderChange: handleSM,
      min: default_values.SM_min,
      max: default_values.SM_max,
      step: 0.01,
    },
    {
      label: "Bulk Modulus",
      values: BM,
      handleSliderChange: handleBM,
      min: default_values.BM_min,
      max: default_values.BM_max,
      step: 0.01,
    },
    {
      label: "Young's Modulus",
      values: YM,
      handleSliderChange: handleYM,
      min: default_values.YM_min,
      max: default_values.YM_max,
      step: 0.01,
    },
    {
      label: "Pugh's Modulus",
      values: PM,
      handleSliderChange: handlePM,
      min: default_values.PM_min,
      max: default_values.PM_max,
      step: 0.01,
    },
  ];

  const SliderTreeItems = [
    {
      nodeId: "1",
      label: "Energy Above Hull",
      handleOpen: handleOpen,
      values: EAH,
      handleSliderChange: handleEAH,
      unit: "eV/atom",
      min: default_values.EAH_min,
      max: default_values.EAH_max,
      step: 0.01,
    },
    {
      nodeId: "2",
      label: "Enthalpy of Formation",
      handleOpen: handleOpen,
      values: FE,
      handleSliderChange: handleFE,
      unit: "eV/atom",
      min: default_values.FE_min,
      max: default_values.FE_max,
      step: 0.01,
    },
    {
      nodeId: "3",
      label: "Hardness",
      handleOpen: handleOpen,
      values: hardness,
      handleSliderChange: handleHardness,
      unit: "GPa",
      min: default_values.hardness_min,
      max: default_values.hardness_max,
      step: 0.01,
    },
    {
      nodeId: "4",
      label: "Density",
      handleOpen: handleOpen,
      values: density,
      handleSliderChange: handleDensity,
      unit: (
        <>
          g/cm
          <sup
            style={{
              verticalAlign: "super",
              fontSize: "inherit",
              color: "inherit",
              fontWeight: "inherit",
            }}
          >
            3
          </sup>
        </>
      ),
      min: default_values.density_min,
      max: default_values.density_max,
      step: 1,
    },
    {
      nodeId: "5",
      label: "Volume",
      handleOpen: handleOpen,
      values: volume,
      handleSliderChange: handleVolume,
      unit: (
        <>
          A
          <sup
            style={{
              verticalAlign: "super",
              fontSize: "inherit",
              color: "inherit",
              fontWeight: "inherit",
            }}
          >
            3
          </sup>
          /cell
        </>
      ),
      min: default_values.volume_min,
      max: default_values.volume_max,
      step: 1,
    },
  ];

  return (
    <>
      <Box
        title="Select Species"
        sx={{
          display: "list-item",
          backgroundColor: "#61acc5 !important",
          border: "1px solid #30435f !important",
          borderRadius: "5px",
          marginLeft: { xs: "11px", md: "0px" },
          padding: "5px",
          listStyleType: "none",
        }}
      >
        <Typography># of Species</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "start", md: "space-between" },
            px: "10px",
          }}
        >
          {["min", "max"].map((label, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography>{label}:</Typography>
              <TextField
                sx={{
                  maxWidth: "35px",
                  marginLeft: "3px",
                  border: "unset",
                  padding: 0,
                  backgroundColor: "#ffffff",
                  height: "fit-content",
                  "& .MuiInputBase-input": {
                    padding: 0,
                    px: "5px",
                    py: "1px",
                    border: "unset",
                    height: "inherit",
                  },
                }}
                type="number"
                value={species?.length > 1 ? species[index] : []}
                onChange={index === 0 ? handleMin : handleMax}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={{ fontSize: 11 }}>
        <TreeView
          defaultCollapseIcon={<KeyboardDoubleArrowDownIcon />}
          defaultExpandIcon={<KeyboardDoubleArrowRightIcon />}
          sx={{ marginLeft: { xs: "11px", md: "0px" } }}
          expanded={openTree}
        >
          {SliderTreeItems.map((item) => (
            <SliderTreeItem
              key={item.nodeId}
              nodeId={item.nodeId}
              unit={item.unit}
              label={item.label}
              values={item.values}
              handleOpen={item.handleOpen}
              handleSliderChange={item.handleSliderChange}
              min={item.min}
              max={item.max}
              step={item.step}
            />
          ))}
          <CrystalSystems
            label="Crystal Systems"
            handleOpen={handleOpen}
            nodeId="6"
            values={CS}
            handleOnChange={handleCS}
            MenuProps={MenuProps}
            names={names}
          />
          <SpaceGroup
            label="SpaceGroup"
            handleOpen={handleOpen}
            nodeId="7"
            valuesSN={SN}
            valuesSS={SS}
            valuesSGS={sgs}
            handleSNChange={handleSN}
            handleSSChange={handleSS}
            MenuProps={MenuProps}
          />
          <TreeItem
            sx={{
              backgroundColor: "#61acc5",
              margin: "1vw auto",
              border: "1px solid  #30435f",
              borderRadius: "5px",
              "& .MuiTreeItem-iconContainer svg": {
                fontSize: "26px",
                color: "#EAE7DC !important",
              },
            }}
            nodeId="10"
            // label="Elasticity "
            label={<Typography>Elasticity</Typography>}
            onClick={(e) => handleOpen("10")}
          >
            <Box sx={{ fontSize: 11 }}>
              {ElasticitySliderItems.map((item) => (
                <SliderItem
                  key={item.label}
                  label={item.label}
                  values={item.values}
                  handleSliderChange={item.handleSliderChange}
                  min={item.min}
                  max={item.max}
                  step={item.step}
                />
              ))}
            </Box>
          </TreeItem>
          <TreeItem
            sx={{
              backgroundColor: "#61acc5",
              margin: "1vw auto",
              border: "1px solid  #30435f",
              borderRadius: "5px",
              "& .MuiTreeItem-iconContainer svg": {
                fontSize: "26px",
                color: "#EAE7DC !important",
              },
            }}
            nodeId="9"
            label={<Typography>Has properties</Typography>}
            onClick={(e) => handleOpen("9")}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={elasticProperties}
                    onChange={handleElasticProperties}
                  />
                }
                label="Elastic properties"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={magneticProperties}
                    onChange={handleMagneticProperties}
                  />
                }
                label="Magnetic properties"
              />
              <FormControlLabel
                control={<Checkbox checked={zt} onChange={handleZT} />}
                label="ZT"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dielectricProperties}
                    onChange={handleDielectricProperties}
                  />
                }
                label="Dielectric properties"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={electride} onChange={handleElectride} />
                }
                label="Electride"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={generalizedConvexHull}
                    onChange={handleGeneralizedConvexHull}
                  />
                }
                label="Generalized convex hull"
              />
              <FormControlLabel
                control={<Checkbox checked={dos} onChange={handleDOS} />}
                label="DOS"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={bandGap} onChange={handleBandGap} />
                }
                label="Band gap"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={superconductivity}
                    onChange={handleSuperconductivity}
                  />
                }
                label="Superconductivity"
              />
            </FormGroup>
          </TreeItem>
        </TreeView>
      </Box>
    </>
  );
}
