import { groups } from "../../data/_groups";
import "../../pages/HomePage/style.css";

function GButton(props) {
  let { num } = props;
  let group = groups[num];
  const onMouseEnter = (event) => {
    props.overActiveCategory(group.name, group.numbers);
  };

  const onMouseLeave = (event) => {
    props.overActiveCategory(group.name, group.numbers);
  };
  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={(e) => props.activeCategory(group.name, group.numbers)}
      className={`button-group ${group.category}`}
    >
      <div className="button-group-name"> {group.name}</div>
    </div>
  );
}

export default GButton;
