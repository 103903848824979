import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  customheader: {
    color: "white !important",
    backgroundColor: "#0a1930 !important",
    borderBottom: "1.5px solid white",
    boxShadow: "none !important",
  },
  logo: {
    width: "auto",
    height: "60px",
    margin: "30px 20px",
    cursor: "pointer",
  },
  headeritems: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    justifyItems: "right",
  },
  signButton: {
    fontSize: "0.9rem",
    border: "1.5px solid #FFA500",
    borderRadius: "5px",
    padding: "0px 4px",
    marginLeft: "1rem",
    "&:hover": {
      backgroundColor: "orangered",
      color: "white",
    },
    "&:active": {
      backgroundColor: "var(--light-orange)",
      color: "white",
    },
  },

  mobileSignButton: {
    padding: "6px 16px",
    fontSize: "16px",
    color: "#090c08",
    fontWeight: "500",
    backgroundColor: "#FFCD57",
    cursor: "pointer",
    border: "1px solid lightgray",
    borderRadius: "7px",
    margin: "0.5rem",
    marginTop: "1rem",
    transition: "background-color 0.3s ease, color 0.3s ease",
    "&:hover": {
      backgroundColor: "orangered",
      color: "white",
    },
  },
  icon: {
    color: "#090c08 !important",
    cursor: "pointer",
    margin: "0 0.1rem",
    transition: "background-color 0.3s ease, color 0.3s ease",
    "&:hover": {
      backgroundColor: "lightgray",
      boxShadow: "0 0 5px lightgray",
      borderRadius: "60%",
    },
  },
}));

export default useStyles;
