import {
  faCheck,
  faInfoCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { register } from "../../api/newAuth";
import GoogleLogo from "../../assets/icon/G.svg";
import backgroundImage from "../../assets/img/grapheneWallpaper.jpg";
import { getItemWithExpiration } from "../../components/Cookies/CookiesConsent";
import LightFooter from "../../components/Footer/LightFooter";
import SuccessRegistration from "../../components/Utils/SuccessRegistration";
import "./SignUp.css";

const URL_SERVER = process.env.REACT_APP_URL_SERVER;
const GOOGLE_REDIRECT = process.env.REACT_APP_GOOGLE_REDIRECT;

const USER_REGEX = /^[A-Za-z][A-Za-z0-9-_]{3,23}$/;
const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const PWD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()-_]).{8,24}$/;

const SignUp = () => {
  const userRef = useRef();
  const emailRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [receiveNewsletter, setReceiveNewsletter] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const isAcceptedCookies = getItemWithExpiration("consent");

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user]);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [user, email, pwd, matchPwd]);

  const handleTermsChange = (isChecked) => {
    setErrMsg("");
    setTermsAccepted(isChecked);
  };

  const handleNewsletterChange = () => {
    setReceiveNewsletter(!receiveNewsletter);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isAcceptedCookies) {
      setErrMsg("Please accept cookies to proceed.");

      return;
    }

    const username = user;
    const useremail = email;
    const password = pwd;
    const password2 = matchPwd;

    const v1 = USER_REGEX.test(user);
    const v2 = EMAIL_REGEX.test(email);
    const v3 = PWD_REGEX.test(pwd);
    if (!v1 || !v2 || !v3) {
      setErrMsg("Invalid Entry");
      return;
    }

    try {
      const response = await register(username, useremail, password, password2);
      setSuccess(true);
      setUser("");
      setPwd("");
      setMatchPwd("");
    } catch (err) {
      if (err.message) {
        setErrMsg(err.message);
      } else {
        setErrMsg("Registration Failed");
      }
      errRef.current.focus();
    }
  };

  const handleGoogleSignUp = () => {
    if (!isAcceptedCookies) {
      setErrMsg("Please accept cookies to proceed.");
      return;
    }
    if (!termsAccepted) {
      setErrMsg("You must accept the terms and conditions to continue");
      return;
    }
    window.location.href = URL_SERVER + GOOGLE_REDIRECT;
  };

  return (
    <>
      <div
        className="signupmain"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container className="containermain" component="main">
          {success ? (
            <section>
              <SuccessRegistration email={email} />
            </section>
          ) : (
            <section>
              <p
                ref={errRef}
                className={errMsg ? "errmsg" : "offscreen"}
                aria-live="assertive"
              >
                {errMsg}
              </p>
              <form onSubmit={handleSubmit}>
                <label htmlFor="username">
                  Username:
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={validName ? "valid" : "hide"}
                  />
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={validName || !user ? "hide" : "invalid"}
                  />
                </label>
                <input
                  type="text"
                  id="username"
                  ref={userRef}
                  autoComplete="off"
                  onChange={(e) => setUser(e.target.value)}
                  value={user}
                  required
                  aria-invalid={validName ? "false" : "true"}
                  aria-describedby="uidnote"
                  onFocus={() => setUserFocus(true)}
                  onBlur={() => setUserFocus(false)}
                />
                <p
                  id="uidnote"
                  className={
                    userFocus && user && !validName
                      ? "instructions"
                      : "offscreen"
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                  4 to 24 characters.
                  <br />
                  Must begin with a letter.
                  <br />
                  Letters, numbers, underscores, hyphens allowed.
                </p>

                <label htmlFor="email">
                  Email:
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={validEmail ? "valid" : "hide"}
                  />
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={validEmail || !email ? "hide" : "invalid"}
                  />
                </label>

                <input
                  type="email"
                  id="email"
                  ref={emailRef}
                  autoComplete="off"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                  aria-invalid={validEmail ? "false" : "true"}
                  aria-describedby="emailnote"
                  onFocus={() => setEmailFocus(true)}
                  onBlur={() => setEmailFocus(false)}
                />

                <p
                  id="emailnote"
                  className={
                    emailFocus && email && !validEmail
                      ? "instructions"
                      : "offscreen"
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                  Must be a valid email address.
                  <br />
                  We recommend using your work email.
                </p>

                <label htmlFor="password">
                  Password:
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={validPwd ? "valid" : "hide"}
                  />
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={validPwd || !pwd ? "hide" : "invalid"}
                  />
                </label>
                <input
                  type="password"
                  id="password"
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                  required
                  aria-invalid={validPwd ? "false" : "true"}
                  aria-describedby="pwdnote"
                  onFocus={() => setPwdFocus(true)}
                  onBlur={() => setPwdFocus(false)}
                />
                <p
                  id="pwdnote"
                  className={
                    pwdFocus && !validPwd ? "instructions" : "offscreen"
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                  8 to 24 characters.
                  <br />
                  Must include uppercase and lowercase letters, a number and a
                  special character.
                </p>

                <label htmlFor="confirm_pwd">
                  Confirm Password:
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={validMatch && matchPwd ? "valid" : "hide"}
                  />
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={validMatch || !matchPwd ? "hide" : "invalid"}
                  />
                </label>
                <input
                  type="password"
                  id="confirm_pwd"
                  onChange={(e) => setMatchPwd(e.target.value)}
                  value={matchPwd}
                  required
                  aria-invalid={validMatch ? "false" : "true"}
                  aria-describedby="confirmnote"
                  onFocus={() => setMatchFocus(true)}
                  onBlur={() => setMatchFocus(false)}
                />
                <p
                  id="confirmnote"
                  className={
                    matchFocus && !validMatch ? "instructions" : "offscreen"
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                  Must match the first password input field.
                </p>

                <label className="checkbox-label">
                  <input
                    className="checkbox"
                    type="checkbox"
                    required
                    onChange={(e) => handleTermsChange(e.target.checked)}
                  />
                  <p>
                    I agree to the{" "}
                    <span
                      className="checkbox-text"
                      onClick={(e) => navigate("/terms-and-conditions")}
                    >
                      {" "}
                      terms and conditions
                    </span>{" "}
                    of DAICS.
                  </p>
                </label>
                <label className="checkbox-label">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(e) => handleNewsletterChange()}
                  />
                  <p>
                    I would like to subscribe to receive DAICS news and updates
                    via email.
                  </p>
                </label>

                <button
                  className={`signup-btn ${
                    !validName || !validEmail || !validPwd || !validMatch
                      ? ""
                      : "active"
                  }`}
                  disabled={
                    !validName || !validEmail || !validPwd || !validMatch
                      ? true
                      : false
                  }
                >
                  Sign Up
                </button>
              </form>
              <Typography
                variant="body1"
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  transition: "background-color 0.3s ease, color 0.3s ease",
                  "&:hover": {
                    color: "blue",
                  },
                }}
                onClick={() => navigate("/login")}
              >
                {"Already have an account? Sign in"}
              </Typography>

              <Button className="google-btn" onClick={handleGoogleSignUp}>
                <img src={GoogleLogo} alt="GoogleLogo" width={25} />
                Sign up with Google
              </Button>
            </section>
          )}
        </Container>
        <LightFooter positioning={"fixed"} />
      </div>
    </>
  );
};

export default SignUp;
