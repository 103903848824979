module.exports = {
    groups: [
        {
            name: "Metals",
            category: 'metal',
            numbers: [3, 4, 11, 12, 13, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83]
        },
        {
            name: "Non-Metals",
            category: 'non-metal',
            numbers: [1, 2, 5, 6, 7, 8, 9, 10, 14, 15, 16, 17, 18, 33, 34, 35, 36, 52, 53, 54]
        },
        {
            name: "Alkali Metals",
            category: 'alkali-metal',
            numbers: [3, 11, 19, 37, 55, 87]
        },
        {
            name: "Alkali Earths",
            category: 'alkali-earth-metal',
            numbers: [4, 12, 20, 38, 56, 88]
        },
        {
            name: "Transition Metals",
            category: 'transition-metal',
            numbers: [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 72, 73, 74, 75, 76, 77, 78, 79, 80]
        },
        {
            name: "Boron Group",
            category: 'boron-group',
            numbers: [5, 13, 31, 49, 81]
        },
        {
            name: "Carbon Group",
            category: 'carbon-group',
            numbers: [6, 14, 32, 50, 82]
        },
        {
            name: "Pnictogens",
            category: 'pnictogens',
            numbers: [7, 15, 33, 51, 83]
        },
        {
            name: "Chalcogens",
            category: 'chalcogens',
            numbers: [8, 16, 34, 52]
        },
        {
            name: "Halogens",
            category: 'halogens',
            numbers: [9, 17, 35, 53, 85]
        },
        {
            name: "Noble Gas",
            category: 'noble-gas',
            numbers: [2, 10, 18, 36, 54, 86]
        },
        {
            name: "Lanthanides",
            category: 'lanthoid',
            numbers: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71]
        },
        {
            name: "Actinoids",
            category: 'actinoid',
            numbers: [89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103]
        },
        {
            name: "Other Metals",
            category: 'other-metal',
            numbers: [13, 31, 32, 49, 50, 51, 81, 82, 83]
        },
        {
            name: "And",
            category: 'logical',
            numbers: []
        },
        {
            name: "Or",
            category: 'logical',
            numbers: []
        },
        {
            name: "Not",
            category: 'logical',
            numbers: []
        },
        {
            name: "Xor",
            category: 'logical',
            numbers: []
        },
        {
            name: "(",
            category: 'logical',
            numbers: []
        },
        {
            name: ")",
            category: 'logical',
            numbers: []
        },
        {
            name: "",
            category: 'non',
            numbers: []
        },
    ]
};