import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputBase from "@mui/material/InputBase";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
function Search(props) {
  const clear_ = (event) => {
    props.clear(event);
  };
  let placeHolder = "";
  if (props.by === "e") {
    placeHolder = "i.e., Si, P ; Na , Halogens ! Br , ...";
  } else if (props.by === "f") {
    placeHolder = "Br2Si3";
  } else if (props.by === "i") {
    placeHolder = "mat_b61540624";
  } else if (props.by === "s") {
    placeHolder = "";
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        mb: "1vw",
        width: { xs: "100%", md: "80%" },
      }}
    >
      <Box
        sx={{
          backgroundColor: "gray",
          borderRadius: "5px",
          // px: "0.5vw",
          mt: "0.5vw",
          mb: "1vw",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <FormControl component="fieldset">
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              px: "0.5vw",
              ml: { xs: 0, md: "-1vw" },
            }}
          >
            <Typography
              sx={{
                mx: 2,
              }}
            >
              Search by :
            </Typography>
            <RadioGroup
              row
              aria-label="gender"
              name="row-radio-buttons-group"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={props.by === "e"}
                    onChange={props.handleBy}
                    value="e"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "E" }}
                    key="1"
                    color="secondary"
                  />
                }
                label="Element"
              />

              <FormControlLabel
                control={
                  <Radio
                    checked={props.by === "f"}
                    onChange={props.handleBy}
                    value="f"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "F" }}
                    key="2"
                    color="secondary"
                  />
                }
                label="Formula"
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={props.by === "i"}
                    onChange={props.handleBy}
                    value="i"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "I" }}
                    key="3"
                    color="secondary"
                  />
                }
                label="ID"
              />

              {/* <FormControlLabel
                control={
                  <Radio
                    checked={props.by === "s"}
                    onChange={props.handleBy}
                    value="s"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "S" }}
                    key="4"
                    color="secondary"
                  />
                }
                label="Structure"
              /> */}
            </RadioGroup>
          </Box>
        </FormControl>
      </Box>
      {props.by !== "s" ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: { xs: "95%", md: "100%" },
            mr: { xs: "5vw", md: "4vw" },
            ml: { xs: "3vw", md: "4vw" },
          }}
        >
          <InputBase
            variant="outlined"
            sx={{
              mx: 1,
              width: "95%",
              backgroundColor: "#ffffff",
              border: "2px solid #808080",
              borderRadius: "15px",
              px: 1,
            }}
            value={props.selectedElements}
            onChange={props.handleSearch}
            placeholder={placeHolder}
          />
          <Stack direction="row" spacing={1}>
            <Button
              color="success"
              variant="contained"
              size="small"
              onClick={(e) => props.sendReq(e)}
            >
              Search
            </Button>
            <Button
              color="error"
              size="small"
              onClick={(e) => clear_(e)}
              variant="contained"
              sx={{
                mx: 10,
              }}
            >
              Clear
            </Button>
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}

export default Search;
