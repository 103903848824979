import emailjs from "@emailjs/browser";
import { Alert, Button, Snackbar } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import GetIPData from "../../components/UserPanel/GetIPData";

const EMJS_SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const EMJS_TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const EMJS_PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

const initialFormData = {
  fullName: "",
  email: "",
  subject: "",
  message: "",
  file: null,
};

const ContactForm = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [ipData, setIpData] = useState("");
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  const form = useRef();

  const ipInfo = GetIPData();

  useEffect(() => {
    if (ipInfo.ip) {
      const ipText = `User Details: IP: ${ipInfo.ip} | Address: ${ipInfo.city}, ${ipInfo.postal}, 
      ${ipInfo.country_name} | Timezone: ${ipInfo.timezone} | Provider: ${ipInfo.org} | ASN: ${ipInfo.asn}`;

      setIpData(ipText);
    }
  }, [ipInfo]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setFormData(initialFormData);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      file,
    });
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      const result = await emailjs.sendForm(
        EMJS_SERVICE_ID,
        EMJS_TEMPLATE_ID,
        form.current,
        EMJS_PUBLIC_KEY
      );

      setSuccessMsg("Message sent successfully!");
      setIsError(false);
      setOpen(true);
      setErrMsg("");
    } catch (error) {
      setErrMsg("An error occurred. Please try again later.");
      setIsError(true);
      setOpen(true);
      setSuccessMsg("");
    }
  };

  return (
    <form className="contact-form" ref={form}>
      <select
        name="title"
        value={formData.title}
        onChange={handleChange}
        className="form-input title"
        required
      >
        {["Title", "Mr.", "Miss", "Mrs.", "Dr.", "Prof."].map((title) => (
          <option key={title} value={title}>
            {title}
          </option>
        ))}
      </select>
      <input
        type="text"
        name="fullName"
        placeholder="Full Name"
        value={formData.fullName}
        onChange={handleChange}
        className="form-input fullname"
        required
      />

      <input
        type="email"
        name="email"
        placeholder="Email"
        value={formData.email}
        onChange={handleChange}
        className="form-input"
        required
      />

      <select
        name="subject"
        value={formData.subject}
        onChange={handleChange}
        className="form-input"
        required
      >
        {[
          "General Inquiry",
          "Reporting Bugs",
          "DAICS Licensing",
          "Collaboration",
          "Financial Support",
          "Others",
        ].map((subject) => (
          <option key={subject} value={subject}>
            {subject}
          </option>
        ))}
      </select>
      <br />
      <textarea
        name="message"
        placeholder="Message"
        value={formData.message}
        onChange={handleChange}
        className="form-input"
        required
      />
      <input
        type="text"
        name="ipdata"
        value={ipData}
        style={{ display: "none" }}
      />

      {}

      <br />
      <input
        type="file"
        name="file"
        onChange={handleFileChange}
        className="file-input"
      />
      <br />
      {formData.fullName !== "" &&
      formData.email !== "" &&
      formData.message !== "" ? (
        <div>
          <Button type="submit" onClick={sendEmail}>
            {}
            Send
          </Button>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            {isError ? (
              <Alert icon={false} severity="error">
                {errMsg}
              </Alert>
            ) : (
              <Alert icon={false} severity="success">
                {successMsg}
              </Alert>
            )}
          </Snackbar>
        </div>
      ) : (
        <Button disabled>Send</Button>
      )}
    </form>
  );
};

export default ContactForm;
