import backgroundImage from "../../assets/img/grapheneWallpaper.jpg";
import { Header, LightFooter } from "../../components/index";

function UserPanel() {
  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          overflowX: "auto",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "repeat",
          backgroundPosition: "center",
        }}
      >
        <Header isPanel={true} />
        <LightFooter positioning={"fixed"} />
      </div>
    </>
  );
}

export default UserPanel;
