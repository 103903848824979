import { useState, useEffect } from "react";
import dayjs from "dayjs";
import useAuth from "./useAuth";
import GetIPData from "../components/UserPanel/GetIPData";
import useAxiosPrivate from "./useAxiosPrivate";
import { formatDate } from "../components/Utils/FormatData";

const URL_PROFILE = process.env.REACT_APP_URL_PROFILE;

function getHighestRole(roles) {
  if (Array.isArray(roles) && roles.length > 0) {
    const roleNames = roles.map((role) => role.name);

    if (roleNames.includes("Premium")) {
      return "Premium";
    } else if (roleNames.includes("Standard")) {
      return "Standard";
    } else {
      return "Basic";
    }
  } else {
    return "Basic";
  }
}

const useGetUserInfo = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [ipData, setIpData] = useState({
    country: "",
    city: "",
  });

  const ipInfo = GetIPData();
  useEffect(() => {
    if (ipInfo.country || ipInfo.city) {
      setIpData({
        country: ipInfo.country,
        city: ipInfo.city,
      });
    }
  }, [ipInfo]);

  const [userData, setUserData] = useState({
    avatar: "",
    username: "",
    email: "",
    instituteEmail: "",
    prefix: "Mr.",
    firstName: "",
    lastName: "",
    number: "",
    dob: null,
    institute: "",
    city: "",
    zip: "",
    country: "",
    website: "",
    subscribe: false,
    joinDated: "",
    lastLogin: "",
    daics_id: "",
    roles: [],
    highestRole: "",
    account_emails: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get(URL_PROFILE);
        const responseData = response.data.data;

        setUserData({
          avatar: responseData.avatar || "",
          username: responseData.username || "",
          email: responseData.email || "",
          instituteEmail: responseData.institutional_email || "",
          prefix: responseData.prefix || "Mr.",
          firstName: responseData.first_name || "",
          lastName: responseData.last_name || "",
          number: responseData.number || "",
          dob: dayjs(responseData.birth_date) || null,
          institute: responseData.institute || "",
          city: responseData.city || ipData.city || "",
          zip: responseData.zip || "",
          country: responseData.country || ipData.country || "",
          website: responseData.website || "",
          subscribe: responseData.is_subscribed || false,
          joinDated: formatDate(responseData.date_joined) || "",
          lastLogin: formatDate(responseData.last_login) || "",
          roles: responseData.roles || [],
          highestRole: getHighestRole(responseData.roles) || "",
          daics_id: responseData.daics_id || "",
          account_emails: responseData.account_emails || [],
        });
      } catch (error) {
        console.error("Error fetching user profile:", error);
        throw error;
      }
    };

    fetchData();
  }, [auth.accessToken, ipData]);

  return userData;
};

export default useGetUserInfo;
