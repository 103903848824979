import { TreeItem } from "@mui/x-tree-view";
import { Typography, Slider, Divider, Box } from "@mui/material";

function SliderTreeItem(props) {
  const {
    handleOpen,
    nodeId,
    label,
    values,
    handleSliderChange,
    onChange,
    unit,
    min,
    max,
    step,
    ...other
  } = props;

  return (
    <TreeItem
      sx={{
        backgroundColor: "#61acc5",
        margin: "1vw auto",
        border: "1px solid #30435f",
        borderRadius: "5px",
        "& .MuiTreeItem-iconContainer svg": {
          fontSize: "26px",
          color: "#EAE7DC !important",
        },
      }}
      nodeId={nodeId}
      label={<Typography>{label}</Typography>}
      onClick={() => handleOpen(nodeId)}
    >
      <Box sx={{ fontSize: 11 }}>
        <Typography>
          {values[0]} to {values[1]}
          {values[1] === max ? "+" : null} {unit}
        </Typography>
        <Slider
          {...other}
          sx={{
            mt: 1,
            width: "85%",
          }}
          size="small"
          getAriaLabel={() => "Minimum distance"}
          value={values}
          onChange={(e, newValue) => handleSliderChange(e, newValue)}
          valueLabelDisplay="auto"
          step={step}
          color="secondary"
          min={min}
          max={max}
        />
        <Divider sx={{ mb: 2 }} />
      </Box>
    </TreeItem>
  );
}

export default SliderTreeItem;
