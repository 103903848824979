import { Grid } from "@mui/material";
import pic_zahed from "../../assets/img/People/pic_zahed.jpg";
import { Footer, Header } from "../../components/index.js";
import "./zxwtstar.css";

const URL_CV = process.env.REACT_APP_URL_RESUME;
const URL_SERVER = process.env.REACT_APP_URL_SERVER;
const Zahed = () => {
  const handleCVDownload = () => {
    window.open(URL_SERVER + URL_CV, "_blank");
  };
  return (
    <>
      <Header />
      <Grid container sx={{ height: "100%" }}>
        <div className="zahed-page">
          <div className="left">
            <div className="picture">
              <img src={pic_zahed} alt="Zahed Allahyari" />
            </div>
            {}
            <h3>Dr. Zahed Allahyari </h3>
            <h4>Computational Materials Scientist</h4>
            <button onClick={handleCVDownload}>Download CV</button>

            <h3>Contact Information</h3>
            <p>
              <b>Address:</b> Berlin, Germany
            </p>
            <p>
              <b>Scientific Network Accounts:</b>
            </p>
            <p>
              <a
                href="https://scholar.google.com/citations?user=0Hv62DAAAAAJ&hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Scholar,
              </a>
              <a
                href="https://github.com/zxwtstar/"
                target="_blank"
                rel="noopener noreferrer"
              >
                GitHub,
              </a>
              <br></br>
              <a
                href="https://www.linkedin.com/in/zxwtstar/"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn,
              </a>
              <a
                href="https://www.researchgate.net/profile/Zahed_Allahyari"
                target="_blank"
                rel="noopener noreferrer"
              >
                ResearchGate
              </a>
            </p>
            <p>
              <b>Email:</b> zahed.allahyari@gmail.com
            </p>
            <p>
              <b>Skype:</b> zxwtstar
            </p>
            <br></br>
            {}
          </div>
          <div className="right">
            <div>
              <h3>About Me</h3>
              <p>
                Zahed's research focuses on the computational exploration of
                materials properties and the prediction of novel materials
                optimized for various technological and industrial applications.
                His previous studies led to the prediction of several hard and
                superhard materials, and new promising hard magnets. His
                extensive work encompasses various materials properties,
                including but not limited to mechanical properties, magnetic
                properties, thermoelectric properties, half-metals, and
                electrides.
              </p>
              <p>
                Zahed is passionate about developing and utilizing
                state-of-the-art computational methodologies and data-driven
                techniques for accelerating materials design and predicting
                novel materials and compounds for next-generation applications.
                The development of the DAICS database stands as a significant
                stride toward achieving this goal.
              </p>
              <p>
                He is also interested in studying disordered materials for
                future research.
              </p>

              <h3>Education & Job Experiences</h3>
              <ul>
                <li>
                  2019 - 2022: Postdoctoral researcher, Skoltech, Moscow, Russia
                </li>
                <li>
                  2016 - Present: Chief Developer of the{" "}
                  <a
                    href="https://uspex-team.org/en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    USPEX
                  </a>
                  code.
                </li>
                <li>
                  2015 - 2017: Fellow researcher, Moscow Institute of Physics
                  and Technology, Moscow, Russia
                </li>
                <li>
                  2015 - 2019: PhD in Material Science & Engineering, Skoltech,
                  Moscow, Russia
                </li>
                <li>
                  2012 - 2014: M.Sc. in Condensed Matter Physics, Isfahan
                  University of Technology, Isfahan, Iran.
                </li>
                <li>
                  2007 - 2012: B.Sc. in Nuclear Physics, Bu-Ali Sina University,
                  Hamedan, Iran.
                </li>
              </ul>
              <div>
                <h3>Publications</h3>
                <ul>
                  <li>
                    <a
                      href="https://doi.org/10.1063/5.0113745"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Koshkaki S. R., Allahyari Z., Oganov A. R., et al.
                      Computational prediction of new magnetic materials. J.
                      Chem. Phys. 157, 124704 (2022).
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://pubs.acs.org/doi/10.1021/acs.jpcc.0c07857"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Allahyari Z., Oganov A. R. Non-empirical definition of
                      Mendeleev numbers: organizing the chemical space. J. Phys.
                      Chem. C, 124, 43, 23867-23878 (2020).
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://doi.org/10.1038/s41524-020-0322-9"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Allahyari Z., Oganov A. R. Coevolutionary search for
                      optimal materials in the space of all possible compounds.
                      Npj Comput. Mater., 6, 55 (2020).
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://doi.org/10.1063/1.5109782"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Kvashnin A.G., Allahyari Z., Oganov A. R. Computational
                      discovery of hard and superhard materials. J. Appl. Phys.,
                      126, 040901 (2019).
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://doi.org/10.1063/1.5037159"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Heydariyan Sh., Nouri M., Alaei M., Allahyari Z., Niehaus
                      T. A. New candidates for the global minimum of
                      medium-sized silicon clusters: A hybrid DFTB/DFT genetic
                      algorithm applied to Sin, n = 8-80. J. Chem. Phys., 149,
                      074313 (2018).
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://doi.org/10.1016/j.cpc.2017.10.001"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Núñez-Valdez M., Allahyari Z., Fan T., Oganov A. R.
                      Efficient technique for computational design of
                      thermoelectric materials. Comput. Phys. Commun., 222, pp
                      152-157 (2018).
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://doi.org/10.1021/acs.jpclett.6b02821"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Kvashnin A. G., Oganov A. R., Samtsevich A. I. , Allahyari
                      Z. Computational Search for Novel Hard Chromium-Based
                      Materials. J. Phys. Chem. Lett., 8 (4), pp 755-764 (2017).
                    </a>
                  </li>
                  <br></br>
                  <li>
                    <a
                      href="https://doi.org/10.1007/978-3-319-44680-6_71"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Allahyari Z., Oganov A. R. Multi-objective optimization as
                      a tool for materials design. In: Andreoni W., Yip S. (eds)
                      Handbook of Materials Modeling. Springer, Cham. (2020).
                      Online ISBN: 978-3-319-44680-6.
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h3>Patents</h3>
                <p>
                  <a href="https://example.com/page1">
                    COMPUTER-IMPLEMENTED CRYSTAL STRUCTURE SEARCH METHOD.
                  </a>
                  <br></br>
                  Pub. No.: WO/2018/009090 (2018).
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Grid>
    </>
  );
};

export default Zahed;
