import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import fetchNews from "../../api/NewsAPI";
import { formatDate } from "../Utils/FormatData";
import ReadingTime from "../Utils/ReadingTime";
import { Header } from "../index";
import SidebarNews from "./SidebarNews";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const IndividualNewsPage = () => {
  const { newsId } = useParams();
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const getNews = async () => {
      const fetchedNews = await fetchNews();
      setNewsData(fetchedNews);
    };

    getNews();
  }, []);

  if (newsData.length === 0) {
    return <Typography variant="h4">Loading...</Typography>;
  }

  if (!newsData.some((news) => news.code === newsId)) {
    return <Typography variant="h4">News not found!</Typography>;
  }

  const selectedNews = newsData.find((news) => news.code === newsId);

  const combinedText = `${selectedNews.content} ${selectedNews.description}`;
  const readtime = ReadingTime(combinedText, 200);

  return (
    <>
      <Header />
      <Box
        sx={{
          margin: "50px auto",
          maxWidth: "1400px",
          boxShadow: "0 0 5px #30435f",
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, sm: 2, md: 3 }}
          columns={{ xs: 4, sm: 4, md: 12 }}
        >
          <Grid item xs={8}>
            <Item>
              <Box>
                <Typography
                  variant="h4"
                  component="h1"
                  gutterBottom
                  sx={{
                    backgroundColor: "#30435f",
                    color: "white",
                    padding: "10px 0px",
                    borderRadius: "5px",
                  }}
                >
                  {selectedNews.title}
                </Typography>
                <Card>
                  {selectedNews.image && (
                    <CardMedia
                      component="img"
                      height="300"
                      image={selectedNews.image}
                      alt={selectedNews.title}
                    />
                  )}

                  <CardContent
                    sx={{
                      textAlign: { xs: "left", md: "justify" },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        justifyContent: "space-between",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        gutterBottom
                      >
                        {formatDate(selectedNews.create)}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        gutterBottom
                      >
                        Reading time: {readtime} minutes
                      </Typography>
                    </Box>
                    <Divider />
                    <Typography variant="body1" gutterBottom sx={{ mt: 1 }}>
                      {selectedNews.description}
                    </Typography>
                    {selectedNews.content && (
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ mt: 2 }}
                        dangerouslySetInnerHTML={{
                          __html: selectedNews.content,
                        }}
                      />
                    )}
                  </CardContent>
                </Card>
              </Box>
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              <Box>
                <SidebarNews newsData={newsData} />
              </Box>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default IndividualNewsPage;
