const ReadingTime = (text, wordsPerMinute = 200) => {
  const calculateReadingTime = (text) => {
    const wordCount = text.split(/\s+/).length;

    const timeInMinutes = Math.ceil(wordCount / wordsPerMinute);

    return timeInMinutes;
  };

  const readingTime = calculateReadingTime(text);

  return readingTime;
};

export default ReadingTime;
