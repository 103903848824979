import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InfoIcon from "@mui/icons-material/Info";
import ApiIcon from "@mui/icons-material/Api";
import SettingsIcon from "@mui/icons-material/Settings";
import DiamondIcon from "@mui/icons-material/Diamond";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import Tooltip from "@mui/material/Tooltip";
import {
  styled,
  useTheme,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { FunctionSubMenu } from "./FunctionButton";
import DashboardContent from "../../pages/UserPanel/DashboardContent";
import MyDetailsContent from "../../pages/UserPanel/MyDetailsContent";
import SettingsContent from "../../pages/UserPanel/SettingsContent";
import SubscriptionsContent from "../../pages/UserPanel/SubscriptionsContent";
import APIContent from "../../pages/UserPanel/APIContent";
import MobileHeader from "./MobileHeader";
import useAuth from "../../hooks/useAuth";
import desktopLogo from "../../assets/logo/LogoTextDAICS.svg";
import mobileLogo from "../../assets/logo/LogoDAICS.png";
import useStyles from "./styles";
import useLogout from "../../hooks/useLogout";

const drawerWidth = "75%";
const LeftdrawerWidth = 260;
const navItems = ["News", "Contact", "About Us"];

const openedMixin = (theme) => ({
  width: LeftdrawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const CustomAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: LeftdrawerWidth,
    width: `calc(100% - ${LeftdrawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const CustomDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: LeftdrawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function Header({ isPanel = false, ...props }) {
  const { auth } = useAuth();
  const logout = useLogout();
  const isLoggedIn = auth?.accessToken && auth.accessToken !== "";

  const { windowProp } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(isLoggedIn);
  const [additioanlLogoStyle, setAdditionalLogoStyle] = useState({});

  const classes = useStyles();
  const customTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 900,
        md: 1100,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [logoSrc, setLogoSrc] = useState(desktopLogo);
  const [selectedTab, setSelectedTab] = useState("Dashboard");
  const navigate = useNavigate();

  useEffect(() => {
    if (isMobileSize && open) {
      setLogoSrc(mobileLogo);
      setAdditionalLogoStyle({
        marginLeft: "0px",
      });
    } else {
      setLogoSrc(desktopLogo);
      setAdditionalLogoStyle({});
    }
  }, [isMobileSize, open]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleSignOut = () => {
    setLoggedIn(false);
    logout().then(() => {
      navigate("/", { replace: true });
    });
  };

  const handleNavigate = (page) => {
    navigate("/" + page.replace(" ", "-").toLowerCase());
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case "Dashboard":
        return <DashboardContent />;
      case "My Details":
        return <MyDetailsContent />;
      case "Subscriptions":
        return <SubscriptionsContent />;
      case "Settings":
        return <SettingsContent />;
      case "API Key":
        return <APIContent />;
      default:
        return null;
    }
  };

  const commonContent = (
    <Toolbar
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {}
        {isPanel && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 0,
              ...(open && { display: "none" }),
            }}
          >
            <DoubleArrowIcon sx={{ color: "white !important" }} />
          </IconButton>
        )}
        {}

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            className={classes.logo}
            src={logoSrc}
            alt="DAICS"
            onClick={(e) => handleNavigate("")}
            style={additioanlLogoStyle}
          />
        </Box>
      </Box>

      <IconButton
        className="MyIconButton"
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerToggle}
        sx={{
          color: "white !important",
          display: { xs: "block", sm: "block", md: "none" },
        }}
      >
        <MenuIcon sx={{ color: "white !important" }} />
      </IconButton>

      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {loggedIn && <FunctionSubMenu />}
          {navItems.map((item) => (
            <Button
              sx={{
                color: "#fff",
                fontSize: "0.9rem",
                ":hover": { color: "orange" },
              }}
              onClick={(e) => handleNavigate(item)}
            >
              {item}
            </Button>
          ))}
          {loggedIn ? (
            <>
              {}
              {!isPanel && (
                <Button
                  sx={{
                    color: "#fff",
                    fontSize: "0.9rem",
                    ":hover": { color: "orange" },
                  }}
                  onClick={(e) => handleNavigate("userpanel")}
                >
                  My Account
                </Button>
              )}
              {}
              <Box className={classes.signButton}>
                <Button
                  sx={{ color: "#fff", fontSize: "0.9rem" }}
                  onClick={handleSignOut}
                >
                  {}
                  Logout
                </Button>
              </Box>
            </>
          ) : (
            <Box className={classes.signButton}>
              <Button
                sx={{ color: "#fff", fontSize: "0.9rem" }}
                onClick={(e) => handleNavigate("login")}
              >
                {}
                Login
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Toolbar>
  );

  const SidebarItems = ({ items, icons, open }) => {
    const GetListItems = ({ text, index, icons, open }) => (
      <>
        <Tooltip title={text} placement="right">
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            selected={selectedTab === text}
            onClick={() => setSelectedTab(text)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              {icons[index]}
            </ListItemIcon>
            <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </Tooltip>
      </>
    );

    return (
      <>
        <Divider />
        <List>
          {items.map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              {open ? (
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  selected={selectedTab === text}
                  onClick={() => setSelectedTab(text)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {icons[index]}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              ) : (
                <GetListItems
                  text={text}
                  index={index}
                  icons={icons}
                  open={open}
                />
              )}
            </ListItem>
          ))}
        </List>
      </>
    );
  };

  const container =
    windowProp !== undefined ? () => windowProp().document.body : undefined;

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ display: "flex", padding: "0", margin: "0" }}>
        <CssBaseline />
        {isPanel ? (
          <CustomAppBar
            component="nav"
            position="fixed"
            open={open}
            className={classes.customheader}
          >
            {commonContent}
          </CustomAppBar>
        ) : (
          <AppBar
            component="nav"
            position="fixed"
            className={classes.customheader}
          >
            {commonContent}
          </AppBar>
        )}

        {}
        {isPanel && (
          <>
            <CustomDrawer variant="permanent" open={open}>
              <DrawerHeader sx={{ height: "120px" }}>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </DrawerHeader>

              <SidebarItems
                items={["Dashboard", "My Details", "Subscriptions", "Settings"]}
                icons={[
                  <DashboardIcon />,
                  <InfoIcon />,
                  <DiamondIcon />,
                  <SettingsIcon />,
                ]}
                open={open}
              />
              <SidebarItems
                items={["API Key"]}
                icons={[<ApiIcon />]}
                open={open}
              />
            </CustomDrawer>
            <Box
              component="main"
              sx={{ flexGrow: 1, p: 3, paddingTop: "50px !important" }}
            >
              <DrawerHeader />
              <Typography>{renderTabContent()}</Typography>
            </Box>
          </>
        )}
        {}

        <nav>
          {}
          <MuiDrawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
              zIndex: 10001,
            }}
          >
            <MobileHeader />
          </MuiDrawer>
          {}
        </nav>
        <Box component="main" sx={{ p: "28px" }}>
          <Toolbar />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Header;
