import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../Utils/FormatData";

const useStyles = makeStyles({
  newsContainer: {
    marginBottom: "10px",
    padding: "10px",
  },
  showMoreButton: {
    color: "#9B2C40",

    "&:hover": {
      textDecoration: "underline",
    },
  },
});

const SidebarNews = ({ newsData }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [displayedNewsCount, setDisplayedNewsCount] = useState({});
  const newsCount = 4;
  const newsCategoryName = (category) => {
    switch (category) {
      case "1":
        return "DAICS Updates";
      case "2":
        return "Events";
      case "3":
        return "General";
      case "4":
        return "Jobs";
      case "5":
        return "Science & Technology";
      default:
        return "Other News";
    }
  };

  const newsByCategory = {};
  newsData.forEach((news) => {
    const { category } = news;
    if (!newsByCategory[category]) {
      newsByCategory[category] = [];
    }
    newsByCategory[category].push(news);
  });

  const limitDescriptionWords = (description, limit) => {
    const words = description.split(" ");
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + " ...";
    }
    return description;
  };

  const showMoreNews = (category) => {
    setDisplayedNewsCount((prevCount) => {
      const currentCount = prevCount[category] || newsCount;
      const newCount = currentCount + newsCount;
      return {
        ...prevCount,
        [category]:
          newCount <= newsByCategory[category].length ? newCount : currentCount,
      };
    });
  };

  const showLessNews = (category) => {
    setDisplayedNewsCount((prevCount) => ({
      ...prevCount,
      [category]: newsCount,
    }));
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          textAlign: "left",
        }}
      >
        <Typography
          variant="h5"
          component="h1"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          Related News
        </Typography>

        {Object.entries(newsByCategory).map(([category, newsArray]) => (
          <div key={category}>
            <Typography
              variant="h5"
              component="h2"
              gutterBottom
              sx={{
                backgroundColor: "yellow",
                color: "black",
                padding: "5px",
              }}
            >
              {newsCategoryName(category)}
            </Typography>
            {newsArray
              .slice(0, displayedNewsCount[category] || newsCount)
              .map((news) => (
                <Box
                  key={news.id}
                  className={classes.newsContainer}
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate(`/news/${news.code}`)}
                >
                  <Typography variant="h6" gutterBottom>
                    {news.title}
                  </Typography>
                  <Typography>
                    {limitDescriptionWords(news.description, 25)} <br />
                    {formatDate(news.create)}
                  </Typography>
                </Box>
              ))}
            {newsArray.length > newsCount && (
              <Button
                className={classes.showMoreButton}
                size="small"
                onClick={() => {
                  if (
                    displayedNewsCount[category] === undefined ||
                    displayedNewsCount[category] === newsCount
                  ) {
                    showMoreNews(category);
                  } else {
                    showLessNews(category);
                  }
                }}
              >
                {displayedNewsCount[category] ===
                newsByCategory[category].length
                  ? "Show less"
                  : "Show more"}
              </Button>
            )}
          </div>
        ))}
      </Box>
    </>
  );
};

export default SidebarNews;
