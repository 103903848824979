import { Button, Tooltip } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useState } from "react";

const pageStyle = {
  backgroundColor: "#eceff3",
  minHeight: "100vh",
  margin: 0,
  fontFamily: "Arial, sans-serif",
};
const preStyle = {
  lineHeight: "1.5",
  paddingTop: 10,
};

const buttonStyle = {
  padding: "7px 15px",
  backgroundColor: "#4CAF50",
  color: "white",
  borderRadius: "5px",
  cursor: "pointer",
  border: "none",
  textTransform: "Capitalize",
};

const experimentalData = `pressure     0.0001               # Experimental pressure in GPa.
start        5.0                  # Start of 2θ angle in degrees.
end         90.0                  # End of 2θ angle in degrees.
wavelength   1.5418               # The wavelength of X-ray radiation source.
fwhm         0.2                  # Full Width at Half Maximum - measure of the width of Gaussian-broadened peaks.
spacing      0.01                 # The resolution of diffraction angles.
base         2.0                  # The base of the triangular weighting function, in degrees.
peaks       20.87         19.93   # Main peaks of the experimental pattern, "2θ angle & Intensities".
            26.65        100.00
            36.56          7.13
            39.49          7.19
            42.47          5.25
            50.17         12.58
            59.98          9.05
            67.78          5.56
            68.19          6.67
`;

const ExampleXRD = () => {
  const [copy, setCopy] = useState(false);

  const handleTooltipClose = () => {
    setCopy(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(experimentalData);
    setCopy(true);
    setTimeout(handleTooltipClose, 2000);
  };

  return (
    <div style={pageStyle}>
      <div style={{ padding: 20 }}>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={copy}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Copied to clipboard"
            >
              <Button style={buttonStyle} onClick={handleCopy}>
                Copy example
              </Button>
            </Tooltip>
          </div>
        </ClickAwayListener>
        <pre style={preStyle}>{experimentalData}</pre>
      </div>
    </div>
  );
};

export default ExampleXRD;
