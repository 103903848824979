import {
  Box,
  Button,
  Container,
  CssBaseline,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../api/newAuth";
import backgroundImage from "../../assets/img/grapheneWallpaper.jpg";
import LightFooter from "../../components/Footer/LightFooter";

const theme = createTheme();

function ResetPassword() {
  const navigate = useNavigate();
  const { uid, token } = useParams();
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const pass1 = formData.get("password1");
    const pass2 = formData.get("password2");

    try {
      const response = await resetPassword(uid, token, pass1, pass2);

      setMessage("Password changed successfully.");
      setSuccess(true);
      setError(false);
    } catch (error) {
      setMessage("Failed to reset the password.");
      setError(true);
      setSuccess(false);
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              borderRadius: 4,
              marginTop: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 1,
              boxShadow: 4,
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            }}
          >
            {isError && (
              <Typography variant="h6" color="error">
                {message}
              </Typography>
            )}
            {isSuccess ? (
              <>
                <Typography variant="h6" color="green">
                  {message}
                </Typography>

                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => navigate("/login")}
                  sx={{ mt: 2, mb: 2 }}
                >
                  Login
                </Button>
              </>
            ) : (
              <>
                <Typography component="h1" variant="h6" sx={{ width: "90%" }}>
                  Reset Your Password
                </Typography>

                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{
                    mt: 1,
                    width: "90%",
                  }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password1"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    variant="filled"
                    sx={{
                      py: 1,
                      padding: "18px 0px 0px 0px",
                      margin: "0px 0px",
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password2"
                    label="Repeat Password"
                    type="password"
                    id="password2"
                    autoComplete="new-password"
                    variant="filled"
                    sx={{
                      py: 1,
                      padding: "18px 0px 0px 0px",
                      margin: "0px 0px",
                    }}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                  >
                    Confirm
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Container>
      </ThemeProvider>
      <LightFooter positioning={"fixed"} />
    </div>
  );
}

export default ResetPassword;
