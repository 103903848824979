import { Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { TreeItem } from "@mui/x-tree-view";

function CrystalSystems(props) {
  const {
    handleOpen,
    nodeId,
    label,
    values,
    handleOnChange,
    MenuProps,
    names,
  } = props;

  return (
    <TreeItem
      sx={{
        backgroundColor: "#61acc5",
        margin: "1vw auto",
        border: "1px solid  #30435f",
        borderRadius: "5px",
        "& .MuiTreeItem-iconContainer svg": {
          fontSize: "26px",
          color: "#EAE7DC !important",
        },
      }}
      nodeId={nodeId}
      label={<Typography>{label}</Typography>}
      onClick={() => handleOpen(nodeId)}
    >
      <FormControl sx={{ m: 1, width: 170 }} size="small">
        <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>

        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={values}
          onChange={handleOnChange}
          input={<OutlinedInput label="Crystal Systems" />}
          renderValue={(selected) => selected.length}
          MenuProps={MenuProps}
          defaultValue="asd"
        >
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={values.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Divider sx={{ mb: 2 }} />
    </TreeItem>
  );
}

export default CrystalSystems;
