import { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { forgotPassword } from "../../api/newAuth";
import LightFooter from "../../components/Footer/LightFooter";
import backgroundImage from "../../assets/img/grapheneWallpaper.jpg";

const theme = createTheme();

function ForgotPassword() {
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    forgotPassword(data.get("email"))
      .then((response) => {
        if (response.status === 200) {
          setMessage(response.data.detail);
          handleOpen();
        } else {
          setMessage(response.detail);
          setError(true);
        }
      })
      .catch((e) => {
        setError(true);
        setMessage(e.response.detail);
      });
  };
  const handleNavigate = () => {
    handleClose();
  };

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                borderRadius: 4,
                marginTop: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 1,
                boxShadow: 4,
                backgroundColor: "rgba(255, 255, 255, 0.9)",
              }}
            >
              <Typography component="h1" variant="h6" sx={{ width: "90%" }}>
                Please enter your email address:
              </Typography>
              {isError ? (
                <Typography component="h1" variant="h6">
                  {message}
                </Typography>
              ) : null}
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{
                  mt: 1,
                  width: "90%",
                }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="email"
                  label="Email"
                  type="email"
                  id="email"
                  autoComplete="current-email"
                  variant="standard"
                  autoFocus
                  sx={{
                    py: 1,
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 1, mb: 2 }}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                button: 0,
              }}
            >
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    borderRadius: 5,
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <Typography component="h1" variant="h6">
                    {message}
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Please check yor Email and confirm.
                  </Typography>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleNavigate}
                  >
                    OK
                  </Button>
                </Box>
              </Modal>
            </Box>
          </Container>
        </ThemeProvider>
        <LightFooter positioning={"fixed"} />
      </div>
    </>
  );
}

export default ForgotPassword;
