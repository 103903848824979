import { Typography, Slider, Divider } from "@mui/material";

function SliderItem(props) {
  const { label, values, handleSliderChange, min, max, step } = props;

  return (
    <>
      <Typography>{label}</Typography>
      <Typography>
        {values[0]} to {values[1]}
        {values[1] === max ? "+" : null}
      </Typography>
      <Slider
        sx={{
          mt: 1,
          width: "85%",
        }}
        size="small"
        getAriaLabel={() => "Minimum distance"}
        value={values}
        onChange={(e, newValue) => handleSliderChange(e, newValue)}
        valueLabelDisplay="auto"
        step={step}
        color="secondary"
        min={min}
        max={max}
      />
      <Divider sx={{ mb: 2 }} />
    </>
  );
}

export default SliderItem;
