import { useState } from "react";
import { Footer, Header, Loading } from "../../components/index.js";

import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputBase,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import periodicTable from "../../assets/icon/PeriodicTable.png";
import PhaseDiagramChart from "../../components/Charts/PhaseDiagram.js";
import Element from "../../components/Element/Element";
import { elements } from "../../data/_data";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
function PhaseDiagram() {
  const [e_hull_limit, setEHL] = useState("");
  const [selectedElements, setSelectedElements] = useState("");
  const [acitveElements, setActiveElement] = useState({});
  const [elementNumbers, setElementNumbers] = useState(new Set());
  const [expanded, setExpanded] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [PData, setPData] = useState(false);

  const URL_PHASE_DIAGRAM_DATA = process.env.REACT_APP_URL_PHASE_DIAGRAM_DATA;

  const axiosPrivate = useAxiosPrivate();
  async function getPhaseDiagram(params) {
    const response = await axiosPrivate.get(URL_PHASE_DIAGRAM_DATA, {
      params: params,
    });
    return response;
  }

  const handelEHL = (event) => {
    setEHL(event.target.value);
  };

  const sendReq = (event) => {
    const params = {
      e_hull_limit: e_hull_limit !== "" ? e_hull_limit : 0.5,
      systems: selectedElements,
    };
    setIsLoading(true);
    setPData(false);
    executeScroll();
    setExpanded([]);
    getPhaseDiagram(params)
      .then((response) => {
        if (response.status === 200) {
          setPData(response.data.result);
          setIsLoading(false);
          setLoaded(true);
          executeScroll();
        }
      })
      .catch((e) => {
        setMessage(e.response);
        setError(true);
        setIsLoading(false);
        setLoaded(true);
      });
  };
  const executeScroll = () => {
    var element = document.getElementById("foooter");
    element.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const handleSearch = (e) => {
    setSelectedElements(e.target.value);
  };

  const re = /[^!:^()]*\w\s/g;
  const addElement = (num) => {
    if (122 > Number(num) && Number(num) > 97) {
    } else {
      if (selectedElements) {
        let s = [...selectedElements.matchAll(re)];
        if (s.length > 0) {
          if (
            String([...selectedElements.matchAll(re)].slice(-1)).includes(
              elements[num].symbol + " "
            )
          ) {
            s = String(s[0]);
            s = s.replace(", " + elements[num].symbol + " ", "");
            s = s.replace(" " + elements[num].symbol + " ,", "");
            s = s.replace(elements[num].symbol + " ", "");
            if (s.startsWith(",")) {
              s = s.slice(2);
            }
            setSelectedElements(s);
          } else {
            let s = [...selectedElements.matchAll(re)];
            s = s + ", " + elements[num].symbol + " ";
            setSelectedElements(s);
          }
        } else {
          let s = [...selectedElements.matchAll(re)];
          s = s + ", " + elements[num].symbol + " ";
          setSelectedElements(s);
        }
      } else {
        setSelectedElements(elements[num].symbol + " ");
      }
    }
  };

  const populateElements = (start, end) => {
    let items = [];
    for (let i = start; i <= end; i++) {
      items.push(
        <Element
          addElement={addElement}
          num={i}
          category={acitveElements}
          selectedElements={selectedElements}
          elementNumbers={elementNumbers}
        />
      );
    }
    return items;
  };
  const handleToggle = (event) => {
    expanded.length === 0 ? setExpanded(["1"]) : setExpanded([]);
  };

  return (
    <Grid
      container
      sx={{
        minHeight: "100%",
      }}
    >
      <Header />
      {}
      <Grid xs={12} lg={12}>
        <Card
          sx={{
            m: { xs: 1, md: 3 },
            pb: 3,
          }}
          variant="outlined"
        >
          <Box
            component="form"
            sx={{
              display: { xs: "inherit", md: "flex" },
              m: 3,
            }}
          >
            <Typography variant="h6" component="h6">
              Maximum Energy Above Hull (eV/atom):
            </Typography>

            <TextField
              sx={{
                mx: { xs: 1, md: 3 },
                minWidth: 120,
                width: "min-content",
              }}
              value={e_hull_limit}
              onChange={handelEHL}
              displayEmpty
              inputProps={{ "aria-label": "Maximum Energy Above Hull" }}
              id="outlined-number"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                width: { xs: "90%", md: "95%" },
                mx: 1,
                justifyContent: "space-around",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: { xs: "100%", md: "85%" },
                  mx: 1,
                }}
              >
                <Typography variant="h6" component="h6">
                  Phase Diagram:
                </Typography>
                <InputBase
                  variant="outlined"
                  sx={{
                    backgroundColor: "#ffffff",
                    border: "2px solid #808080",
                    borderRadius: "15px",
                    height: "2em",
                    px: 1,
                    mx: 1,
                  }}
                  value={selectedElements}
                  onChange={handleSearch}
                />
              </Box>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: "10px !important",
                }}
              >
                <IconButton
                  color="primary"
                  aria-label="periodicTable"
                  component="label"
                  variant="contained"
                  onClick={(e) => handleToggle(e)}
                  sx={{
                    mt: "13px !important",
                  }}
                >
                  <img
                    src={periodicTable}
                    width={"auto"}
                    height={"50"}
                    alt="periodicTable"
                  />
                </IconButton>
                <Button
                  color="success"
                  variant="contained"
                  size="small"
                  onClick={(e) => sendReq(e)}
                  sx={{
                    mt: "13px !important",
                    minWidth: "80px",
                  }}
                >
                  generate
                </Button>
              </Stack>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TreeView
              sx={{
                marginLeft: "-17px !important",
              }}
              expanded={expanded}
            >
              <TreeItem className="results-tree--item" nodeId="1" label="">
                <Card
                  sx={{
                    p: 3,
                  }}
                  variant="outlined"
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xs: "repeat(17,4.1vw) 1fr",
                        md: "repeat(17, 4.1vw) 1fr",
                      },
                      gridColumnGap: { xs: "1px", md: "2px" },
                      gridRowGap: { xs: "1px", md: "2px" },
                      height: "auto",
                      margin: "auto 0",
                      mx: { xs: "1vw", md: "1vw" },
                      mt: { xs: "2vw", md: "0" },
                      width: "95%",
                    }}
                  >
                    {populateElements(1, 4)}
                    {populateElements(5, 56)}
                    {populateElements(120, 120)}
                    {populateElements(72, 88)}
                    {populateElements(121, 121)}
                    {populateElements(104, 118)}
                    {populateElements(57, 71)}
                    {populateElements(89, 103)}
                  </Box>
                </Card>
              </TreeItem>
            </TreeView>
            {}
          </Box>
        </Card>
      </Grid>
      {}
      <>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-around",
              m: { xs: 1, md: 3 },
            }}
          >
            <Loading type={"spinningBubbles"} color="#4A5073" />
          </Box>
        ) : loaded ? (
          <Card
            variant="outlined"
            sx={{
              display: "flex",
              width: "100%",
              pt: 4,
              justifyContent: "space-around",
              mx: { xs: 1, md: 3 },
              mb: 1,
            }}
          >
            <PhaseDiagramChart data={PData} labels={selectedElements} />
          </Card>
        ) : null}
      </>
      <Footer />
      <div id="foooter"></div>
    </Grid>
  );
}

export default PhaseDiagram;
