import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

function HandleGoogle() {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();

  const { key } = useParams();

  useEffect(() => {
    setAuth({ accessToken: key });

    navigate("/");
  }, [key]);
  return <></>;
}

export default HandleGoogle;
