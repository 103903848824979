import {
  Box,
  Card,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Slider,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import { useState } from "react";
import ParetoRanking from "../../components/Charts/ParetoRanking.js";
import ParetoDes from "../../components/Pareto/ParetoDes.js";
import { Footer, Header, Loading } from "../../components/index.js";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const URL_PARETO_DATA = process.env.REACT_APP_URL_PARETO_DATA;

function Pareto() {
  const [propertyX, setPropertyX] = useState("");
  const [propertyY, setPropertyY] = useState("");
  const [systems, setSystems] = useState("");
  const [Xminmax, setXMinMax] = useState("");
  const [Yminmax, setYMinMax] = useState("");
  const [ParetoData, setParetoData] = useState(false);
  const [EL, setEL] = useState([0, 1]);
  const [ELR, setELR] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isError, setError] = useState(false);
  const [errMsg, setErrorMsg] = useState("");
  const [message, setMessage] = useState("");

  const axiosPrivate = useAxiosPrivate();
  async function getPareto(params) {
    try {
      const response = await axiosPrivate.get(URL_PARETO_DATA, {
        params: params,
      });
      setError(false);
      return response;
    } catch (error) {
      setError(true);
      setErrorMsg(error.response.data.detail);
    }
  }

  const handlePropertyX = (event) => {
    setPropertyX(event.target.value);
  };
  const handleSystems = (event) => {
    setSystems(event.target.value);
  };
  const handlePropertyY = (event) => {
    setPropertyY(event.target.value);
  };
  const handleXMinMax = (event) => {
    setXMinMax(event.target.value);
  };
  const handleYMinMax = (event) => {
    setYMinMax(event.target.value);
  };
  const sendReq = (event) => {
    event.preventDefault();
    executeScroll();
    setParetoData(false);
    setError(false);
    const params = {
      p1: propertyX,
      p2: propertyY,
      opt_type1: Xminmax,
      opt_type2: Yminmax,
      systems: systems,
    };
    if (ELR) {
      params.e_hull_limit = EL[1];
    }
    params.e_hull_limit = EL[1];
    setIsLoading(true);
    getPareto(params)
      .then((response) => {
        if (response.status === 200) {
          setParetoData(response.data.result);
          setIsLoading(false);
          setLoaded(true);
          executeScroll();
        }
      })
      .catch((e) => {
        setMessage(e.response);
        setErrorMsg(e.response.data.detail);
        setError(true);
      });
  };
  const executeScroll = () => {
    var element = document.getElementById("foooter");
    element.scrollIntoView({ behavior: "smooth", block: "end" });
  };
  const handleEL = (event, newEL) => {
    setEL([0, newEL[1]]);
    if (ELR < 1.5) {
      setELR(true);
    } else {
      setELR(false);
    }
  };

  const materialsPropertyData = [
    { value: "none", name: "None" },
    { value: "hardness", name: "Hardness" },
    { value: "electrides", name: "Electrides" },
    { value: "atomization_energy", name: "Atomization energy" },
    { value: "e_hull", name: "Energy above Hull" },
    { value: "enthalpy_of_formation", name: "Enthalpy of formation" },
    { value: "magnetization", name: "Magnetization" },
    { value: "density", name: "Density" },
    { value: "ml_bulk_modulus", name: "ML Bulk Modulus" },
    { value: "ml_shear_modulus", name: "ML Shear Modulus" },
    { value: "ml_young_modulus", name: "ML Young Modulus" },
    { value: "ml_pugh_modulus", name: "ML Pugh Modulus" },
    { value: "ml_possions_ratio", name: "ML Possions ratio" },
    { value: "ml_vickers_hardness", name: "ML Vickers hardness" },
    { value: "ml_fracture_toughness", name: "ML Fracture toughness" },
  ];
  return (
    <Grid
      container
      sx={{
        minHeight: "100%",
      }}
    >
      <Header />

      <Grid xs={12} lg={12}>
        <ParetoDes />
        {}
        <Card sx={{ mt: 5, m: { xs: 1, md: 3 } }} variant="outlined">
          {}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <FormControl sx={{ m: 3, minWidth: 120 }}>
                <Typography sx={{ mx: 3 }} variant="h6" component="h6">
                  Property X
                </Typography>

                <Select
                  value={propertyX}
                  onChange={handlePropertyX}
                  displayEmpty
                  inputProps={{ "aria-label": "Order Of elements" }}
                  sx={{ minWidth: 120 }}
                >
                  {Object.entries(materialsPropertyData).map(
                    ([index, item]) => (
                      <MenuItem key={index} value={item.value}>
                        {item.name}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 3, minWidth: 120 }}>
                <Typography variant="h6" component="h6">
                  Optimization Type X
                </Typography>
                <Select
                  value={Xminmax}
                  onChange={handleXMinMax}
                  displayEmpty
                  inputProps={{ "aria-label": "Order Of elements" }}
                >
                  <MenuItem value={"MIN"}>Min </MenuItem>
                  <MenuItem value={"MAX"}>Max </MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ m: 3, minWidth: 120 }}>
                <Typography sx={{ mx: 3 }} variant="h6" component="h6">
                  Property Y
                </Typography>

                <Select
                  value={propertyY}
                  onChange={handlePropertyY}
                  displayEmpty
                  inputProps={{ "aria-label": "Order Of elements" }}
                  sx={{ minWidth: 120 }}
                >
                  {Object.entries(materialsPropertyData).map(
                    ([index, item]) => (
                      <MenuItem key={index} value={item.value}>
                        {item.name}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>

              <FormControl sx={{ m: 3, minWidth: 120 }}>
                <Typography variant="h6" component="h6">
                  Optimization Type Y
                </Typography>
                <Select
                  value={Yminmax}
                  onChange={handleYMinMax}
                  displayEmpty
                  inputProps={{ "aria-label": "Order Of elements" }}
                >
                  <MenuItem value={"MIN"}>Min </MenuItem>
                  <MenuItem value={"MAX"}>Max </MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ m: 3, minWidth: 120 }}>
                <Typography variant="h6" component="h6">
                  Energy Limit (eV/atom)
                </Typography>
                <Typography>
                  {" "}
                  {EL[0]} to {EL[1]}
                </Typography>
                <Slider
                  sx={{
                    mt: 1,
                    width: "85%",
                  }}
                  size="small"
                  getAriaLabel={() => "Minimum distance"}
                  value={EL}
                  onChange={handleEL}
                  valueLabelDisplay="auto"
                  step={0.05}
                  color="secondary"
                  min={0}
                  max={1}
                />
              </FormControl>
            </Box>
          </Box>
          {}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              m: 2,
            }}
          >
            <Typography sx={{ mx: 3 }} variant="h6" component="h6">
              System search
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <InputBase
                variant="outlined"
                sx={{
                  mx: 1,
                  width: "100%",
                  backgroundColor: "#ffffff",
                  border: "2px solid #808080",
                  borderRadius: "15px",
                  height: "2em",
                  px: 1,
                }}
                value={systems}
                onChange={handleSystems}
                placeholder={"Systems e.g. Mn-H, Si-C, B,"}
              />
              <Button
                color="success"
                variant="contained"
                onClick={(e) => sendReq(e)}
                sx={{
                  ml: 3,
                  minWidth: "90px",
                }}
              >
                Generate
              </Button>
            </Box>
          </Box>
        </Card>
        <>
          {isError ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                m: { xs: 1, md: 3 },
              }}
            >
              <Typography variant="body1" color="error">
                {errMsg}
              </Typography>
            </Box>
          ) : isLoading ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                m: { xs: 1, md: 3 },
              }}
            >
              <Loading type={"spinningBubbles"} color="#4A5073" />
            </Box>
          ) : loaded ? (
            <Card
              variant="outlined"
              sx={{
                display: "flex",
                py: 4,
                justifyContent: "space-around",
                m: { xs: 1, md: 3 },
              }}
            >
              <ParetoRanking
                data={ParetoData}
                x_label={propertyX}
                y_label={propertyY}
              />
            </Card>
          ) : null}
        </>
      </Grid>

      <Footer />
      <div id="foooter"></div>
    </Grid>
  );
}

export default Pareto;
