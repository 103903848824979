import { Box, Button, Container, CssBaseline, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sendEmailToken } from "../../api/newAuth";
import useAuth from "../../hooks/useAuth";

const theme = createTheme();

function EmailConfirm() {
  const navigate = useNavigate();
  const { token } = useParams();
  const { auth } = useAuth();
  const [isError, setError] = useState(false);

  useEffect(() => {
    sendEmailToken(token).then((response) => {
      if (response.detail === "ok") {
        setError(false);
      } else {
        setError(true);
      }
    });
  }, [token]);

  const handleLoginPage = (event) => {
    event.preventDefault();
    navigate("/login");
  };

  const handleDashboard = (event) => {
    event.preventDefault();
    navigate("/userpanel");
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            high: "90%",
          }}
        >
          {!isError ? (
            <Box>
              <Typography component="h1" variant="h6">
                Your Email is verified successfully!
              </Typography>
              {!auth.accessToken ? (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={handleLoginPage}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Go to Sign In Page
                </Button>
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={handleDashboard}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Go to Dashboard
                </Button>
              )}
            </Box>
          ) : (
            <Typography component="h1" variant="h6">
              Email is not verified, please try again.
            </Typography>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default EmailConfirm;
