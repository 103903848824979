import { axiosPrivate } from "../api/axios";
import useAuth from "./useAuth";

const URL_LOGOUT = process.env.REACT_APP_URL_LOGOUT;

const useLogout = () => {
  const { auth, setAuth } = useAuth();

  const logout = async () => {
    try {
      await axiosPrivate.post(URL_LOGOUT, null, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      });
      setAuth({});
      localStorage.removeItem("daicsUser");
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return logout;
};

export default useLogout;
