import {
  Card,
  CardContent,
  CardMedia,
  CssBaseline,
  Grid,
  Typography,
} from "@mui/material";
import { Footer, Header } from "../../components/index.js";

import { Link } from "react-router-dom";
import pic_artem from "../../assets/img/People/pic_artem.jpg";
import pic_omid from "../../assets/img/People/pic_omid.jpg";
import pic_zahed from "../../assets/img/People/pic_zahed.jpg";
import useStyles from "./Styles.js";

const AboutUs = () => {
  const classes = useStyles();

  return (
    <>
      <Header />
      <Grid container sx={{ height: "100%" }}>
        <div>
          <CssBaseline />
          <container className={classes.banner}>
            <Typography
              className={classes.textbanner}
              variant="body1"
              sx={{ textAlign: { xs: "left", md: "justify" } }}
            >
              <b>DAICS (Database of Ab Initio Crystal Structures)</b> is a
              collaborative project initiated by{" "}
              <Link to="/zxwtstar" style={{ color: "inherit" }}>
                Dr. Zahed Allahyari
              </Link>
              , and, developed with the valuable guidance and support of{" "}
              <a
                href="https://uspex-team.org/en/oganov/about"
                style={{ color: "inherit" }}
              >
                Prof. Artem R. Oganov
              </a>
              . Our mission is clear: to make the complex world of
              crystallography accessible to everyone while also driving
              innovation in materials science. Through dedicated effort, we've
              built a comprehensive database of crystal structures, covering a
              wide range of materials. Currently, we've made significant
              progress by collecting data for unary systems and over 85% of
              binary systems, and this is just the beginning of our journey. As
              we move forward, our commitment extends to gathering data for
              ternary and more complex systems, and calculate various material
              properties for each crystal structure within DAICS. Additionally,
              we are devoted to creating user-friendly functions to expedite
              materials discovery. Our vision is to establish a substantial,
              accurate, and invaluable database that plays a pivotal role in
              applying data-driven AI techniques within the field of materials
              science.
              <br />
              As we continue on this journey, our focus remains on providing
              researchers and scientific community with a comprehensive and
              carefully assembled repository of ab initio crystal structures. By
              doing so, we aim to drive advancements in materials science, and
              related fields by enhancing accessibility to and utilization of
              high-quality crystallographic data.
            </Typography>
          </container>

          <container className={classes.sectiontopic}>
            <h3 className={classes.textsectiontopic}>Authors</h3>
          </container>

          <container className={classes.cardGrid}>
            <Grid container spacing={20} justifyContent="center">
              <Grid item>
                <Link
                  to={"/zxwtstar"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.cardLink}
                >
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      component="img"
                      image={pic_zahed}
                      title="Zahed Allahyari"
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography variant="h5" gutterBottom>
                        Founder & Developer
                      </Typography>
                      <Typography variant="h6">Dr. Zahed Allahyari</Typography>
                      <Typography>
                        Research Associate, Computational Materials Design
                        Laboratory
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>

              <Grid item>
                <a
                  href="https://uspex-team.org/en/oganov/about"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.cardLink}
                >
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      component="img"
                      image={pic_artem}
                      title="Artem R. Oganov"
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography variant="h5" gutterBottom>
                        Co-author & Supervisor
                      </Typography>
                      <Typography variant="h6">
                        Prof. Artem R. Oganov
                      </Typography>
                      <Typography>
                        Full Professor, Head of the Material Discovery
                        Laboratory, Skoltech, Moscow
                      </Typography>
                    </CardContent>
                  </Card>
                </a>
              </Grid>
            </Grid>
          </container>

          <container className={classes.sectiontopic}>
            <h3 className={classes.textsectiontopic}>Computing Science</h3>
          </container>

          <container className={classes.cardGrid}>
            <Grid container spacing={10} justifyContent="center">
              <Grid item>
                <Card className={classes.cardsecondary}>
                  <CardMedia
                    className={classes.cardMedia}
                    component="img"
                    image={pic_omid}
                    title="Omid"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography variant="h6" gutterBottom>
                      Full Stack Developer
                    </Typography>
                    <Typography variant="h6">Omid Nateghi</Typography>
                    <Typography>
                      Computer Scientist Razi University, Kermanshah
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              {}
            </Grid>
          </container>
        </div>
        <Footer />
      </Grid>
    </>
  );
};

export default AboutUs;
