import {
  Box,
  Button,
  Container,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const URL_PROFILE = process.env.REACT_APP_URL_PROFILE;
const URL_CREATE_API_KEY = process.env.REACT_APP_URL_CREATE_API_KEY;
const URL_REVOKE_API_KEY = process.env.REACT_APP_URL_REVOKE_API_KEY;

const APIContent = () => {
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [hashKey, setHashKey] = useState(false);
  const [key, setKey] = useState(false);
  const [createdDate, setCreatedDate] = useState(false);
  const [expireDate, setExpireDate] = useState(false);
  const [apiError, setApiError] = useState(null);
  const axiosPrivate = useAxiosPrivate();

  async function getProfile() {
    return await axiosPrivate.get(URL_PROFILE).then((response) => {
      return response;
    });
  }

  async function createAPIKey() {
    try {
      const response = await axiosPrivate.post(URL_CREATE_API_KEY);
      return response;
    } catch (error) {
      setMessage(
        error.response.data.detail ||
          "Unable to create API Key. Please try again."
      );
      throw error;
    }
  }

  async function revokeAPIKey() {
    try {
      const response = await axiosPrivate.post(URL_REVOKE_API_KEY);
      return response;
    } catch (error) {
      setMessage(
        error.response.data.detail ||
          "Unable to revoke API Key. Please try again."
      );
      throw error;
    }
  }

  useEffect(() => {
    getProfile().then((response) => {
      if (response.status === 200 && response.data.data.api_key) {
        setHashKey(response.data.data.api_key.hashed_key);
        setCreatedDate(response.data.data.api_key.created);
        setExpireDate(response.data.data.api_key.expiry_date);
      } else {
        setError(true);
      }
    });
  }, []);

  const convertDateToStr = (dateObj, fmt) => {
    return moment(dateObj).format(fmt);
  };

  const handleClose = (event) => {
    event.preventDefault();
    navigator.clipboard.writeText(key);
    setOpen(false);
  };

  const onCreate = async (event) => {
    event.preventDefault();
    try {
      const response = await createAPIKey();
      if (response.status === 201) {
        setMessage(
          "Your API Key has been created successfully. Please Note that this Key will not be shown to you again. please keep it safe."
        );
        setKey(response.data.data["API-KEY"]);
        setCreatedDate(response.data.data.created);
        setExpireDate(response.data.data.expiry_date);
        setOpen(true);
        setApiError(null);
      }
    } catch (error) {
      setApiError(
        error.response?.data?.detail ||
          "Unable to create API Key. Please try again."
      );
    }
  };

  const onRevoke = async (event) => {
    event.preventDefault();
    try {
      const response = await revokeAPIKey();
      if (response.status === 201) {
        setMessage(
          "Your API Key has been revoked successfully. Please Note that this Key will not be shown to you again. please keep it safe."
        );
        setKey(response.data.data["API-KEY"]);
        setCreatedDate(false);
        setExpireDate(response.data.data.expire_date);
        setOpen(true);
        setApiError(null);
      }
    } catch (error) {
      setApiError(
        error.response?.data?.detail ||
          "Unable to revoke API Key. Please try again."
      );
    }
  };

  return (
    <div>
      <Container
        sx={{
          mt: 12,
          paddingBottom: 3,
          minWidth: "400px",
          border: "1px solid black",
          borderRadius: "20px",
          backgroundColor: "rgba(255, 255, 255, 0.94)",
        }}
        component="main"
      >
        <Typography
          component="h1"
          variant="h6"
          sx={{
            mt: 3,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Get your API key
        </Typography>
        <Box
          sx={{
            marginTop: 2,
            marginBottom: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Box component="form" noValidate sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: {
                        xs: "column",
                        md: "column",
                      },
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {key ? (
                      <TextField
                        required
                        fullWidth
                        id="APIKey"
                        label="API Key"
                        name="APIKey"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={key}
                      />
                    ) : (
                      <TextField
                        required
                        fullWidth
                        id="APIKey"
                        label="API Key"
                        name="APIKey"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: {
                          xs: "column",
                          md: "row",
                        },
                        justifyContent: "center",
                        mt: 2,
                      }}
                    >
                      {createdDate ? (
                        <Button
                          type="submit"
                          variant="contained"
                          onClick={onRevoke}
                        >
                          Revoke API Key
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          onClick={onCreate}
                        >
                          Create API KEy
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {apiError && (
          <Typography
            color="error"
            sx={{
              mt: 2,
              mb: 2,
              fontSize: 18,
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            {apiError}
          </Typography>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "800px",
              minWidth: "300px",
              bgcolor: "background.paper",
              border: "2px solid #000",
              borderRadius: 5,
              boxShadow: 24,
              p: 2,
              textAlign: "center",
            }}
          >
            {isError ? (
              <Typography component="h1" variant="h6">
                {message.user && typeof message.user === "object"
                  ? Object.keys(message.user).map((onKey, i) => (
                      <Typography
                        component="h1"
                        display="block"
                        variant="body2"
                        key={i}
                      >
                        {onKey}: {message.user[onKey][0]}
                      </Typography>
                    ))
                  : "Error: User data is missing or not an object"}
              </Typography>
            ) : (
              <>
                <Typography
                  component="div"
                  variant="body1"
                  sx={{
                    textAlign: "start",
                    mx: 1,
                    minHeight: "200px",
                    overflowY: "auto",
                    mb: "15px",
                  }}
                >
                  {message}
                  <br />
                  <br />
                  <div style={{ overflow: "auto" }}>API Key: {key}</div>
                  <br />
                  Expire at:{" "}
                  {convertDateToStr(expireDate, " YYYY-MM-DD, hh:mm")}
                </Typography>
                <Button type="submit" variant="contained" onClick={handleClose}>
                  Copy API Key to Clipboard
                </Button>
              </>
            )}
          </Box>
        </Modal>
      </Container>
    </div>
  );
};

export default APIContent;
