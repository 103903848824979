import ChangeEmail from "../../components/UserPanel/Settings/ChangeEmail";
import ChangePassword from "../../components/UserPanel/Settings/ChangePassword";

const SettingsContent = () => {
  return (
    <div>
      <ChangeEmail />
      <ChangePassword />
    </div>
  );
};

export default SettingsContent;
