import { useEffect } from "react";

import Button from "../../UI/Button";
import "../../UI/Button.css";

import { Box, Container, Typography } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import bgImage from "../../../assets/img/crystalWallpaper.jpg";
const Banner = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <Container
      sx={{
        width: "100%",
        padding: "0 !important",
        margin: "0 !important",
        maxWidth: "unset !important",
        position: "inherit",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center;",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Box sx={{}} className="header-left" data-aos="fade-right">
          <Typography
            href="/database"
            sx={{
              fontSize: { xs: "2.5rem", md: "4rem" },
              lineHeight: "1.4",
              textTransform: "uppercase",
              position: "relative",
              paddingBottom: "2rem",
              marginTop: "120px",
              textShadow: "2px 2px gray",
              color: "white",
            }}
          >
            Database of Ab Initio Crystal Structures
          </Typography>
          <Box
            sx={{
              backgroundColor: "white",
              width: { xs: "90%", md: "70%" },
              margin: "-2rem auto",
              paddingBottom: "2rem",
              boxShadow: "0px 0px 6px 0px grey",
            }}
          >
            <Typography
              sx={{
                textAlign: { xs: "left", sm: "justify" },
                textJustify: "inter-word",
                pt: { xs: "2rem", md: "2rem" },
                position: "relative",
                margin: { xs: "3rem auto", md: "3rem auto" },
                mt: { xs: "2rem", md: "10rem" },
                width: "80%",
              }}
            >
              {}
              The Database of Ab Initio Crystal Structures (DAICS) encompasses a
              vast collection of both theoretical and experimental crystal
              structures. These structures have been calculated using Density
              Functional Theory (DFT), with the VASP code. This database serves
              as an excellent resource for employing cutting-edge machine
              learning and neural network methodologies, such as Generative
              Adversarial Networks (GANs), with the aim of expediting the
              exploration and identification of new materials.
            </Typography>
            <Box>
              <Button
                text={"Search Compounds "}
                btnClass={"btn-light"}
                href={"database"}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Banner;
