import * as echarts from "echarts";
import { useEffect, useRef } from "react";
const PhaseDiagramChart = ({ data, labels }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = echarts.init(chartRef.current);

    const scaterNormal = [];
    const scater_1 = [];
    const scater_tooltip = [];

    let A = labels.split(", ")[0];
    let B = labels.split(", ")[1];
    const sortedData = Object.keys(data)
      .sort((a, b) => data[a].X - data[b].X)
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});
    var callback = (args) => {
      var selectedData;
      if (args?.data) {
        selectedData = Object.entries(
          Object.entries(data).filter(
            ([key, value]) =>
              (value.X === args.data[0]) & (value.Y === args.data[1])
          )
        );
        return (
          selectedData[0][1][0] +
          "<br />" +
          "E Hull: " +
          selectedData[0][1][1].e_hull +
          "<br />" +
          "X: " +
          selectedData[0][1][1].X +
          "<br />" +
          "Y: " +
          selectedData[0][1][1].Y
        );
      }
    };
    Object.entries(sortedData).forEach(([key, value]) => {
      scater_tooltip.push([{ name: key, e_hull: value.e_hull }]);
      if (value.e_hull === 0) {
        scater_1.push([value.X, value.Y]);
      } else {
        scaterNormal.push([value.X, value.Y]);
      }
    });

    const option = {
      grid: {
        bottom: 130,
        containLabel: true,
        left: 50,
      },
      toolbox: {
        show: true,
        feature: {
          dataZoom: {},

          saveAsImage: { name: `${A}-${B}_phaseDiagram`, pixelRatio: 2 },
        },
      },
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "cross",
        },
        formatter: callback,
      },
      xAxis: {
        type: "value",
        name: `Composition ratio, ${B}/(${A}+${B})`,
        nameGap: 25,
        nameLocation: "middle",
        nameTextStyle: {
          fontSize: 14,
        },
        axisLine: { onZero: false },
        splitLine: {
          show: true,
        },
      },
      yAxis: {
        type: "value",
        name: "Formation Energy (eV/atom)",
        nameGap: 40,
        nameRotate: 90,
        nameLocation: "middle",
        axisLine: { onZero: false },
        nameTextStyle: {
          fontSize: 14,
        },
        splitLine: {
          show: true,
        },
      },
      series: [
        {
          name: "Hardness",
          type: "scatter",

          data: scaterNormal,
          color: "#000000",
        },
        {
          name: "Hardness",
          type: "scatter",
          symbol: "circle",
          symbolSize: 15,
          color: "#000000",
          lineStyle: {
            width: 5,
          },
          itemStyle: {
            borderWidth: 2,
            borderColor: "#000000",
            color: "#ff0000",
          },
          data: scater_1,
        },
        {
          name: "e_hull",
          type: "line",
          data: scater_1,
          symbol: "none",
        },
        {
          name: "e_hull_y_0",
          type: "line",
          data: [
            [0, 0],
            [1, 0],
          ],
          symbol: "none",
        },
        {
          name: "line",
          type: "line",
          stack: "all",
          data: [],
          markLine: {
            silent: true,
            symbol: "none",
            label: {
              padding: [3, 6, 10, 6],
              fontWeight: "bold",
              fontSize: 14,
            },
            data: [
              {
                name: A,
                xAxis: 0,

                label: {
                  formatter: A,
                },
              },
              {
                name: B,
                xAxis: 1,
                label: {
                  formatter: B,
                },
              },
            ],
          },
        },
      ],
    };

    chart.setOption(option);
    chart.on("click", function (params) {
      Object.entries(data).forEach(([key, value]) => {
        if ((value.X === params.data[0]) & (value.Y === params.data[1])) {
          window.open(`/compound/${value.key}`, "_blank");
        }
      });
    });
    return () => {
      chart.dispose();
    };
  }, [data]);

  let size;
  if (window.matchMedia("screen and (max-width: 768px)").matches) {
    size = ["90vw", "90vw"];
  } else {
    size = ["45vw", "45vw"];
  }
  return <div ref={chartRef} style={{ width: size[0], height: size[1] }} />;
};

export default PhaseDiagramChart;
