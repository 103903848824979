import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  MobileStepper,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import fetchNews from "../../api/NewsAPI";
import { formatDate } from "../Utils/FormatData";

const useResponsiveCardsPerPage = ({ newsLength }) => {
  const isLargeScreen = useMediaQuery("(min-width: 1600px)");
  const isMediumScreen = useMediaQuery("(min-width: 1024px)");
  const isSmallScreen = useMediaQuery("(min-width: 700px)");
  const isXSmallScreen = useMediaQuery("(max-width: 700px)");

  if (isLargeScreen && newsLength >= 4) {
    return 4;
  } else if (isMediumScreen && newsLength >= 3) {
    return 3;
  } else if (isSmallScreen && newsLength >= 2) {
    return 2;
  } else if (isXSmallScreen && newsLength >= 1) {
    return 1;
  } else {
    return newsLength;
  }
};

const NewsWidget = () => {
  const theme = useTheme();
  const [news, setNews] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [newsLength, setNewsLength] = useState(0);
  const [open, setOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const maxDescriptionLength = 199;

  useEffect(() => {
    const getNews = async () => {
      const fetchedNews = await fetchNews();
      setNews(fetchedNews);
      setNewsLength(fetchedNews.length);
    };

    getNews();
  }, []);

  const cardsPerPage = useResponsiveCardsPerPage({ newsLength });

  useEffect(() => {
    const interval = setInterval(() => {
      const nextStep = (activeStep + 1) % newsLength;

      setActiveStep(nextStep);
    }, 4000);

    return () => clearInterval(interval);
  }, [activeStep, newsLength]);

  if (news.length === 0) {
    return <div>Loading...</div>;
  }

  const handleNext = () => {
    const nextStep = Math.min(activeStep + 1, newsLength - 1);
    setActiveStep(nextStep);
  };

  const handleBack = () => {
    const prevStep = Math.max(activeStep - 1, 0);
    setActiveStep(prevStep);
  };

  const handleShare = (code) => {
    const newsLink = `${window.location.origin}/news/${code}`;

    // Copy to clipboard
    navigator.clipboard
      .writeText(newsLink)
      .then(() => {
        setCopySuccess(true);
        setOpen(true);
      })
      .catch((err) => console.error("Failed to copy to clipboard", err));
  };

  const handleClose = () => {
    setOpen(false);
  };
  const displayCards = () => {
    const items = [];
    for (let i = activeStep; i < activeStep + cardsPerPage; i++) {
      const index = i >= newsLength ? i - newsLength : i;
      const step = news[index];

      if (!step) continue;

      const truncatedDescription = step.description
        ? step.description.length > maxDescriptionLength
          ? `${step.description.substring(0, maxDescriptionLength)}...`
          : step.description
        : "No description available";

      items.push(
        <Card
          key={step.code}
          sx={{
            boxShadow: "none",
            width: "100%",
            mt: 2,
          }}
        >
          <CardMedia
            sx={{ height: 140, width: "90%", margin: "0 auto" }}
            image={step.image}
            title={step.title}
          />
          <CardContent
            sx={{
              margin: "0 auto",
              height: "250px",
              overflow: "auto",
            }}
          >
            <Typography variant="body2" color="text.secondary">
              {formatDate(step.create)}
            </Typography>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ mt: 1 }}
            >
              {step.title}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ minHeight: "50px" }}
            >
              {truncatedDescription}
            </Typography>
          </CardContent>
          <CardActions>
            <Link to="#">
              <Button size="small" onClick={() => handleShare(step.code)}>
                Share
              </Button>
              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
              >
                {copySuccess && (
                  <Alert icon={false} severity="success">
                    Link copied to clipboard!
                  </Alert>
                )}
              </Snackbar>
            </Link>

            <Link to={`/news/${step.code}`} target="_blank" rel="noreferrer">
              <Button size="small">Read more</Button>
            </Link>
          </CardActions>
        </Card>
      );
    }
    return items;
  };

  return (
    <Box
      sx={{
        maxWidth: "100%",
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mb: 2,
        }}
      >
        {displayCards()}
      </Box>
      <MobileStepper
        variant="text"
        steps={newsLength}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === newsLength - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Previous
          </Button>
        }
        sx={{
          fontSize: "20px",
        }}
      />
    </Box>
  );
};

export default NewsWidget;
